import * as warehouseWorkshopApi from '@/api/warehouse_workshop.js';
import * as warehouseOrderApi from '@/api/warehouse_orders.js';

export default {
	namespaced: true,
	state: {
		show:false,
		filter:null,
		orders:[],
		order:null, 
		page:1,
		get_row:20,
		quantity_page:0,
		notify:{
			show:false, 
			type:null,
			message:null, 
		}, 
		timeout:null, 
	},
	getters: {
		warehouse_workshop:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.orders = data.orders;  
			state.quantity_page = data.quantity_page;  
		}, 
		setShow(state, data){ 
			state.show = data.status; 
			state.order = data.order; 
		},
		setNotify(state, data){ 
			state.notify.show = data.status; 
			state.notify.message = data.message;  	 
		},
		setOrderPosition(state, data){
			 
			let hold_workshop = parseFloat(state.order.positions[data.key].items[data.k].hold_workshop) 
			let limit_remains = parseFloat(state.order.positions[data.key].items[data.k].limit_remains);
 
			if(hold_workshop < 0){
				state.order.positions[data.key].items[data.k].hold_workshop = 0;
				hold_workshop = 0;
			}
			
			if(hold_workshop > limit_remains){
				state.order.positions[data.key].items[data.k].hold_workshop = limit_remains;
				hold_workshop = limit_remains;  
			}
 
			state.order.positions[data.key].items[data.k].remains = limit_remains - hold_workshop;   
			
			////

			let group_hold_workshop = 0;
				  
			for(let k in state.order.positions[data.key].items)
				group_hold_workshop = group_hold_workshop + parseFloat(state.order.positions[data.key].items[k].hold_workshop);  

			state.order.positions[data.key].total_group.hold_workshop = group_hold_workshop;

			///

			let total_hold_workshop = 0;

			for(let key in state.order.positions) 
				if(key !== "total") 
					total_hold_workshop = total_hold_workshop + parseFloat(state.order.positions[key].total_group.hold_workshop);		 
				  
			state.order.positions.total.hold_workshop = total_hold_workshop;
		}, 
		setOrderNotify(state, data){   
			state.notify.show = data.status; 
			state.notify.message = data.message;   
			state.notify.type = data.type;  
		},
		resetFilter(state){ 
			state.filter.period = "current_month";
			state.filter.date = null;
			state.filter.status_id = null;
			state.filter.names_product_id = null;
			state.filter.colour_product_id = null;
			state.filter.deal_id = null;
		},
		setPage(state, page){ 
			state.page = page 
		},  
		copyPlace(state, data){    
			state.order.positions[data.key].items[data.k].place.zone_workshop = state.order.positions[data.key].items[data.k].place.zone
			state.order.positions[data.key].items[data.k].place.shelf_workshop = state.order.positions[data.key].items[data.k].place.shelf 
		},    
	},
	actions: { 
		async loadFilterWarehouseWorkshop({ state, commit, dispatch, rootGetters }){

			let data = await warehouseWorkshopApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"warehouse_workshop"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				await dispatch("loadDataWarehouseWorkshop", { filter:data.filter });
			}				 
		},    
		async setFilterWarehouseWorkshop({ commit, dispatch, rootGetters }, filter){
			let data = await warehouseWorkshopApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"warehouse_workshop", filter });	 
			if(data.result) {
				commit('setPage', 1);  
				dispatch("loadDataWarehouseWorkshop", { filter:data.filter });
			}				 
		},
		async loadDataWarehouseWorkshop({ state, commit }, data){ 
			let response = await warehouseWorkshopApi.getData({ filter:data.filter, page:state.page, get_row:state.get_row, });	 
			if(response.result) {
				commit('setData', response);						 
			}
		},
		async showWarehouseWorkshopCard({ state, commit, dispatch }, data){
			if(data.status){				
				let response = await warehouseWorkshopApi.getOrder({order_id:data.order_id});	 
				if(response.result) {
					data.order = response.order;
					commit('setShow', data);						 
				}
			}
			else{
				dispatch("saveDataPosition").then((response)=>{
					if(response.result) {
						data.order = null;
						commit('setShow', data);
					}
				});
			}
		},
		changeOrder({ state }, param_id){ 

			let param_val = null;

			if(param_id == "comment_workshop") param_val = state.order.comment_workshop;

			clearTimeout(state.timeout);
			
			state.timeout = setTimeout( async () => {
				let response = await warehouseOrderApi.setOrder({ param_id, param_val, order_id:state.order.order_id });	 
			}, 1000);

		},
		changeDataPosition({ state, commit, dispatch }, data){ 
			
			if(data.param_id == 'hold_workshop')
				commit('setOrderPosition', data);	 
			
			clearTimeout(state.timeout);
			
			state.timeout = setTimeout(() => {
				dispatch("saveDataPosition")
			}, 1500)
		},
		async saveDataPosition({ state }){ 			
			
			let positions = [];
			
			if(state.order !== null && Object.keys(state.order.positions).length !== 0){
				for(let key in state.order.positions){ 
					for(let k in state.order.positions[key].items){ 
						positions.push({ 
							position_id:state.order.positions[key].items[k].position_id,
							hold_workshop:state.order.positions[key].items[k].hold_workshop,
							place:{
								zone_workshop:state.order.positions[key].items[k].place.zone_workshop,
								shelf_workshop:state.order.positions[key].items[k].place.shelf_workshop,
							} 
						}); 
					} 
				}

				let response = await warehouseWorkshopApi.setPosition({ order_id:state.order.order_id, positions });	 
				if(response.result) { 
					return { result: true };
				}
			}
		},
		async runJob({ state, dispatch }){
			let response = await warehouseWorkshopApi.runJob({ order_id:state.order.order_id });	 
			if(response.result) { 
				dispatch("showWarehouseWorkshopCard", { status:true, order_id:state.order.order_id });
				dispatch("loadDataWarehouseWorkshop", { filter:state.filter });				   
			}
		},
		validateOrder({ state, commit, dispatch }){

			let validate = true; 
			let message = null; 
			let type = null;

			for(let key in state.order.positions){

				for(let k in state.order.positions[key].items){
				
					if(state.order.positions[key].items[k].hold_workshop == 0 || state.order.positions[key].items[k].hold_workshop == ""){
						validate = false;
						message = "Укажите сколько «Использовано»";
						type = "position"; 
					}

					if(state.order.positions[key].items[k].place.zone_workshop == null || state.order.positions[key].items[k].place.zone_workshop == ""){
						validate = false;
						message = "Вы не заполнили поле «Зона»";
						type = "place"; 
					}

					if(state.order.positions[key].items[k].place.shelf_workshop == null || state.order.positions[key].items[k].place.shelf_workshop == ""){
						validate = false;
						message = "Вы не заполнили поле «Полка»";
						type = "place"; 
					}
				}
			}
		 
			if(!validate) 
				commit('setOrderNotify', { status:true, type, message });	 
			else 
				dispatch("confirmOrder");			
		},
		confirmOrder({ state, commit, dispatch }){
 
			dispatch("saveDataPosition").then(async (response)=>{
				if(response.result) {
					let res = await warehouseWorkshopApi.confirmOrder({ order_id:state.order.order_id });	 
					if(res.result) { 
						commit('setNotify', { type:null, message:null, position_key:null });	
						dispatch("showWarehouseWorkshopCard", { status:false, order_id:null });
						dispatch("loadDataWarehouseWorkshop", { filter:state.filter });				   
					}
				}
			});

		},
		showOrderNotify({ commit }, data){
			commit('setOrderNotify', data);
		},
		resetFilter({ state, commit, dispatch }){
			commit('resetFilter');	
			dispatch("loadDataWarehouseWorkshop", { filter:state.filter });	
		},
		copyPlace({ commit }, data){
			commit('copyPlace', data);	
		}
	}, 
} 