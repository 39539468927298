import server from '@/api/http';
 
export async function getFilterCurrent(params){  
	let { data } = await server.get('sales_pf/current/get_filter', { params });
	return data;
} 
export async function getDataCurrent(params){  
	let { data } = await server.get('sales_pf/current/get_data', { params });
	return data;
} 
export async function setFilterCurrent(params){  
	let { data } = await server.get('sales_pf/current/set_filter', { params });
	return data;
} 
export async function getBarRevenue(params){  
	let { data } = await server.get('sales_pf/current/get_bar_revenue', { params });
	return data;
}  
export async function getDataInput(params){  
	let { data } = await server.get('sales_pf/input/get_data', { params });
	return data;
} 
export async function upDirectionInput(params){  
	let { data } = await server.get('sales_pf/input/up_direction', { params });
	return data;
} 
export async function upPeriodInput(params){  
	let { data } = await server.get('sales_pf/input/up_period', { params });
	return data;
}

export async function getFilterPlan(params){  
	let { data } = await server.get('sales_pf/plan/get_filter', { params });
	return data;
}
export async function setFilterPlan(params){  
	let { data } = await server.get('sales_pf/plan/set_filter', { params });
	return data;
}
export async function getDataPlan(params){  
	let { data } = await server.get('sales_pf/plan/get_data', { params });
	return data;
} 
export async function upPlanDirection(params){  
	let { data } = await server.get('sales_pf/plan/up_plan_direction', { params });
	return data;
} 
export async function upSortDirections(params){  
	let { data } = await server.get('sales_pf/input/up_sort_directions', { params });
	return data;
} 
 