import * as expensesApi from '@/api/expenses_budget.js';

export default {
	namespaced: true,
	state: {
		filter:null, 
		tables:[],
		table_total:null, 
		timeout:null, 
		colls_status:{
			plan:false,
			fact:false,
			total:false,
		}
	},
	getters: {
		expenses_budget:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.tables = data.tables; 
			state.table_total = data.table_total;  
			state.colls_status = data.colls_status;  
		},
		upTotalTables(state, data){
			
			for(let key_state_table in state.tables) 

				for(let key_data_table in data.tables){

					if(state.tables[key_state_table].id == state.tables[key_data_table].id) 
						state.tables[key_state_table].table_rows.total = data.tables[key_data_table].table_rows.total;

					for(let key_state_table_rows in state.tables[key_state_table].table_rows.rows) 

						for(let key_state_table_items in state.tables[key_state_table].table_rows.rows[key_state_table_rows].items){

							if(state.tables[key_state_table].table_rows.rows[key_state_table_rows].items[key_state_table_items].type == "total")  
								state.tables[key_state_table].table_rows.rows[key_state_table_rows].items[key_state_table_items].value = data.tables[key_state_table].table_rows.rows[key_state_table_rows].items[key_state_table_items].value
						  
							if(state.tables[key_state_table].table_rows.rows[key_state_table_rows].items[key_state_table_items].type == "value" && state.tables[key_state_table].table_rows.rows[key_state_table_rows].items[key_state_table_items].fact) 
								state.tables[key_state_table].table_rows.rows[key_state_table_rows].items[key_state_table_items].style = data.tables[key_state_table].table_rows.rows[key_state_table_rows].items[key_state_table_items].style
						}
				} 
			 

			state.table_total = data.table_total
		} 
	},
	actions: {
		async loadFilterExpensesBudget ({ commit, dispatch, rootGetters }){
			let data = await expensesApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"expenses_budget"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadDataExpensesBudget", data.filter);	
			}				 
		},    
		async setFilterExpensesBudget ({ dispatch, rootGetters }, filter){
			let data = await expensesApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"expenses_budget", filter });	 
			if(data.result) {
				dispatch("loadDataExpensesBudget", data.filter);	
			}				 
		},
		async loadDataExpensesBudget({ commit }, filter){ 
			let data = await expensesApi.getData({ filter });	 
			if(data.result) commit('setData', data);						 
		}, 
		editValue({ state, commit }, data){
			
			clearTimeout(state.timeout)
			
			state.timeout = setTimeout(async () => {

				let items =  state.tables[data.key].table_rows.rows[data.k_rows].items[data.k];
				let item_expenditure_id = state.tables[data.key].table_rows.rows[data.k_rows].id;

				let res = await expensesApi.setPlan({ items, item_expenditure_id });	 
				if(res.result) {
					let res_get_data = await expensesApi.getData({ filter:state.filter });	 
					if(res_get_data.result) commit('upTotalTables', res_get_data);	 	 
				}	 
			}, 1000)

		}
	}, 
} 