import server from '@/api/http';

export async function get(params){  
	let { data } = await server.get('marketing/track_analytic/utm/get', { params });
	return data;
}
export async function create(params){  
	let { data } = await server.get('marketing/track_analytic/utm/create', { params });
	return data;
}
export async function up(params){  
	let { data } = await server.get('marketing/track_analytic/utm/up', { params });
	return data;
}
export async function deleteUtm(params){  
	let { data } = await server.get('marketing/track_analytic/utm/delete', { params });
	return data;
}