<template> 
  <v-dialog v-model="dialog.dialog_group_details.show" max-width="600" class="dialog-details">
    <v-card v-if="dialog.dialog_group_details.data !== null"> 
      
      <v-toolbar color="blue-analytics-1">
        <v-toolbar-title>Детализация</v-toolbar-title>
        <v-btn icon @click="setDialogGroupDetails({show:false, data:null})">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>
        
        <v-card-subtitle>Параметр «<span v-html="dialog.dialog_group_details.data.title"></span>»</v-card-subtitle>

        <v-table class="details-table">
          <thead>
            <tr> 
              <th class="text-left">Регион</th>
              <th class="text-left">Звонки</th> 
            </tr>
          </thead>
          <tbody>
            <tr v-for="(items, key) in dialog.dialog_group_details.data.items" :key="key">
              <td>{{ items.name }}</td>
              <td>{{ items.value.toLocaleString('ru-RU') }}</td>
            </tr>
          </tbody>
        </v-table>
      </v-card-text> 
    </v-card>
  </v-dialog>  
</template> 
<script>  
import { mapGetters, mapActions } from 'vuex'
export default { 
  computed:{  
    ...mapGetters("dialog", {dialog:"dialog"}), 
  },
  methods:{   
    ...mapActions("dialog", ["setDialogGroupDetails"]),
    formattedTime(value, format){
       
       if(format == 'dhis'){ 
         let day = Math.floor(value / 86400);
         value %= 86400;
         let hours = Math.floor(value / 3600);
         value %= 3600;
         let minutes = Math.floor(value / 60);
         let seconds = value % 60;
 
         return day+":"+hours+":"+minutes+":"+seconds;
       }
 
       if(format == 'i') {
         let minutes = Math.floor(value / 60);
         return minutes;
       }
     }  
  }, 
}
</script>