import * as api from '@/api/publications_networks.js';

export default {
	namespaced: true,
	state: {
		publics_list:[
			{ 
				name:'VK', 
				value:'vk', 
				type_publication:[
					{ name:'Пост', id:'post' },
					{ name:'Видео', id:'video' },
					{ name:'Клип', id:'clip' },
					{ name:'Сторис', id:'stories' }					
				] 
			},
			{ 
				name:'OK', 
				value:'ok',
				type_publication:[
					{ name:'Пост', id:'post' },
					{ name:'Видео', id:'video' },
					{ name:'Клип', id:'clip' },
					{ name:'Сторис', id:'stories' }					
				]  
			},
			{ 
				name:'Telegram', 
				value:'telegram',
				type_publication:[
					{ name:'Пост', id:'post' },
					{ name:'Видео', id:'video' },
					{ name:'Клип', id:'clip' },
					{ name:'Сторис', id:'stories' }					
				]  
			}
		],
		publics:[],
		show_public:{
			status:false,
			id:null,
			action:null,
			network:null,
			name:null,
			data:null
		}
	},
	getters: {
		publications_networks_public:state=>{
			return state
		},  
	},
	mutations: { 
		setPublics(state, publics){
			state.publics = publics;
		},
		showPublic(state, data){ 
			state.show_public.status = data.status;
			state.show_public.id = data.id;
			state.show_public.action = data.action;
			state.show_public.network = data.network;
			state.show_public.name = data.name;
			state.show_public.data = data.data; 
		},
		setShowPublicNetwork(state, network){
			state.show_public.network = network;
		},
		setDataPublicNetwork(state, data){
			state.show_public.data = data;
		}
	},
	actions: { 
		async loadPublics({ commit }){ 			 
			await api.getPublics().then((response)=>{
				if(response.result) {
					commit('setPublics', response.publics);	 
				}				 
			})	 
		},
		async deletePublic({ dispatch }, id){
			await api.deletePublic({ id }).then((response)=>{
				if(response.result) { 
					dispatch("loadPublics")
				}				 
			})	
		},
		async saveNetwork({ state, commit, dispatch }){ 		
			
			let status = true;
			let mess = null;

			if(state.show_public.name == null){
				status = false;
				mess = "Введите название";
			}

			if(mess == null && state.show_public.network == null){
				status = false;
				mess = "Выберите сервис";
			}
 
			if(
				mess == null &&
				state.show_public.network == "vk" && 
				(state.show_public.data.api_key == null || state.show_public.data.public_id.api_key == 0)  
			){
				status = false;
				mess = "Введите api key";
			}

			if(
				mess == null &&
				state.show_public.network == "vk" && 
				(state.show_public.data.public_id == null || state.show_public.data.public_id.length == 0)
			){
				status = false;
				mess = "Введите public id";
			}
			  
			if(state.show_public.network == "ok"){
				status = false;
			}

			if(state.show_public.network == "telegram"){
				status = false;
			}

			if(!status && mess !== null)
				dispatch("snackBar/showSnackBar", mess, {root:true})

			if(status)
				await api.savePublic({
					id:state.show_public.id,
					name:state.show_public.name,
					action:state.show_public.action,
					network:state.show_public.network,
					data:state.show_public.data,
				}).then((response)=>{
					if(response.result) {
						commit('setPublics', response.publics);	 
						commit('showPublic', { status:false, id:null, action:null, network:null, name:null, data:null })
						dispatch("loadPublics")
					}				 
				})	 
		}
	}, 
} 