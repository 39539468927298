import * as analyticsOptionalApi from '@/api/analytics_optional.js';

export default {
	namespaced: true,
	state: {
		filter:null,
		data:null,  
		markers:null,
		view_region:"all",
		map_key:1
	},
	getters: {
		analytics_optional:state=>{
			return state
		},  
	},
	mutations: {
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.data = data.regions 
		}, 
		setMarkers(state, markers){ 
			state.markers = markers 
			state.map_key++ 
		}, 
		setView(state, view){ 
			state.view_region = view 
		},
	},
	actions: {    
		async setViewRegion({ dispatch, state, commit }, view){
			commit('setView', view);
			dispatch("setMarkers", state.data);	 
		},
		async setFilterAnalyticsOptional({ dispatch, rootGetters }, filter){
			let data = await analyticsOptionalApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"analytics_optional", filter });	 
			if(data.result) {
				dispatch("loadData", data.filter);	
			}				 
		},
		async loadFilter({ commit, dispatch, rootGetters }){
			let data = await analyticsOptionalApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"analytics_optional"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadData", data.filter);	
			}				 
		},
		async loadData({ dispatch, commit }, filter){
			let data = await analyticsOptionalApi.getData({filter});	 
			if(data.result) {
				commit('setData', data);
				dispatch("setMarkers", data.regions);	
			}					 
		}, 
		setMarkers({ commit, state }, data){
			commit('setMarkers', null);
				let markers = [];	
				if(state.view_region == "all" && data.regions_all_calls.items !== null){ 
					console.log("all", data.regions_all_calls);
					let i = 0;
					for(let key in data.regions_all_calls.items){
						if(data.regions_all_calls.items[key].coords !== null){
							markers.push({
								name:data.regions_all_calls.items[key].name,   
								coords:data.regions_all_calls.items[key].coords,
								value:data.regions_all_calls.items[key].value
							})
							i++;
							if(i == 10) break;
						}
					}  
				}
				if(state.view_region == "outgoing" && data.regions_outgoing_calls.items !== null){ 
					console.log("outgoing", data.regions_outgoing_calls);
					let i = 0;
					for(let key in data.regions_outgoing_calls.items){
						if(data.regions_outgoing_calls.items[key].coords !== null){
							markers.push({
								name:data.regions_outgoing_calls.items[key].name,   
								coords:data.regions_outgoing_calls.items[key].coords,
								value:data.regions_outgoing_calls.items[key].value
							})
							i++;
							if(i == 10) break;
						}
					}  
				}
				if(state.view_region == "incoming" && data.regions_incoming_calls.items !== null){ 
					console.log("incoming", data.regions_incoming_calls);
					let i = 0;
					for(let key in data.regions_incoming_calls.items){
						if(data.regions_incoming_calls.items[key].coords !== null){
							markers.push({
								name:data.regions_incoming_calls.items[key].name,   
								coords:data.regions_incoming_calls.items[key].coords,
								value:data.regions_incoming_calls.items[key].value
							})
							i++;
							if(i == 10) break;
						}
					}  
				}
				console.log("markers", markers);
				commit('setMarkers', markers); 
		},
	}, 
}