import * as api from '@/api/publications_networks.js';

export default {
	namespaced: true,
	state: { 
		publication_id:null,
		network:null,
		network_id:null,
		type_public:null,
		date_public:null, 
		images:[],
		video:[],
		request:null,
		status:null
	},
	getters: {
		publications_networks_create:state=>{
			return state
		},  
	},
	mutations: { 
		setData(state, data){
			state.publication_id = data.publication_id; 
			state.network = data.network; 
			state.network_id = data.network_id; 
			state.type_public = data.type_public; 
			state.date_public = data.date_public; 
			state.images = data.images;
			state.video = data.video; 
			state.request = data.request;
			state.status = data.status;  
		}, 
		setPublicationId(state, publication_id){
			state.publication_id = publication_id; 
		},
		setNetwork(state, network){
			state.network = network; 
		}, 
		setNetworkId(state, id){
			state.network_id = id; 
		},  
		setTypePublic(state, type){
			state.type_public = type;
		},  
		setRequest(state, request){
			state.request = request;
		},    
	},
	actions: { 
		async loadPublication({ commit }, data){ 			 
			await api.getPublication(data).then((response)=>{
				if(response.result && response.data !== null) {
					commit('setData', response.data);	 
				}				 
			})	 
		},
		async createPublication({ commit }){
			await api.upPublication({action:"add"}).then((response)=>{
				if(response.result) {
					commit('setPublicationId', response.publication_id);	 
				}				 
			})	 
		},
		async changePublication({ state }, params){ 
			await api.upPublication({ action:"up", id:state.publication_id, params, value:state[params], request_status:false }) 
		},
		async changePublicationRequest({ state }, params){ 
			await api.upPublication({ action:"up", id:state.publication_id, params, value:state.request[params], request_status:true }) 
		},
		async sendPublication({ state, dispatch }){
			
			//check

			await api.sendPublication({id:state.publication_id}).then((response)=>{
				if(response.result)
					dispatch("nullableData");			
			})	 
		},
		async loadFiles({ state, dispatch }, data){ 
 
			let formData = new FormData();  
			formData.append('publication_id', state.publication_id);
			formData.append('type', data.type);
			formData.append('params', data.params); 
			data.files.forEach(file=>{
				formData.append("files[]", file);
			}); 
			await api.loadFilesPublication(formData).then((response)=>{
				if(response.result)
					dispatch("loadPublication", { type:"one", id:state.publication_id })				
			})	 
		},
		async deleteFile({ state, dispatch }, data){ 
			await api.deleteFilePublication({ id:state.publication_id, path:data.path, type:data.type, params:data.params }).then((response)=>{
				if(response.result)
					dispatch("loadPublication", { type:"one", id:state.publication_id })
			}); 
		},
		async deletePublication({ state, dispatch }){
			await api.deletePublication({ id:state.publication_id }).then((response)=>{
				if(response.result) {
					dispatch("nullableData");
				}				 
			})	 
		},
		nullableData({ commit }){
			commit('setData', {
				publication_id:null,
				network:null,
				network_id:null,
				type_public:null,
				date_public:null,
				images:[],
				video:[],
				request:null,
				status:null
			});	 
		}
	}
} 