export default {
	namespaced: true,
	state: {
		page:null,
		account:{
			show_drawer: null, 
		}, 
	},
	getters: {
		appInterface:state=>{
			return state
		}, 
	},
	mutations: { },
	actions: { }, 
}