import * as salespfApi from '@/api/sales_pf.js';

export default {
	namespaced: true,
	state: {
		filter:null, 
		table_1:{},
		table_2:{},
		dinamic_year:{
			precent:0,
			value:0
		},
		dinamic_filter:{
			precent:0,
			value:0
		},
		bar_revenue: null,
		pie_revenue: null,
		year_bar_revenue:[2023],
		key_gauge:1
	},
	getters: {
		sales_pf_current:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.table_1 = data.table_1;  
			state.table_2 = data.table_2;  
			state.dinamic_year = data.dinamic_year;  
			state.dinamic_filter = data.dinamic_filter; 
			state.pie_revenue = data.pie_revenue;
			state.key_gauge++;
		},
		setbarRevenue(state, data){ 
			state.bar_revenue = data.bar_revenue;
		},
	},
	actions: {
		async loadFilter ({ commit, dispatch, rootGetters }){
			let data = await salespfApi.getFilterCurrent({user_id:rootGetters['account/account'].user_id, page:"sales_pf_current"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadData");	
				dispatch("loadBarRevenue");
			}				 
		},    
		async setFilterSalesPfCurrent({ dispatch, rootGetters }, filter){
			let data = await salespfApi.setFilterCurrent({ user_id:rootGetters['account/account'].user_id, page:"sales_pf_current", filter });	 
			if(data.result) {
				dispatch("loadData");	
				dispatch("loadBarRevenue");	
			}				 
		},
		async loadData({ state, commit }){ 
			let data = await salespfApi.getDataCurrent({ filter:state.filter });	 
			if(data.result) commit('setData', data);						 
		}, 
		async loadBarRevenue({ state, commit }){ 
			let data = await salespfApi.getBarRevenue({ filter:state.filter, year_bar_revenue:state.year_bar_revenue });	 
			if(data.result) commit('setbarRevenue', data);						 
		}, 
	}, 
} 