import server from '@/api/http';

 
export async function getFilter(params){  
	let { data } = await server.get('expenses/budget/get_filter', { params });
	return data;
} 

export async function getData(params){  
	let { data } = await server.get('expenses/budget/get_data', { params });
	return data;
} 

export async function setFilter(params){  
	let { data } = await server.get('expenses/budget/set_filter', { params });
	return data;
} 

export async function setPlan(params){  
	let { data } = await server.get('expenses/budget/set_plan', { params });
	return data;
} 

 