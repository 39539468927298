<template>   
  <v-app id="inspire" full-height class="layout-account">
    <sidebar-account></sidebar-account> 
    <header-account></header-account>  
    <v-main>
      <router-view></router-view>
      <!-- <footer-account></footer-account> -->
    </v-main>     
    <dialog-details></dialog-details>
    <dialog-group-details></dialog-group-details>
    <dialog-chart-index></dialog-chart-index>
    <snack-bar></snack-bar>
    <preloader></preloader>
  </v-app>  
</template>
<script> 
import HeaderAccount from '../components/header/HeaderAccount.vue'
import SidebarAccount from '../components/sidebar/SidebarAccount.vue'
import Preloader from '../components/Preloader.vue' 
import SnackBar from '../components/SnackBar.vue'
import DialogDetails from '@/components/dialog/Details.vue'
import DialogGroupDetails from '@/components/dialog/GroupDetails.vue'
import DialogChartIndex from '@/components/dialog/ChartIndex.vue'
 
export default {
  components: { 
    HeaderAccount,
    SidebarAccount,
    Preloader, 
    SnackBar,
    DialogDetails,
    DialogGroupDetails,
    DialogChartIndex
  }, 
}
</script>