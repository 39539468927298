import * as warehouseReportsApi from '@/api/warehouse_reports.js';

export default {
	namespaced: true,
	state: {
		filter:null,
		remains:0,
		revenue:{
			entry_summ:0,
			write_offs_sum:0,
			diff:0
		}, 
		trade_turnover:[],
		accounting:{
			type_products:[],
			company_list:[],
			contract_list:[],
			company_id:null,
			contract:{
				id:null,
				name:null,
			}
		},
		group_by:{
			category_id:false,
			names_id:false,
			type_id:false,
			class_id:false,
			colour_id:false,
		}
	},
	getters: {
		warehouse_reports:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){   
			state.revenue = data.revenue;  
			state.trade_turnover = data.trade_turnover;  
			state.remains = data.remains;   
			state.accounting.company_list = data.accounting.company_list;   
			state.accounting.company_id = data.accounting.company_list[0].id;    
			state.accounting.contract_list = data.accounting.contract_list;   
			state.accounting.contract.id = data.accounting.contract_list[0].id;   
			state.accounting.contract.name = data.accounting.contract_list[0].name;   			 
		}, 
		setOrderBy(state, param_id){ 
			
			state.group_by[param_id] = state.group_by[param_id] ? false : true;
  
			if(param_id == "category_id" && !state.group_by.category_id){ 
				state.group_by.type_id = false;  
				state.group_by.class_id = false;   
			}

			if(
				(param_id == "type_id" || param_id == "class_id") && 
				(state.group_by.type_id || state.group_by.class_id)
			) 
				state.group_by.category_id = true;  
			 	 
		}
	},
	actions: { 
		async loadFilterWarehouseReport ({ state, commit, dispatch, rootGetters }){

			let data = await warehouseReportsApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"warehouse_reports"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				await dispatch("loadDataWarehouseReport", { filter:data.filter });
			}				 
		},    
		async setFilterWarehouseReport ({ dispatch, rootGetters }, filter){
			let data = await warehouseReportsApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"warehouse_reports", filter });	 
			if(data.result) {
				dispatch("loadDataWarehouseReport", { filter:data.filter });
			}				 
		},
		async loadDataWarehouseReport({ state, commit }, data){ 
			let response = await warehouseReportsApi.getData({ filter:data.filter, order_by:state.group_by });	 
			if(response.result) {
				commit('setData', response);						 
			}
		},
		groupBy({ state, commit, dispatch }, param_id){  
			commit('setOrderBy', param_id);	  
			dispatch("loadDataWarehouseReport", { filter:state.filter });
		},
	}, 
} 