import * as warehouseEntryApi from '@/api/warehouse_entry.js';
import * as warehouseEntryCardApi from '@/api/warehouse_entry_card.js';
 
export default {
	namespaced: true,
	state: {
		filter:null,  
		load_table:false,
		table:[],  
		page:1,
		get_row:20,
		quantity_page:null,
		timeout:null,
		order_by:{
			date:'desc',
			entry_id:null, 
		}, 
	},
	getters: {
		warehouse_entry:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setLoadTable(state, status){ 
			state.load_table = status; 
		}, 
		setData(state, data){ 
			state.table = data.table 
			state.quantity_page = data.quantity_page;   
		},  
		setOrderBy(state, type){ 			  
			if(type == "date"){  
				state.order_by.date = state.order_by.date == null ? 'desc' : state.order_by.date == 'desc' ? 'asc' : 'desc'; 
				state.order_by.entry_id = null 	 
			}
			if(type == "entry_id"){  
				state.order_by.entry_id = state.order_by.entry_id == null ? 'desc' : state.order_by.entry_id == 'desc' ? 'asc' : 'desc'; 
				state.order_by.date = null 	 
			}
		},
		setPage(state, page){ 
			state.page = page 
		},  
		resetFilter(state){  
			state.filter.period = null;
			state.filter.date = null;
			state.filter.entry_id = null;
			state.filter.position_id = null;
			state.filter.category_product_id = null;
			state.filter.type_product_id = null;
			state.filter.class_product_id = null;
			state.filter.colour_product_id = null;
			state.filter.supplier_product_id = null;
			state.filter.payer_product_id = null;
			state.filter.conducted = "show";
		},  
	},
	actions: { 
		async loadFilterWarehouseEntry ({ state, commit, dispatch, rootGetters }){
			let data = await warehouseEntryApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"warehouse_entry"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				
				if((state.filter.period == "custom_range" || state.filter.period == "day") && state.filter.date !== null) 					
					dispatch("loadDataWarehouseEntry", { filter:data.filter });
			 
				if((state.filter.period !== "custom_range" && state.filter.period !== "day")) 					
					dispatch("loadDataWarehouseEntry", { filter:data.filter });				 	
			}				 
		},    
		async setFilterWarehouseEntry ({ dispatch, rootGetters, commit }, filter){
			let data = await warehouseEntryApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"warehouse_entry", filter });	 
			if(data.result) { 
				commit('setPage', 1);  
				dispatch("loadDataWarehouseEntry", { filter:data.filter });	
			}				 
		},
		async loadDataWarehouseEntry({ state, commit }, data){ 
			 
			// if(data.page !== null){ 
				commit('setLoadTable', true);			
				let response = await warehouseEntryApi.getData({ filter:data.filter, page:state.page, get_row:state.get_row, order_by:state.order_by });	 
				if(response.result) {
					commit('setData', response);
					commit('setLoadTable', false);						 
				}
			//}
		},   
		changeDataEntry({ state }, data){	   
			let params_val = null;
			let delay = 1500;
			if(data.params_id == "comment") params_val = state.table[data.key].entry_comment; 
			clearTimeout(state.timeout) 
			state.timeout = setTimeout(async () => {   
				let response = await warehouseEntryCardApi.setEntry({  
					action:"save", 
					params_id:data.params_id, 
					params_val, 
					id:state.table[data.key].entry_id
				});	  

			}, delay); 
		},
		orderBy({ commit, state, dispatch }, type){ 		 
			commit('setOrderBy', type);	
			dispatch("loadDataWarehouseEntry", { filter:state.filter });	
		},
		resetFilter({ state, commit, dispatch }){
			commit('resetFilter');	
			dispatch("loadDataWarehouseEntry", { filter:state.filter });	
		},
		 
	}, 
} 