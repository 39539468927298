import * as emailAnalyticsApi from '@/api/email_analytics.js';

export default {
	namespaced: true,
	state: {
		filter:null,
		chart:null,
		survey:[],
		survey_items:[],
		rating_fill:null,
		font_color:null,
		rating_figure:null,
		nps_avg:0,
		modal_response_company:{
			show:false,
			key:null,
			k:null,
			search:null,
			search_items:[],
			items:[]
		},
		modal_rating_rate:{
			show:false,
			key:null,
		},
		modal_user_survey:{
			show:false,
			user_response:null,
			rating_rate:null
		}  
	},
	getters: {
		email_analytics:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		},   
		setData(state, data){ 
			state.chart = data.chart; 
			state.survey = data.survey;
			state.survey_items = data.survey_items;  
			state.rating_fill = data.rating_fill; 
			state.font_color = data.font_color; 
			state.rating_figure = data.rating_figure; 
			state.nps_avg = data.nps_avg; 
		},
		setFilterSurvey(state, survey){
			state.filter.survey = survey;
		},
		setFilterPeriod(state, period){
			state.filter.period = period;
		},
		setFilterCustomRange(state, custom_range){
			state.filter.custom_range = custom_range;
		},
		setModalResponseCompany(state, data){
			state.modal_response_company.show = data.show;
			state.modal_response_company.key = data.key;
			state.modal_response_company.k = data.k;
			
			let search_items = [];
			let items = [];

			if(data.show){
				if(state.survey.questions[data.key].type_response == 'text'){					
					if(state.survey.questions[data.key].response.length > 0){
						console.log(state.survey.questions[data.key].response)
						for(let key in state.survey.questions[data.key].response){
							if(state.survey.questions[data.key].response[key].company !== null && Object.keys(state.survey.questions[data.key].response[key].company).length !== 0)
							search_items.push(state.survey.questions[data.key].response[key].company)
						}
						items = state.survey.questions[data.key].response;
					}
				}
				else{

					if(state.survey.questions[data.key].response[data.k].id == 'custom'){						 
						if(state.survey.questions[data.key].response[data.k].custom_text.length > 0){
							for(let key in state.survey.questions[data.key].response[data.k].custom_text){ 
								if(Object.keys(state.survey.questions[data.key].response[data.k].custom_text[key].company).length !== 0){
									search_items.push(state.survey.questions[data.key].response[data.k].custom_text[key].company)
								}
							}
							items = state.survey.questions[data.key].response[data.k].custom_text;
						} 
					}	
					else{
						if(state.survey.questions[data.key].response[data.k].company.length > 0){
							search_items = state.survey.questions[data.key].response[data.k].company;	
						}
						items = [state.survey.questions[data.key].response[data.k]]
					}				
				}
			} 
			else 
				state.modal_response_company.search = null;

			items = JSON.parse(JSON.stringify(items));
			console.log(items);
			state.modal_response_company.search_items = search_items
			state.modal_response_company.items = items
		},
		setModalResponseCompanySearch(state, val){
			
			let items = [];
			let key = state.modal_response_company.key  
			let k = state.modal_response_company.k  			
			
			if(val === undefined || val == null){

				if(state.survey.questions[key].type_response == 'text'){					
					if(state.survey.questions[key].response.length > 0)						
						items = state.survey.questions[key].response;
				}
				else{
					if(state.survey.questions[key].response[k].id == 'custom'){
						items = state.survey.questions[key].response[k].custom_text;
					}
					else{
						if(state.survey.questions[key].response[k].company.length > 0)
							items = [state.survey.questions[key].response[k]]
					}
				}
			}
			else{
				if(state.survey.questions[key].type_response == 'text'){
					if(state.survey.questions[key].response.length > 0)
						for(let key_r in state.survey.questions[key].response){
							if(state.survey.questions[key].response[key_r].company !== null && Object.keys(state.survey.questions[key].response[key_r].company).length !== 0 && Number(state.survey.questions[key].response[key_r].company.id) == val){
								items.push(state.survey.questions[key].response[key_r]);
							}
						}
				}
				else{
					if(state.survey.questions[key].response[k].id == 'custom'){
						if(state.survey.questions[key].response[k].custom_text.length > 0)
							for(let key_c in state.survey.questions[key].response[k].custom_text)
								if(Object.keys(state.survey.questions[key].response[k].custom_text[key_c].company).length !== 0 && Number(state.survey.questions[key].response[k].custom_text[key_c].company.id) == val)
									items.push(state.survey.questions[key].response[k].custom_text[key_c]);				 
					}
					else{	
						console.log(state.survey.questions[key].response, k);

						if(state.survey.questions[key].response.length > 0 && state.survey.questions[key].response[k].company.length > 0)
							for(let key_c in state.survey.questions[key].response[k].company) 
								if(state.survey.questions[key].response[k].company[key_c].id == val){  
									items.push({
										id:state.survey.questions[key].response[k].id,
										text:state.survey.questions[key].response[k].text,
										quantity:state.survey.questions[key].response[k].quantity,
										company:[{
											id:state.survey.questions[key].response[k].company[key_c].id,
											name:state.survey.questions[key].response[k].company[key_c].name
										}],
										email:[]
									});
								} 
					}
				}
			}
			items = JSON.parse(JSON.stringify(items));

			console.log(items, val)
			state.modal_response_company.items = items
			state.modal_response_company.search = val
		},
		setModalRatingRate(state, data){
			state.modal_rating_rate.show = data.show;
			state.modal_rating_rate.key = data.key;
		},
		setModalUserSurvey(state, data){
			state.modal_user_survey.show = data.show;
			state.modal_user_survey.user_response = data.user_response; 
			state.modal_user_survey.rating_rate = data.rating_rate; 
		},
		setFilterViewChart(state, view){
			state.filter.view_chart = view;
		},
		deleteResponseRating(state, data){ 
			if(data.type == "company")
				state.survey.nps[state.modal_rating_rate.key].company.splice(data.key, 1);

			if(data.type == "email")
				state.survey.nps[state.modal_rating_rate.key].email.splice(data.key, 1);
		}
	},
	actions: { 
		async loadFilterEmailAnalytics ({ commit, dispatch, rootGetters }){ 
			await emailAnalyticsApi.getFilter({
				user_id:rootGetters['account/account'].user_id, 
				page:"email_analytics"
			}).then((response)=>{
				if(response.result) {
					commit('setFilter', response.filter);	 
					dispatch('loadEmailAnalytics');
				}				 
			})	 
		}, 
		async setFilterEmailAnalytics ({ state, commit, dispatch, rootGetters }){ 
			await emailAnalyticsApi.setFilter({ 
				user_id:rootGetters['account/account'].user_id, 
				page:"email_analytics", 
				filter:state.filter
			}).then((response)=>{
				if(response.result) {
					commit('setFilter', response.filter);	 
					dispatch('loadEmailAnalytics');
				}				 
			})	 
		},
		async loadEmailAnalytics ({ state, commit }){ 
			await emailAnalyticsApi.getData({ filter:state.filter }).then((response)=>{
				if(response.result) {
					commit('setData', { chart:response.chart, survey:response.survey, survey_items:response.survey_items, rating_fill:response.rating_fill, font_color:response.font_color, rating_figure:response.rating_figure, nps_avg:response.nps_avg });	  
				}				 
			})	   
		},
		async setModalUserSurvey({state, commit}, data){ 
			if(data.show)
				await emailAnalyticsApi.getUserResponse({ survey_id:state.filter.survey, company_id:data.company_id, email:data.email }).then((response)=>{
					if(response.result) {
						commit('setModalUserSurvey', { show:data.show, user_response:response.user_response, rating_rate:response.rating_rate });	  
					}				 
				})
			else
				commit('setModalUserSurvey', { show:false, user_response:null, rating_rate:null });	   
		},
		async setFilterViewChart({ state, commit, dispatch, rootGetters }, view){
			
			commit('setFilterViewChart', view);	

			await emailAnalyticsApi.setFilter({ 
				user_id:rootGetters['account/account'].user_id, 
				page:"email_analytics", 
				filter:state.filter
			})
		},
		async deleteResponse({ state, commit, dispatch }, data){

			await emailAnalyticsApi.deleteResponse({ 
				survey_id:state.filter.survey, 
				type:data.type, 
				deal_id:data.deal_id,
				val:data.val
			}).then((response)=>{
				if(response.result){
					commit("deleteResponseRating", data);
					dispatch("loadEmailAnalytics")
				}
			}) 

		}
	}, 
} 