import server from '@/api/http';

export async function getData(params){  
	let { data } = await server.get('expenses/input_data/get_data', { params });
	return data;
} 

export async function saveExpenses(params){  
	let { data } = await server.get('expenses/input_data/save_expenses', { params });
	return data;
} 

export async function delExpenses(params){  
	let { data } = await server.get('expenses/input_data/del_expenses', { params });
	return data;
} 

export async function saveItemExpenditure(params){  
	let { data } = await server.get('expenses/input_data/save_item_expenditure', { params });
	return data;
} 

export async function delItemExpenditure(params){  
	let { data } = await server.get('expenses/input_data/del_item_expenditure', { params });
	return data;
} 

export async function saveSuppliers(params){  
	let { data } = await server.get('expenses/input_data/save_suppliers', { params });
	return data;
} 

export async function delSuppliers(params){  
	let { data } = await server.get('expenses/input_data/del_suppliers', { params });
	return data;
}  

export async function savePayer(params){  
	let { data } = await server.get('expenses/input_data/save_payer', { params });
	return data;
} 
 
export async function delPayer(params){  
	let { data } = await server.get('expenses/input_data/del_payer', { params });
	return data;
}   
export async function validExpenses(params){  
	let { data } = await server.get('expenses/input_data/valid', { params });
	return data;
} 
export async function upSort(params){  
	let { data } = await server.get('expenses/input_data/up/sort', { params });
	return data;
}  
 