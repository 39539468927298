import server from '@/api/http';
 
export async function getFilter(params){  
	let { data } = await server.get('expenses/result_period/get_filter', { params });
	return data;
} 

export async function getData(params){  
	let { data } = await server.get('expenses/result_period/get_data', { params });
	return data;
} 

export async function setFilter(params){  
	let { data } = await server.get('expenses/result_period/set_filter', { params });
	return data;
}   