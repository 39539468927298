import * as WarehouseMinRemainsApi from '@/api/warehouse_min_remains.js';
 
export default {
	namespaced: true,
	state: {
		show:false,
		items:[],
		count:0, 	 
	},
	getters: {
		warehouse_min_remains:state=>{
			return state
		},  
	},
	mutations: {  
		setItems(state, data){  
			let items = [];
			
			for(let item of data.notify_min_remains) 
				if(item.status) items.push(item);
			  
			state.items = items;   
			state.count = data.count;  
		},      
		setShow(state, data){   
			state.show = data.show;  	 
		},      
	},
	actions: {  
		async showMinRemains ({ commit }, data){  
			commit('setShow', data); 
		},		
		async loadMinRemains ({ commit }){ 
			 
			let response = await WarehouseMinRemainsApi.get();	 
			if(response.result) {  
				commit('setItems', response); 
			}	 
		},
	
	}, 
} 