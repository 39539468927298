import * as chartsApi from '@/api/charts.js';
export default {
	namespaced: true,
	state: {
		dialog_details:{
			show:false,
			data:null
		},
		dialog_group_details:{
			show:false,
			data:null
		},
		chart_index:{
			show:false,
			data:null,
			charts:null,
			page:null
		},
	},
	getters: {
		dialog:state=>{
			return state
		}, 
	},
	mutations: { 
		setDialogDetails(state, data){ 
			state.dialog_details.show = data.show  
			state.dialog_details.data = data.data  
		}, 
		setDialogGroupDetails(state, data){ 
			state.dialog_group_details.show = data.show  
			state.dialog_group_details.data = data.data  
		},   
		setDialogChartIndex(state, data){ 
			state.chart_index.show = data.show  
			state.chart_index.data = data.data 
			state.chart_index.charts = data.charts 
			state.chart_index.page = data.page 
			console.log(data);
		},   
	},
	actions: {
		setDialogDetails({ commit }, data){ 
			commit('setDialogDetails', data); 
		},
		setDialogGroupDetails({ commit }, data){ 
			commit('setDialogGroupDetails', data); 
		},
		async setDialogChartIndex({ state, commit, rootGetters }, data){ 
			 
			let show = data.show
			let page = data.page
			let charts = data.charts
			let filter = null;
			let chart_data = null;
			if(show) {

				let view_departament = "all";
				if(page == "callcenter") view_departament = rootGetters['callcenter/callcenter'].view_departament;
				if(page == "base") view_departament = rootGetters['base/base'].view_departament;

				let view_managers = [
					{
						user_id:rootGetters['account/account'].user_id,
						b_user_id:rootGetters['account/account'].bitrix.user_id,
						status:true,
					} 
				];
				let access = rootGetters['account/account'].access;
	
				if(access !== null)  	 
					for(let key in access){  
						if(access[key].hasOwnProperty("admin")){
							view_managers = [];
							 
							if(view_departament == "all"){  
								 
								for(let key_d in access[key].users_departament[page])    
									if(access[key].users_departament[page][key_d].length !== 0)
										for(let key_du in access[key].users_departament[page][key_d]) 
											view_managers.push(access[key].users_departament[page][key_d][key_du])
							}
							else{
								for(let key_d in access[key].users_departament[page]) 	 
									if(key_d == view_departament && access[key].users_departament[page][key_d].length !== 0)
										view_managers = access[key].users_departament[page][key_d];							 
							} 
						}
						else{
	
							if(
								access[key].hasOwnProperty("departament_type") && 
								access[key].departament_type == page && 
								access[key].hasOwnProperty("permissions")  
							)  
								for(let key_p in access[key].permissions) 
									if(
										access[key].permissions[key_p] == "see_department" ||
										access[key].permissions[key_p] == "set_plan_department" 
									)
									view_managers = access[key].users_departament 
						}
					}

				if(page == "callcenter") filter = rootGetters['callcenter/callcenter'].filter
				if(page == "base") filter = rootGetters['base/base'].filter

				let data = await chartsApi.get({ page, charts, filter, view_managers });	 
				if(data.result) chart_data = data.chart_data; 
			}
  
			commit('setDialogChartIndex', { show, data:chart_data, charts, page}); 

		},  
	}
}