<template> 
  <component :is="layout">  
    <router-view></router-view>
  </component>  
</template> 

<script>
import layout_page from '@/layouts/layout_page.vue'
import layout_account from '@/layouts/layout_account.vue'
import layout_auth from '@/layouts/layout_auth.vue'

import { markRaw } from "vue";

export default {
name: "AppLayout", 
computed: { 
  layout(){  
      let layout = layout_page;

      if(this.$route.meta.layout == "page") layout = layout_page;
      if(this.$route.meta.layout == "account") layout = layout_account; 
      if(this.$route.meta.layout == "auth") layout = layout_auth; 

      return markRaw(layout)
  }
},
}
</script>