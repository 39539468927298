import * as salespfApi from '@/api/sales_pf.js';

export default {
	namespaced: true,
	state: {
		filter:null, 
		table:null,
		timeout:null, 
	},
	getters: {
		sales_pf_plan:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.table = data.table;  
		},
		upTotal(state, data){
			
			let total_direction = 0;
			 
			for(let item of state.table.items[data.key][data.type])
				if(item.id !== "name" && item.id !== "total")
					total_direction = total_direction + parseFloat(item.val)

			let key_total = state.table.items[data.key][data.type].length - 1

			state.table.items[data.key][data.type][key_total].val = total_direction;

			////
			 
			let total_month = 0;
			for(let key in state.table.items)
				for(let item of state.table.items[key][data.type])					
					if(item.id == data.month){
						total_month = total_month + item.val; 		
					}	
							 
			for(let key in state.table.total[data.type])
				if(state.table.total[data.type][key].id == data.month){
					state.table.total[data.type][key].val = total_month;

					let type = data.type == 'revenue_plan' ? 'revenue_fact' : 'contract_fact';
					let type_precent = data.type == 'revenue_plan' ? 'revenue_precent' : 'contract_precent';	
					 
					let fact = state.table.total[type][key].val;
					  
					fact = typeof fact === 'string' || fact instanceof String ? parseFloat(fact.replace(/ /g,'').replace(",", ".")) : fact;
 
					state.table.total[type_precent][key].val = state.table.total[data.type][key].val == 0 || state.table.total[data.type][key].val == 0.0 
						? 0 
						: Math.round(((fact/state.table.total[data.type][key].val)*100) * 100) / 100 ;
				}

			let total = 0;
			for(let key in state.table.total[data.type])
				if(state.table.total[data.type][key].id !== "name" && state.table.total[data.type][key].id !== "total"){

					let plan = state.table.total[data.type][key].val;

					plan = typeof plan === 'string' || plan instanceof String ? parseFloat(plan.replace(/ /g,'').replace(",", ".")) : plan;

					total = total + plan;  
				}

			let key_direction_total = state.table.total[data.type].length - 1;

			state.table.total[data.type][key_direction_total].val = total;


			 

			let total_type = data.type == 'revenue_plan' ? 'revenue_fact' : 'contract_fact';
			let total_type_precent = data.type == 'revenue_plan' ? 'revenue_precent' : 'contract_precent';	

			let total_key = state.table.total[total_type].length - 1;
				
			let total_plan = state.table.total[data.type][total_key].val;
			let total_fact = state.table.total[total_type][total_key].val;
				
			total_plan = typeof total_plan === 'string' || total_plan instanceof String ? parseFloat(total_plan.replace(/ /g,'').replace(",", ".")) : total_plan;
			total_fact = typeof total_fact === 'string' || total_fact instanceof String ? parseFloat(total_fact.replace(/ /g,'').replace(",", ".")) : total_fact;

			state.table.total[total_type_precent][total_key].val = total_plan == 0 || total_plan == 0.0 
						? 0 
						: Math.round(((total_fact/total_plan)*100) * 100) / 100 ;
			 

			/////

		}
	},
	actions: {
		async loadFilter ({ commit, dispatch, rootGetters }){
			let response = await salespfApi.getFilterPlan({user_id:rootGetters['account/account'].user_id, page:"sales_pf_plan"});	 
			if(response.result) {
				commit('setFilter', response.filter);	
				dispatch("loadData");	
			}				 
		},    
		async setFilterSalesPfPlan({ dispatch, rootGetters }, filter){
			let response = await salespfApi.setFilterPlan({ user_id:rootGetters['account/account'].user_id, page:"sales_pf_plan", filter });	 
			if(response.result) {
				dispatch("loadData");	
			}				 
		},
		async loadData({ state, commit }){ 
			let response = await salespfApi.getDataPlan({ filter:state.filter });	 
			if(response.result) commit('setData', response);						 
		}, 
		setValue({ state, commit }, data){ 
			  
			clearTimeout(state.timeout);

			state.timeout = setTimeout( async () => {
 
				commit('upTotal', { key:data.key, direction_id:data.direction_id, type:data.type, month:data.month });
				await salespfApi.upPlanDirection({ 
					direction_id:data.direction_id, 
					type:data.type,  
					year:state.filter.year,
					values:state.table.items[data.key][data.type]
				});	 
			}, 800);
			 
		} 
	}, 
} 