import * as salespfApi from '@/api/sales_pf.js';

export default {
	namespaced: true,
	state: {
		funnels:[], 
		deals_type:[],
		direction:null,
		period:null,
		up_status:false,
		new_direction:{
			show:false,
			type:null,
		}
	},
	getters: {
		sales_pf_input:state=>{
			return state
		}, 
	},
	mutations: { 
		setData(state, data){ 
			state.funnels = data.funnels;  
			state.deals_type = data.deals_type;   
			state.direction = data.direction;  
			state.period = data.period;  
		},
		upStatus(state, status){ 
			state.up_status = status
		},
		setNewDirection(state, data){ 
			state.new_direction.show = data.status
			state.new_direction.type = data.type
		}
	},
	actions: {
		async loadDataInput({ commit }){ 
			let response = await salespfApi.getDataInput();	 
			if(response.result) commit('setData', response);						 
		}, 
		async upDirection({ state, dispatch, commit }, data){ 
			
			let id = null;
			let name = null;
			let funnel = null;
			let type = null;
			let deal_type = null;
			let action = data.action;
			let status = true;
 
			if(data.key !== null){ 
				id = state.direction[data.key].id;
				name = state.direction[data.key].name;
				funnel = state.direction[data.key].funnel;   
				type = state.direction[data.key].type;  
				deal_type = state.direction[data.key].deal_type;   
			}
			 
			if(action == "add" && state.new_direction.type == null)  
				status = false;
			if(action == "add" && state.new_direction.type !== null) 
				type = state.new_direction.type;   
 
			if(status){ 
				let response = await salespfApi.upDirectionInput({ action, id, name, funnel, type, deal_type });	 
				if(response.result) {
					dispatch("loadDataInput");
					dispatch("snackBar/showSnackBar", "Данные сохранены!", { root:true });		
					commit('setNewDirection', { status:false, type:null });			 					 
				}
			}
		}, 
		async upPeriod({ state, dispatch, commit }){ 
			commit('upStatus', true);
			let response = await salespfApi.upPeriodInput({ period:state.period });	 
			if(response.result){
				dispatch("snackBar/showSnackBar", "Данные сохранены!", { root:true });		
				commit('upStatus', false);
			}				 
		}, 
		async endDrag({ state, dispatch }){ 
			
			let directions = [];

			for(let items of state.direction)
				directions.push({id:items.id});

			await salespfApi.upSortDirections({ directions });	  				 
		}, 
		setNewDirection({ commit }, data){ 
			
			commit('setNewDirection', data);				 
		}, 
		 
	}, 
} 