import server from '@/api/http';

export async function getFilter(params){  
	let { data } = await server.get('warehouse/workshop/get_filter', { params });
	return data;
}  
export async function setFilter(params){  
	let { data } = await server.get('warehouse/workshop/set_filter', { params });
	return data;
} 
export async function getData(params){  
	let { data } = await server.get('warehouse/workshop/get_data', { params });
	return data;
}
export async function getOrder(params){  
	let { data } = await server.get('warehouse/workshop/get_order', { params });
	return data;
} 
export async function setPosition(params){  
	let { data } = await server.get('warehouse/workshop/set/position', { params });
	return data;
}  
export async function runJob(params){  
	let { data } = await server.get('warehouse/workshop/run/job', { params });
	return data;
}   
export async function confirmOrder(params){  
	let { data } = await server.get('warehouse/workshop/confirm/order', { params });
	return data;
}   
 