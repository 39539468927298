import server from '@/api/http';
 
export async function getFilter(params){  
	let { data } = await server.get('ebitda/project/get_filter', { params });
	return data;
} 

export async function getData(params){  
	let { data } = await server.get('ebitda/project/get_data', { params });
	return data;
} 

export async function setFilter(params){  
	let { data } = await server.get('ebitda/project/set_filter', { params });
	return data;
}  