<template> 
  <v-dialog v-model="dialog.chart_index.show" max-width="1000" persistent class="dialog-details">
    <v-toolbar color="blue-analytics-1">
      <v-toolbar-title>Динамика</v-toolbar-title>
      <v-btn icon @click="setDialogChartIndex({show:false, page:null, data:null, charts:null})">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card> 
      <v-card-text>  
        <v-tabs
          v-model="tab"
          color="blue-analytics-1"
          align-tabs="start"
        >
          <v-tab v-for="(items, key) in dialog.chart_index.data" :value="items.id" :key="key">{{ items.name }}</v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item v-for="(items, key) in dialog.chart_index.data" :value="items.id" :key="key">
            <v-container fluid>
              <v-row>
                <v-col style="height: 520px;">
          
                  <chart-line :data="items.data"></chart-line>
                
                </v-col>
              </v-row>
            </v-container>
          </v-window-item>
        </v-window> 
      </v-card-text> 
    </v-card>
  </v-dialog>  
</template> 
<script>  
import { mapGetters, mapActions } from 'vuex'

import ChartLine from '@/components/charts/Line.vue'

export default { 
  data () {
    return {
      tab: null, 
    }
  },
  computed:{  
    ...mapGetters("dialog", {dialog:"dialog"}), 
  },
  methods:{   
    ...mapActions("dialog", ["setDialogChartIndex"]), 
  },
  components: { 
    ChartLine,
  } 
}
</script>