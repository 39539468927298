import * as ebitdaApi from '@/api/ebitda_project.js';

export default {
	namespaced: true,
	state: { 
		filter:null,   
		table:[],
		deals:[],
		order_by:{
			revenue:'desc',
			expenses_summ:null,
			diff:null,
			profit:null,
			date_create:null,
			date_close:null,
			deal_time:null,
		},   
	},
	getters: {
		ebitda_project:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.table = data.table;  
			state.deals = data.deals;  
		}, 
		openTable(state, key){ 
			state.table.project[key].more = state.table.project[key].more ? false : true;  
		},
		setOrderBy(state, type){ 

		 
			for(let key in state.order_by)
				if(key == type){
					state.order_by[key] = state.order_by[key] == null ? 'desc' : state.order_by[key] == 'desc' ? 'asc' : 'desc'; 
				}
				else state.order_by[key] = null;
			
			  
			// if(type == "revenue"){  
			// 	state.order_by.revenue = state.order_by.revenue == null ? 'desc' : state.order_by.revenue == 'desc' ? 'asc' : 'desc'; 

			// 	state.order_by.revenue = null
			// 	state.order_by.expenses_summ = null
			// 	state.order_by.diff = null
			// 	state.order_by.profit = null
			// 	state.order_by.date_create = null 
			// 	state.order_by.date_close = null 
			// 	state.order_by.deal_time = null 
			// }  
		  
		},      	 
	},
	actions: { 
		async loadFilterEbitdaProject ({ commit, dispatch, rootGetters }){
			let data = await ebitdaApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"ebitda_project"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadDataEbitdaProject", data.filter);	
			}				 
		},    
		async setFilterEbitdaProject ({ dispatch, rootGetters }, filter){
			let data = await ebitdaApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"ebitda_project", filter });	 
			if(data.result) {
				dispatch("loadDataEbitdaProject", data.filter);	
			}				 
		},
		async loadDataEbitdaProject({ commit, state }, filter){  
			let data = await ebitdaApi.getData({ filter, order_by:state.order_by } );	 
			if(data.result) {
				commit('setData', data);	 
			}		 
		},  
		openTable({ commit }, key){  
			commit('openTable', key);
		},
		orderBy({ commit, state, dispatch }, type){
			commit('setOrderBy', type);	
			dispatch("loadDataEbitdaProject", state.filter);	
		}
	}, 
} 