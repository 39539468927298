import server from '@/api/http';
 
export async function getEntry(params){  
	let { data } = await server.get('warehouse/product_card/get_entry', { params });
	return data;
}    
export async function setPosition(params){  
	let { data } = await server.get('warehouse/product_card/set/position', { params });
	return data;
}  
export async function saveChange(params){  
	let { data } = await server.get('warehouse/product_card/save/change', { params });
	return data;
}  
export async function cancelChange(params){  
	let { data } = await server.get('warehouse/product_card/cancel/change', { params });
	return data;
}    