import server from '@/api/http';
 
export async function getFilter(params){  
	let { data } = await server.get('warehouse/orders/get_filter', { params });
	return data;
}  
export async function getData(params){  
	let { data } = await server.get('warehouse/orders/get_data', { params });
	return data;
}  
export async function getOrder(params){  
	let { data } = await server.get('warehouse/orders/get/order', { params });
	return data;
}  
export async function setFilter(params){  
	let { data } = await server.get('warehouse/orders/set_filter', { params });
	return data;
}   
export async function createOrder(params){  
	let { data } = await server.get('warehouse/orders/create', { params });
	return data;
}  
export async function upOrder(params){  
	let { data } = await server.get('warehouse/orders/up', { params });
	return data;
} 
export async function delOrder(params){  
	let { data } = await server.get('warehouse/orders/del', { params });
	return data;
}
export async function setOrder(params){  
	let { data } = await server.get('warehouse/orders/set', { params });
	return data;
}  
export async function setPosition(params){  
	let { data } = await server.get('warehouse/orders/set/position', { params });
	return data;
}  
export async function runWorkshop(params){  
	let { data } = await server.get('warehouse/orders/run/workshop', { params });
	return data;
}    
export async function cancelWorkshop(params){  
	let { data } = await server.get('warehouse/orders/cancel/workshop', { params });
	return data;
}
export async function confirmWorkshop(params){  
	let { data } = await server.get('warehouse/orders/confirm/workshop', { params });
	return data;
} 
export async function returnWorkshop(params){  
	let { data } = await server.get('warehouse/orders/return/workshop', { params });
	return data;
}     
export async function returnForConfirmation(params){  
	let { data } = await server.get('warehouse/orders/return/confirmation', { params });
	return data;
}    
export async function downloadFiles(params){  
	let { data } = await server.post('warehouse/orders/download/files', params, { headers: {'Content-Type': 'multipart/form-data' } });
	return data;
}
export async function delFile(params){  
	let { data } = await server.get('warehouse/orders/del/file', { params });
	return data;
}    
export async function runUnity(params){  
	let { data } = await server.get('warehouse/orders/unity', { params });
	return data;
}   
export async function saveChange(params){  
	let { data } = await server.get('warehouse/orders/save/change', { params });
	return data;
}   
export async function cancelChange(params){  
	let { data } = await server.get('warehouse/orders/cancel/change', { params });
	return data;
}   
export async function setPositionModify(params){  
	let { data } = await server.get('warehouse/orders/set/position/modify', { params });
	return data;
}   
export async function getModify(params){  
	let { data } = await server.get('warehouse/orders/get/modify', { params });
	return data;
}
export async function setDataWriteOffs(params){  
	let { data } = await server.get('warehouse/orders/set/date_write_offs', { params });
	return data;
}   
 