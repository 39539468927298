import * as warehouseRemainsCardApi from '@/api/warehouse_remains_card.js';
import * as warehouseOrdersApi from '@/api/warehouse_orders.js';

export default {
	namespaced: true,
	state: {
		show:false,
		filter:null,
		table:[],
		total:[],
		selected:[], 
		//delete_disabled:[],  
		group_by:{ 
			category_id:false,
			type_id:false,
			class_id:false,
			colour_id:false,
			supplier_id:false,
			articles_supplier_id:false,
			articles_pzn_id:false,
			payer_id:false,
		},
		order_by:{
			position_id:null,
			article_pzn:'asc',
			remains:null, 
			remains_hold:null
		}, 
		notify:{
			show:false,
			message:null,
		},
		page:1,
		get_row:20,
		quantity_page:null
	},
	getters: {
		warehouse_remains_card:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setNotify(state, data){ 
			state.notify.show = data.status;
			state.notify.message = data.message; 
		}, 
		setData(state, data){   
			  
			if(data.table.length > 0 && state.selected.length > 0)
				for(let key in data.table)
					for(let k in state.selected){
						if(state.selected[k].position_id == data.table[key].position_id){
							data.table[key].action = null; 
						}
					}
					
			state.table = data.table;    
			state.total = data.total;
			state.quantity_page = data.quantity_page;   
		},
		nullableData(state){
			state.table = [];
			state.total = [];    
		},
		pushSelected(state, data){ 
			state.selected.push(state.table[data.key]); 
			state.table[data.key].action = "del";
		},  
		spliceSelected(state, data){  

			for(let k in state.selected)
				if(state.selected[k].position_id == data.position_id) 
					state.selected.splice(k, 1); 			
			
			if(data.key == null){
				for(let key in state.table)
					if(state.table[key].position_id == data.position_id) 
						state.table[key].action = "add";
			}
			else 
				state.table[data.key].action = "add";
			 
		}, 
		nullableSelected(state){  
			state.selected = [];

			for(let k in state.table) 
				state.table[k].action = "add";
		}, 
		showCard(state, data){ 
			if(!data.status){
				state.selected = [];
				state.table = [];
			}			
			if(data.positions.length !== 0)
				for(let key in data.positions){
					if(key !== "total"){
						for(let k in data.positions[key].items){  
							state.selected.push(data.positions[key].items[k]); 
						}
					}
				}
			console.log(state.selected);
			state.show = data.status
		},
		setGroupBy(state, param_id){ 
			state.group_by[param_id] = state.group_by[param_id] ? false : true;
			if(param_id == "category_id" && !state.group_by.category_id){ 
				state.group_by.type_id = false;  
				state.group_by.class_id = false;   
			}
			if(
				(param_id == "type_id" || param_id == "class_id") && 
				(state.group_by.type_id || state.group_by.class_id)
			) 
				state.group_by.category_id = true;  	 
		},
		setOrderBy(state, type){  
			if(type == "position_id"){  
				state.order_by.position_id = state.order_by.position_id == null ? 'desc' : state.order_by.position_id == 'desc' ? 'asc' : 'desc'; 
				state.order_by.article_pzn = null 	
				state.order_by.remains = null 	 
				state.order_by.remains_hold = null 	
			}
			if(type == "article_pzn"){  
				state.order_by.article_pzn = state.order_by.article_pzn == null ? 'desc' : state.order_by.article_pzn == 'desc' ? 'asc' : 'desc'; 
				state.order_by.position_id = null 	
				state.order_by.remains = null 	 
				state.order_by.remains_hold = null 	
			}
			if(type == "remains"){  
				state.order_by.remains = state.order_by.remains == null ? 'desc' : state.order_by.remains == 'desc' ? 'asc' : 'desc'; 
				state.order_by.position_id = null 	
				state.order_by.article_pzn = null 	 
				state.order_by.remains_hold = null 	
			}
			if(type == "remains_hold"){  
				state.order_by.remains_hold = state.order_by.remains_hold == null ? 'desc' : state.order_by.remains_hold == 'desc' ? 'asc' : 'desc'; 
				state.order_by.position_id = null 	
				state.order_by.article_pzn = null 	 
				state.order_by.remains = null 	
			}  
		},
		setPage(state, page){ 
			state.page = page 
		}, 
		resetFilter(state){  	  
			state.filter.articles_supplier_id = null;
			state.filter.articles_pzn_id = null;
			state.filter.category_product_id = null;
			state.filter.type_product_id = null;
			state.filter.class_product_id = null;
			state.filter.colour_product_id = null;
			state.filter.names_product_id = null;
			state.filter.position_id = null;
			state.filter.supplier_product_id = null;
			state.filter.payer_product_id = null;
			state.filter.place.zone = null;
			state.filter.place.shelf = null;
			state.filter.nullable_reserve = false;
			state.filter.nullable_remains = false;				 
		},  
	},
	actions: { 
		async loadFilterWarehouseRemainsCard ({ commit, dispatch, rootGetters }){
			let data = await warehouseRemainsCardApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"warehouse_remains_card"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadDataWarehouseRemainsCard", { filter:data.filter });	
			}				 
		},    
		async setFilterWarehouseRemainsCard ({ dispatch, rootGetters, commit }, filter){
			let data = await warehouseRemainsCardApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"warehouse_remains_card", filter });	 
			if(data.result) {
				commit('setPage', 1); 
				dispatch("loadDataWarehouseRemainsCard", { filter:data.filter });	
			}				 
		},
		async loadDataWarehouseRemainsCard({ state, commit }, data){ 
			let response = await warehouseRemainsCardApi.getData({ 
				filter:data.filter, 
				group_by:state.group_by, 
				page:state.page, 
				get_row:state.get_row,
				order_by:state.order_by 
			});	 
			if(response.result) {  
				commit('setData', response);						 
			}
		},
		async createOrder({ commit, state, dispatch }){
		 
			let positions = [];
			
			if(state.selected.length == 0) { 
				dispatch("showNotify", { status:true, message:"Для создания заказа выберите позиции" });
				return { status:false, order_id:null };
			}
			else{
				for(let key in state.selected) 
					positions.push(state.selected[key].position_id);
				
				let data = await warehouseOrdersApi.createOrder({ positions });	 
				if(data.result) {
					commit('nullableSelected');
					return  { status:true, order_id:data.order_id };  
				}	
			}	
		},
		async upOrder({ commit, state, rootGetters, dispatch }){
			
			let positions = [];
			let order_id = rootGetters['warehouse_order_card/warehouse_order_card'].order.order_id;
			 
			if(state.selected.length == 0) dispatch("showNotify", { status:true, message:"Для обновления должна быть выбрана хотя бы одна позиция" }); 
			else{ 
				for(let key in state.selected) 
					positions.push(state.selected[key].position_id);

				let data = await warehouseOrdersApi.upOrder({ order_id, positions });	 
					if(data.result) {
						commit('nullableSelected');
						await dispatch("showWarehouseRemainsCard", { status:false, positions:[] });
						await dispatch("warehouse_orders/loadDataWarehouseOrders", 
							{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
							{ root: true }
						);
						dispatch("warehouse_order_card/showWarehouseOrderCard", { status:true, order_id, card_type:"orders", modify:false }, { root: true });
						dispatch("warehouse_order_card/updateStatus", true, { root: true });
						 
					}	
			} 
		},
		pushSelected({ commit }, data){   
			commit('pushSelected', data);		 
		},    
		spliceSelected({ commit }, data){  
			commit('spliceSelected', data);		 
		},    
		async showWarehouseRemainsCard({ commit, dispatch }, data){ 
			console.log(data);
			commit('showCard', data);	
			if(data.status)
				await dispatch("loadFilterWarehouseRemainsCard");	 	 
		},    
		groupBy({ state, commit, dispatch }, param_id){  
			commit('setGroupBy', param_id);	  
			dispatch("loadDataWarehouseRemainsCard", { filter:state.filter });
		},
		showNotify({ commit }, data){
			commit('setNotify', data);	
		},
		nullableData({ commit }){
			commit('nullableData');	
		},
		resetFilter({ state, commit, dispatch }){
			commit('resetFilter');	
			dispatch("loadDataWarehouseRemainsCard", { filter:state.filter });	
		},
		orderBy({ commit, state, dispatch }, type){ 		 
			commit('setOrderBy', type);	
			dispatch("loadDataWarehouseRemainsCard", { filter:state.filter });	
		},
	}, 
} 