import server from '@/api/http';
 
export async function getFilter(params){  
	let { data } = await server.get('fot/statement/get_filter', { params });
	return data;
} 

export async function getData(params){  
	let { data } = await server.get('fot/statement/get_data', { params });
	return data;
} 

export async function setFilter(params){   
	let { data } = await server.get('fot/statement/set_filter', { params });
	return data;
}   

 
export async function saveManagerTable5(params){  
	console.log(params);
	let { data } = await server.post('fot/statement/table_5/manager/save', params);
	return data;
}   

export async function delManagerTable5(params){  
	let { data } = await server.get('fot/statement/table_5/manager/del', { params });
	return data;
}   

export async function saveDealTable5(params){  
	let { data } = await server.get('fot/statement/table_5/deal/save', { params });
	return data;
}   

export async function delDealTable5(params){  
	let { data } = await server.get('fot/statement/table_5/deal/del', { params });
	return data;
}   

export async function saveManagerTable7(params){  
	let { data } = await server.get('fot/statement/table_7/manager/save', { params });
	return data;
}   
export async function delManagerTable7(params){  
	let { data } = await server.get('fot/statement/table_7/manager/del', { params });
	return data;
}   
  
export async function saveManagerTable10(params){  
	let { data } = await server.get('fot/statement/table_10/manager/save', { params });
	return data;
}   
export async function delManagerTable10(params){  
	let { data } = await server.get('fot/statement/table_10/manager/del', { params });
	return data;
}   
 
export async function sendExpenses(params){  
	let { data } = await server.get('expenses/accounting/set_fot', { params });
	return data;
}   
 