import server from '@/api/http';

export async function getSurveys(params){  
	let { data } = await server.get('marketing/email/get_surveys', { params });
	return data;
}
export async function getSurvey(params){  
	let { data } = await server.get('marketing/email/get_survey', { params });
	return data;
}
export async function upSurvey(params){  
	let { data } = await server.post('marketing/email/up_survey', params);
	return data;
}
export async function deleteSurveys(params){  
	let { data } = await server.get('marketing/email/delete_survey', { params });
	return data;
}
export async function changeResponse(params){  
	let { data } = await server.get('marketing/email/change_response', { params });
	return data;
}
export async function saveRatingRate(params){  
	let { data } = await server.get('marketing/email/save_rating_rate', { params });
	return data;
}
export async function deleteResponseCustom(params){  
	let { data } = await server.get('marketing/email/delete_response_custom', { params });
	return data;
}
 