<template>
  <v-navigation-drawer v-model="appInterface.account.show_drawer">  
    <v-img :src="require('@/assets/logo.png')" width="150" style="width: 91px;margin-left: 12px;padding: 14px;"></v-img>
    <v-divider></v-divider>  
    <v-list>
      
      <v-list-item 
        v-if="analytics.access"
        :active="
          $route.name === 'analytics-callcenter' || 
          $route.name === 'analytics-base' || 
          $route.name === 'account-optional'" 
        prepend-icon="mdi-finance" title="Аналитика" :to="{ name:analytics.route_name }" active-color="blue-analytics-1" rounded="xl">
      </v-list-item>  
      
      <v-list-item 
        v-if="current_status.access"
        :active="
          $route.name === 'current-status-callcenter' || 
          $route.name === 'current-status-base'" 
        prepend-icon="mdi-chart-pie" title="Текущее состояние" :to="{ name:current_status.route_name }" active-color="blue-analytics-1" rounded="xl">
      </v-list-item>
       
      <v-list-item
        v-if="result_period.access"
        :active="
          $route.name === 'result-period-callcenter' || 
          $route.name === 'result-period-base'" 
          prepend-icon="mdi-poll" title="Результат периода" :to="{ name:result_period.route_name }" active-color="blue-analytics-1" rounded="xl">
      </v-list-item>

      <v-list-item 
        v-if="sales_pf.access"
        :active="
          $route.name === 'sales-pf-current' ||
          $route.name === 'sales-pf-plan' ||
          $route.name === 'sales-pf-input'      
        "
          prepend-icon="mdi-chart-donut" title="Продажи" :to="{ name:sales_pf.route_name }" active-color="blue-analytics-1" rounded="xl">
      </v-list-item>

      <v-list-item 
        v-if="expenses.access"
        :active="
          $route.name === 'expenses-result-period' || 
          $route.name === 'expenses-accounting' ||
          $route.name === 'expenses-budget' ||
          $route.name === 'expenses-input-data'" 
          prepend-icon="mdi-chart-arc" title="Расходы" :to="{ name:expenses.route_name }" active-color="blue-analytics-1" rounded="xl">
      </v-list-item>

      <v-list-item 
        v-if="fot.access"
        :active="
          $route.name === 'fot-result' || 
          $route.name === 'fot-statement'" 
          prepend-icon="mdi-account-cash-outline" title="ФОТ" :to="{ name:fot.route_name }" active-color="blue-analytics-1" rounded="xl">
      </v-list-item>

      <v-list-item 
        v-if="ebitda.access" 
        :active="$route.name === 'ebitda-project'" 
          prepend-icon="mdi-chart-timeline" title="EBITDA" :to="{ name:ebitda.route_name }" active-color="blue-analytics-1" rounded="xl">
      </v-list-item> 

      <v-list-item 
        v-if="warehouse.access"
        :active="
          $route.name === 'warehouse-entry' ||
          $route.name === 'warehouse-remains' ||
          $route.name === 'warehouse-orders' ||
          $route.name === 'warehouse-workshop' || 
          $route.name === 'warehouse-write-offs' ||
          $route.name === 'warehouse-reports' ||
          $route.name === 'warehouse-input-data'     
        "
          prepend-icon="mdi-warehouse" title="Склад" :to="{ name:warehouse.route_name }" active-color="blue-analytics-1" rounded="xl">
      </v-list-item>


      <v-list-group value="marketing"  v-if="marketing.access">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-target-variant"
            title="Маркетинг"
          ></v-list-item>
        </template>

        <v-list-item 
          v-if="marketing.marketing_email_access"
          :active="
            $route.name === 'marketing-email-surveys' ||
            $route.name === 'marketing-email-analytics'
          "
          title="Email" :to="{ name:marketing.marketing_email_route_name }" active-color="blue-analytics-1" rounded="xl"></v-list-item>

          <v-list-item 
          v-if="marketing.marketing_track_analytic_access"
          :active="
            $route.name === 'marketing-track-analytic-report' ||
            $route.name === 'marketing-track-analytic-utm' ||
            $route.name === 'marketing-track-analytic-settings'
          "
          title="Сквозная аналитика" :to="{ name:marketing.marketing_track_analytic_route_name }" active-color="blue-analytics-1" rounded="xl"></v-list-item>

          <v-list-item 
          v-if="marketing.marketing_publications_networks_access"
          :active="
            $route.name === 'marketing-publications-networks-create' ||
            $route.name === 'marketing-publications-networks-delay' ||
            $route.name === 'marketing-publications-networks-published' ||
            $route.name === 'marketing-publications-networks-settings'
          "
          title="Публикации" :to="{ name:marketing.marketing_publications_networks_route_name }" active-color="blue-analytics-1" rounded="xl"></v-list-item>
      </v-list-group>
       

      <v-list-item 
        v-if="settings.access"
        :active="
          $route.name === 'admin-users' || 
          $route.name === 'admin-managers' ||
          $route.name === 'admin-managers-plan' ||
          $route.name === 'admin-managers-kpi'" 
          prepend-icon="mdi-cog-outline" title="Управление" :to="{ name:settings.route_name }" active-color="blue-analytics-1" rounded="xl">
      </v-list-item>
    </v-list>

  </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex'
export default { 
  data: () => ({ 
  }),
  computed:{ 
    ...mapGetters("appInterface", {appInterface:"appInterface"}),
    ...mapGetters("account", {account:"account"}),
    analytics(){
      let access = false;  
      let route_name = this.account.sort_page.analytics.callcenter_base[0].route_name;

      let perm_callcenter = false;
      let perm_base = false;

      if(this.account.access !== null) 
        for(let key in this.account.access){      
          if(this.account.access[key].hasOwnProperty("admin")) {
            perm_callcenter = true;
            perm_base = true;
          }
          else{            
            if(this.account.access[key].hasOwnProperty("departament_type") && this.account.access[key].departament_type == "callcenter")             
              perm_callcenter = true;
            
            if(this.account.access[key].hasOwnProperty("departament_type") && this.account.access[key].departament_type == "base")             
              perm_base = true;            
          }
        }  

        if(perm_callcenter && !perm_base){
          access = true;  
          route_name = this.account.sort_page.analytics.callcenter[0].route_name;
        }

        if(!perm_callcenter && perm_base){
          access = true;  
          route_name = this.account.sort_page.analytics.base[0].route_name;
        }

        if(perm_callcenter && perm_base){
          access = true;  
          route_name = this.account.sort_page.analytics.callcenter_base[0].route_name;
        }

      return { access, route_name }
    },
    current_status(){
      let access = false;  
      let route_name = this.account.sort_page.current_status.callcenter_base[0].route_name;

      let perm_callcenter = false;
      let perm_base = false;     
      
      if(this.account.access !== null) 
        for(let key in this.account.access){      
          if(this.account.access[key].hasOwnProperty("admin")){ 
            perm_callcenter = true;
            perm_base = true;
          }
          else{
            if(this.account.access[key].hasOwnProperty("departament_type") && this.account.access[key].departament_type == "callcenter")
              perm_callcenter = true;

            if(this.account.access[key].hasOwnProperty("departament_type") && this.account.access[key].departament_type == "base") 
              perm_base = true;
          }
        }        
        
      if(perm_callcenter && !perm_base){
        access = true;  
        route_name = this.account.sort_page.current_status.callcenter[0].route_name;
      }

      if(!perm_callcenter && perm_base){
        access = true;  
        route_name = this.account.sort_page.current_status.base[0].route_name;
      }

      if(perm_callcenter && perm_base){
        access = true;  
        route_name = this.account.sort_page.current_status.callcenter_base[0].route_name;
      }        
        
      return { access, route_name }
    },
    result_period(){
      let access = false;  
      let route_name = this.account.sort_page.result_period.callcenter_base[0].route_name;

      let perm_callcenter = false;
      let perm_base = false;     
      
      if(this.account.access !== null) 
        for(let key in this.account.access){      
          if(this.account.access[key].hasOwnProperty("admin")){ 
            perm_callcenter = true;
            perm_base = true;
          }
          else{
            if(this.account.access[key].hasOwnProperty("departament_type") && this.account.access[key].departament_type == "callcenter")
              perm_callcenter = true;

            if(this.account.access[key].hasOwnProperty("departament_type") && this.account.access[key].departament_type == "base") 
              perm_base = true;
          }
        }        
        
      if(perm_callcenter && !perm_base){
        access = true;  
        route_name = this.account.sort_page.result_period.callcenter[0].route_name;
      }

      if(!perm_callcenter && perm_base){
        access = true;  
        route_name = this.account.sort_page.result_period.base[0].route_name;
      }

      if(perm_callcenter && perm_base){
        access = true;  
        route_name = this.account.sort_page.result_period.callcenter_base[0].route_name;
      }        
        
      return { access, route_name }
    },
    expenses(){  
      let access = false; 
      let route_name = this.account.sort_page.expenses.length > 0 ? this.account.sort_page.expenses[0].route_name : null;     

      if(this.account.access !== null) 
        for(let key in this.account.access)  
            if(this.account.access[key].hasOwnProperty("admin")) access = true;
            else{ 
              if(this.account.access[key].hasOwnProperty("permissions"))  
                for(let key_spe in this.account.sort_page.expenses){ 
                  for(let key_p in this.account.access[key].permissions)
                      if(this.account.sort_page.expenses[key_spe].perm == this.account.access[key].permissions[key_p] ){
                        access = true; 
                        route_name = this.account.sort_page.expenses[key_spe].route_name; 
                      } 
                    if(access) break;
                } 
            }
           
      return { access, route_name }
    }, 
    marketing(){     
       
      let access = false; 
      let marketing_email_access = false; 
      let marketing_track_analytic_access = false;
      let marketing_publications_networks_access = false;
      let marketing_email_route_name = this.account.sort_page.marketing_email.length > 0 ? this.account.sort_page.marketing_email[0].route_name : null;     
      let marketing_track_analytic_route_name = this.account.sort_page.marketing_track_analytic.length > 0 ? this.account.sort_page.marketing_track_analytic[0].route_name : null;     
      let marketing_publications_networks_route_name = this.account.sort_page.marketing_publications_networks.length > 0 ? this.account.sort_page.marketing_publications_networks[0].route_name : null;  

      if(this.account.access !== null) 
        for(let key in this.account.access)  
            if(this.account.access[key].hasOwnProperty("admin")) {
              access = true;
              marketing_email_access = true;
              marketing_track_analytic_access = true;
              marketing_publications_networks_access = true;
            }
            else{               
              if(this.account.access[key].hasOwnProperty("permissions")){  
                for(let key_spe in this.account.sort_page.marketing_email){ 
                  for(let key_p in this.account.access[key].permissions)
                      if(this.account.sort_page.marketing_email[key_spe].perm == this.account.access[key].permissions[key_p] ){
                        access = true;
                        marketing_email_access = true; 
                        marketing_email_route_name = this.account.sort_page.marketing_email[key_spe].route_name; 
                      } 
                    if(marketing_email_access) break;
                }
                for(let key_spe in this.account.sort_page.marketing_track_analytic){ 
                  for(let key_p in this.account.access[key].permissions)
                      if(this.account.sort_page.marketing_track_analytic[key_spe].perm == this.account.access[key].permissions[key_p] ){
                        access = true; 
                        marketing_track_analytic_access = true; 
                        marketing_track_analytic_route_name = this.account.sort_page.marketing_track_analytic[key_spe].route_name; 
                      } 
                    if(marketing_track_analytic_access) break;
                }

                for(let key_spe in this.account.sort_page.marketing_publications_networks){ 
                  for(let key_p in this.account.access[key].permissions)
                      if(this.account.sort_page.marketing_publications_networks[key_spe].perm == this.account.access[key].permissions[key_p] ){
                        access = true; 
                        marketing_publications_networks_access = true; 
                        marketing_publications_networks_route_name = this.account.sort_page.marketing_publications_networks[key_spe].route_name; 
                      } 
                    if(marketing_publications_networks_access) break;
                }


              }
            }

      return { access, marketing_track_analytic_access, marketing_publications_networks_access, marketing_email_access, marketing_email_route_name, marketing_track_analytic_route_name, marketing_publications_networks_route_name  }
    },

    fot(){ 
      let access = false;    
      let route_name = this.account.sort_page.fot.length > 0 ? this.account.sort_page.fot[0].route_name : null; 

      if(this.account.access !== null) 
        for(let key in this.account.access)  
            if(this.account.access[key].hasOwnProperty("admin")) access = true;
            else{
              if(this.account.access[key].hasOwnProperty("permissions"))  
                for(let key_spe in this.account.sort_page.fot){ 
                  for(let key_p in this.account.access[key].permissions)
                    if(this.account.sort_page.fot[key_spe].perm == this.account.access[key].permissions[key_p]){
                      access = true; 
                      route_name = this.account.sort_page.fot[key_spe].route_name; 
                    }

                  if(access) break; 
                }    
            } 

      return { access, route_name }
    }, 
    ebitda(){
      let access = false; 
      let route_name = 'ebitda-project';   
      if(this.account.access !== null) 
        for(let key in this.account.access)  
            if(this.account.access[key].hasOwnProperty("admin")) access = true;
            else{

              if(this.account.access[key].hasOwnProperty("permissions"))  
							  for(let key_p in this.account.access[key].permissions)
                  if(this.account.access[key].permissions[key_p] == "ebitda_project"){
                      access = true; 
                      route_name = 'ebitda-project';
                      break;
                    }   
            } 
      return { access, route_name }
    },
    sales_pf(){

      let access = false;    
      let route_name = this.account.sort_page.sales_pf.length > 0 ? this.account.sort_page.sales_pf[0].route_name : null;

      if(this.account.access !== null) 
        for(let key in this.account.access)  
            if(this.account.access[key].hasOwnProperty("admin")) access = true;
            else{ 
              if(this.account.access[key].hasOwnProperty("permissions"))  
                for(let key_spe in this.account.sort_page.sales_pf){
                  for(let key_p in this.account.access[key].permissions)
                    if(this.account.sort_page.sales_pf[key_spe].perm == this.account.access[key].permissions[key_p]){
                      access = true; 
                      route_name = this.account.sort_page.sales_pf[key_spe].route_name; 
                    }

                  if(access) break; 
                }   
            }

      return { access, route_name }

    },
    warehouse(){  
      let access = false;    
      let route_name = this.account.sort_page.warehouse.length > 0 ? this.account.sort_page.warehouse[0].route_name : null;

      if(this.account.access !== null) 
        for(let key in this.account.access)  
            if(this.account.access[key].hasOwnProperty("admin")) access = true;
            else{ 
              if(this.account.access[key].hasOwnProperty("permissions"))  
                for(let key_spe in this.account.sort_page.warehouse){
                  for(let key_p in this.account.access[key].permissions)
                    if(this.account.sort_page.warehouse[key_spe].perm == this.account.access[key].permissions[key_p]){
                      access = true; 
                      route_name = this.account.sort_page.warehouse[key_spe].route_name; 
                    }

                  if(access) break; 
                }   
            }

      return { access, route_name }
    },
    settings(){ 
      let access = false; 
      let route_name = 'admin-users';   
			  
      if(this.account.access !== null) 
      for(let key in this.account.access)  
          if(this.account.access[key].hasOwnProperty("admin")) access = true;
          else{
            if(this.account.access[key].hasOwnProperty("permissions"))  
              for(let key_p in this.account.access[key].permissions){
                if(this.account.access[key].permissions[key_p] == "set_plan_department"){
                      access = true; 
                      route_name = 'admin-managers-plan';
                      break;
                    } 
              } 
          }
 
      return { access, route_name }
    },
  },
  methods:{ 
    access_admin(){
      let result = false; 
		  
      if(this.account.access !== null) 
        for(let key in this.account.access)  
            if(this.account.access[key].hasOwnProperty("admin")) 
              result = true;

      return result;
    },   
  }
}
</script> 