import server from '@/api/http';

export async function getFilter(params){  
	let { data } = await server.get('marketing/track_analytic/report/filter/get', { params });
	return data;
}
export async function getTrackAnalytics(params){  
	let { data } = await server.get('marketing/track_analytic/report/get', { params });
	return data;
} 
export async function getCompanies(params){  
	let { data } = await server.get('marketing/track_analytic/report/companies/get', { params });
	return data;
} 
 