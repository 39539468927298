import * as expensesApi from '@/api/expenses_result_period.js';

export default {
	namespaced: true,
	state: {
		filter:null,     
		period_budget:0,
		period_expenses:0,
		period_balance:[0,0,0],
		bar_plan_fact:null,
		bar_expenses_revenue:null,
		pie_expenses:null,
	},
	getters: {
		expenses_result_period:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.period_budget = data.period_budget;  
			state.period_expenses = data.period_expenses;   
			state.period_balance = data.period_balance;  
			state.bar_plan_fact = data.bar_plan_fact;  
			state.bar_expenses_revenue = data.bar_expenses_revenue;  
			state.pie_expenses = data.pie_expenses;  
		},    
	},
	actions: { 
		async loadFilterExpensesResultPeriod ({ commit, dispatch, rootGetters }){
			let data = await expensesApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"expenses_result_period"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadDataExpensesResultPeriod", data.filter);	
			}				 
		},    
		async setFilterExpensesResultPeriod ({ dispatch, rootGetters }, filter){
			let data = await expensesApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"expenses_result_period", filter });	 
			if(data.result) {
				dispatch("loadDataExpensesResultPeriod", data.filter);	
			}				 
		},
		async loadDataExpensesResultPeriod({ commit }, filter){ 
			let data = await expensesApi.getData({ filter });	 
			if(data.result) commit('setData', data);						 
		},  
	}, 
} 