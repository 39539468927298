import server from '@/api/http';

export async function getFilter(params){  
	let { data } = await server.get('current_status_base/get_filter', { params });
	return data;
}
export async function setFilter(params){  
	let { data } = await server.get('current_status_base/set_filter', { params });
	return data;
} 
export async function getData(params){  
	let { data } = await server.get('current_status_base/get_data', { params });
	return data;
}