<template>
  <v-snackbar v-model="snackBar.status" :timeout="snackBar.timeout" class="text-center" location="top">
    <div class="text-center">{{ snackBar.text }}</div>
    <!-- <template v-slot:actions>
      <v-btn color="pink" variant="text" @click="closeSnackBar()">
        Закрыть
      </v-btn>
    </template> -->
  </v-snackbar>
</template>
<script> 
import { mapGetters, mapActions } from 'vuex'
 
export default {   
  computed: {
    ...mapGetters("snackBar", { snackBar:"snackBar" }), 
  },
  methods: { 
    ...mapActions("snackBar", ["closeSnackBar"]),
  } 
}
</script>