import * as resultPeriodCallcenterApi from '@/api/result_period_callcenter.js';
 
export default {
	namespaced: true,
	state: {
		filter:null,
		data:null,	 
		view_departament:"all"
	},
	getters: {
		result_period_callcenter:state=>{
			return state
		},  
	},
	mutations: {
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.data = data 
		}, 
		setVD(state, val){ 
			state.view_departament = val
		},
	},
	actions: {    
		async setFilterRPCallcenter({ dispatch, rootGetters }, filter){
			let data = await resultPeriodCallcenterApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"result_period_callcenter", filter });	 
			if(data.result) {
				dispatch("loadDataRPCallcenter", data.filter);	
			}				 
		},
		async loadFilterRPCallcenter ({ commit, dispatch, rootGetters }){
			let data = await resultPeriodCallcenterApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"result_period_callcenter"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadDataRPCallcenter", data.filter);	
			}				 
		},
		setVDRPCallcenter({ commit, dispatch, state }, val){
			commit('setVD', val);
			dispatch("loadDataRPCallcenter", state.filter);
		},
		async loadDataRPCallcenter({ commit, rootGetters, state }, filter){
 
			let view_managers = [
				{
					user_id:rootGetters['account/account'].user_id,
					b_user_id:rootGetters['account/account'].bitrix.user_id,
					status:true,
				} 
			];
			let access = rootGetters['account/account'].access;

			if(access !== null)  	 
				for(let key in access){  
					if(access[key].hasOwnProperty("admin")){
						view_managers = [];
							
						if(state.view_departament == "all"){  
								
							for(let key_d in access[key].users_departament.callcenter)    
								if(access[key].users_departament.callcenter[key_d].length !== 0)
									for(let key_du in access[key].users_departament.callcenter[key_d]) 
										view_managers.push(access[key].users_departament.callcenter[key_d][key_du])
						}
						else{
							for(let key_d in access[key].users_departament.callcenter) 	 
								if(key_d == state.view_departament && access[key].users_departament.callcenter[key_d].length !== 0)
									view_managers = access[key].users_departament.callcenter[key_d];							 
						}
					}
					else{ 
						if(
							access[key].hasOwnProperty("departament_type") && 
							access[key].departament_type == "callcenter" && 
							access[key].hasOwnProperty("permissions")  
						)  
							for(let key_p in access[key].permissions) 
								if(
									access[key].permissions[key_p] == "see_department" ||
									access[key].permissions[key_p] == "set_plan_department" 
								)
								view_managers = access[key].users_departament 
					}
				}

				let data = await resultPeriodCallcenterApi.getData({ filter, view_managers });	 
				if(data.result) commit('setData', data);						 
		},
	}, 
}