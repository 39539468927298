import * as accountApi from '@/api/account.js';

export default {
	namespaced: true,
	state: {
		token:null,
		user_id:null,
		bitrix:null, 	
		access:null,
		sort_page:{} 	 
	},
	getters: {
		isToken: state =>{
			return state.token ? true : false;
		},
		account:state=>{
			return state
		},  
	},
	mutations: {
		setToken(state, token){ 
			state.token = token; 
		}, 
		setAccount(state, data){ 
			state.user_id = data.user_id
			state.bitrix = data.bitrix 
			state.access = data.access
			state.sort_page = data.sort_page		 
		}, 
	},
	actions: {    
		async loadToken({ commit }){
			let token = localStorage.getItem('token') ? localStorage.getItem('token') : false; 
			if(token){ 
				let data = await accountApi.validToken(token);	 
				if(!data.result) token = false; 
			} 
			commit('setToken', token);						 
			return token;
		},
		async load({ commit }){			 
			let data = null;
			try { 
				data = await accountApi.get(); 	  
				commit('setAccount', {
					user_id:data.user_id,
					access:data.access, 
					bitrix:data.bitrix,  
					sort_page:data.sort_page,  
				});	
			} catch (error) {
				localStorage.removeItem('token')
				commit('setToken', false);	
			}  			 
			return data;
		},  
		async upSortPage({ state }){		

			let formData = new FormData(); 
				formData.append('user_id', state.user_id);  
				formData.append('sort_page', JSON.stringify(state.sort_page));
				  
			await accountApi.upSortPage(formData); 	  
		}
	}, 
}