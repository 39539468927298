import * as fotApi from '@/api/fot_statement.js';

export default {
	namespaced: true,
	state: {
		filter:null, 
		users_all:[],
		deals:[],
		table_1:[],
		table_2:[],
		table_3:[],
		table_4:[],
		table_5:[],
		table_6:[],
		table_7:[],
		table_8:[],
		table_10:[],
		table_9:[],
	},
	getters: {
		fot_statement:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){  
			state.users_all = data.users_all;  
			state.deals = data.deals;   
			state.table_1 = data.table_1; 
			state.table_2 = data.table_2; 
			state.table_3 = data.table_3; 
			state.table_4 = data.table_4; 
			state.table_5 = data.table_5; 
			state.table_6 = data.table_6; 
			state.table_7 = data.table_7; 
			state.table_8 = data.table_8;
			state.table_10 = data.table_10; 
			state.table_9 = data.table_9; 
		}, 
	},
	actions: { 
		async loadFilterFotStatement ({ commit, dispatch, rootGetters }){
			let data = await fotApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"fot_statement"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadDataFotStatement", data.filter);	
			}				 
		},    
		async setFilterFotStatement ({ dispatch, rootGetters }, filter){
			let data = await fotApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"fot_statement", filter });	 
			if(data.result) {
				dispatch("loadDataFotStatement", data.filter);	
			}				 
		},
		async loadDataFotStatement({ commit }, filter){ 
			let data = await fotApi.getData({ filter });	 
			if(data.result) commit('setData', data);						 
		},  
		async addManagerTable5({ dispatch, state }){ 

			let formData = new FormData(); 
			formData.append('filter', JSON.stringify(state.filter));
			formData.append('action', "add");  
  
			let data = await fotApi.saveManagerTable5(formData);	 
			if(data.result) {
				dispatch("loadDataFotStatement", state.filter);	
			}		
		},
		async saveManagerTable5({ dispatch, state }, key){ 

			let formData = new FormData(); 
				formData.append('filter', JSON.stringify(state.filter));
				formData.append('action', "save");  
				formData.append('key', key);  
				formData.append('data', JSON.stringify(state.table_5.managers[key]));  

			let data = await fotApi.saveManagerTable5(formData);	 
			if(data.result) {
				dispatch("loadDataFotStatement", state.filter);	
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true });
			}		
		},
		async delManagerTable5({ dispatch, state }, key){   
			let data = await fotApi.delManagerTable5({ filter:state.filter, key });	 
			if(data.result) {
				dispatch("loadDataFotStatement", state.filter);	
			}		
		},
		async addDealTable5({ dispatch, state }, key){
			let data = await fotApi.saveDealTable5({ filter:state.filter, key });	 
			if(data.result) {
				dispatch("loadDataFotStatement", state.filter);	
			}		
		}, 
		async delDealTable5({ dispatch, state }, data){
			let response = await fotApi.delDealTable5({ filter:state.filter, key:data.key, k:data.k });	 
			if(response.result) {
				dispatch("loadDataFotStatement", state.filter);	
			}		
		},
		async addManagerTable7({ dispatch, state }){
			let data = await fotApi.saveManagerTable7({ filter:state.filter });	 
			if(data.result) {
				dispatch("loadDataFotStatement", state.filter);	
			}		
		}, 
		async saveManagerTable7({ dispatch, state }, key){
			let data = await fotApi.saveManagerTable7({ filter:state.filter, key, data:state.table_7.managers[key] });	 
			if(data.result) {
				dispatch("loadDataFotStatement", state.filter);	
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true });
			}		
		}, 
		async delManagerTable7({ dispatch, state }, key){
			let data = await fotApi.delManagerTable7({ filter:state.filter, key });	 
			if(data.result) {
				dispatch("loadDataFotStatement", state.filter);	
			}		
		},

		async addManagerTable10({ dispatch, state }){
			let data = await fotApi.saveManagerTable10({ filter:state.filter });	 
			if(data.result) {
				dispatch("loadDataFotStatement", state.filter);	
			}		
		}, 
		async saveManagerTable10({ dispatch, state }, key){
			let data = await fotApi.saveManagerTable10({ filter:state.filter, key, data:state.table_10.managers[key] });	 
			if(data.result) {
				dispatch("loadDataFotStatement", state.filter);	
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true });
			}		
		}, 
		async delManagerTable10({ dispatch, state }, key){
			let data = await fotApi.delManagerTable10({ filter:state.filter, key });	 
			if(data.result) {
				dispatch("loadDataFotStatement", state.filter);	
			}		
		},

		async sendExpenses({ state, dispatch }){
			
			let summ = state.table_9.total;

			let data = await fotApi.sendExpenses({ year:state.filter.year, month:state.filter.month, summ });	 
			if(data.result) {
				dispatch('snackBar/showSnackBar', "Данные ведомости отправлены в расходы!", { root: true });
			}		
		},
	}, 
} 