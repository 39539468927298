import server from '@/api/http';
 
export async function getFilter(params){  
	let { data } = await server.get('expenses/accounting/get_filter', { params });
	return data;
} 

export async function getData(params){  
	let { data } = await server.get('expenses/accounting/get_data', { params });
	return data;
} 

export async function setFilter(params){  
	let { data } = await server.get('expenses/accounting/set_filter', { params });
	return data;
}  

export async function setValue(params){  
	let { data } = await server.get('expenses/accounting/set_value', { params });
	return data;
}  

export async function delValue(params){  
	let { data } = await server.get('expenses/accounting/del_value', { params });
	return data;
} 
export async function saveTable(params){  
	let { data } = await server.get('expenses/accounting/save_table', { params });
	return data;
}   
 
export async function getBoxOffice(params){  
	let { data } = await server.get('expenses/accounting/box_office/get', { params });
	return data;
}  

export async function boxOfficeSetValue(params){  
	let { data } = await server.get('expenses/accounting/box_office/set_value', { params });
	return data;
}  

export async function boxOfficeDelValue(params){  
	let { data } = await server.get('expenses/accounting/box_office/del_value', { params });
	return data;
}  
 