import * as fotApi from '@/api/fot_result.js';

export default {
	namespaced: true,
	state: {
		filter:null,
		users:[],
		table:[],
		current_month_satus:false,
		superannuation_allowance:{
			show:false,
			value:0
		},
		new_manager:{
			show:false,
			manager:null
		},
		report:{
			show:false,
			filter:{
				period:"year",
				year:new Date().getFullYear(),
				month:null
			},
			key:null,
			data:null
		},
		companies:[]      
	},
	getters: {
		fot_result:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){  
			state.users = data.users; 
			state.table = data.table; 
			state.current_month_satus = data.current_month_satus;  
			state.superannuation_allowance.value = data.superannuation_allowance;  
			state.companies  = data.companies;  
		},
		setSuperannuationAllowance(state, status){ 
			state.superannuation_allowance.show = status; 
		},
		setNewManager(state, data){ 
			state.new_manager.show = data.status; 
			state.new_manager.manager = data.manager; 
		},  
		setReport(state, data){ 
			state.report.show = data.status; 
			state.report.key = data.key;
			state.report.data = data.data; 
		}     
	},
	actions: { 
		async loadFilterFotResult ({ commit, dispatch, rootGetters }){
			let data = await fotApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"fot_result"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadDataFotResult", data.filter);	
			}				 
		},    
		async setFilterFotResult ({ dispatch, rootGetters }, filter){
			let data = await fotApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"fot_result", filter });	 
			if(data.result) {
				dispatch("loadDataFotResult", data.filter);	
			}				 
		},
		async loadDataFotResult({ commit }, filter){ 
			let data = await fotApi.getData({ filter });	 
			if(data.result) commit('setData', data);						 
		},  
		async showReport({ commit, state }, data){

			if(data.key == null) commit('setReport', data);
			else{
				let b_user_id = state.table[data.key].b_user_id
				
				let response = await fotApi.getReport({ filter:state.report.filter, b_user_id });	 
				if(response.result) {
					commit('setReport', { status:data.status, key:data.key, data:response.data });
				}
			} 
		},
		showNewManager({ commit }, data){
			commit('setNewManager', data);		
		},
		showSuperannuationAllowance({ commit }, status){
			commit('setSuperannuationAllowance', status);		
		},
		async upSuperannuationAllowance({ commit, dispatch, state }){
			let filter = state.filter;
			let data = await fotApi.upSuperannuationAllowance({ value:state.superannuation_allowance.value, filter });	 
			if(data.result) {
				dispatch("loadDataFotResult", state.filter);	
				commit('setSuperannuationAllowance', false);		
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true }); 
			}				 
		},
		async addManager({ dispatch, state, commit }){
			let filter = state.filter;
			let manager = state.new_manager.manager;

			if(manager !== null){
				let data = await fotApi.saveManagers({ filter, b_user_id:manager, action:'add' });	 
				if(data.result) { 
					dispatch("loadDataFotResult", filter);
					commit('setNewManager', { status:false, manager:null });
				}	
			}
		},
		async saveManager({ state, dispatch }, key){ 
			let filter = state.filter;
			let data = await fotApi.saveManagers({ 
				filter,
				b_user_id:state.table[key].b_user_id, 
				company:state.table[key].company,
				salary:state.table[key].salary,
				action:'save'
			});	 
			if(data.result) { 
				dispatch("loadDataFotResult", filter);
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true });   
			}	 
		},
		async delManager({ state, dispatch }, key){
			let filter = state.filter;
			let data = await fotApi.delManagers({ b_user_id:state.table[key].b_user_id, filter });	 
			if(data.result) { 
				dispatch("loadDataFotResult", filter);
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true }); 
			}		
		},
		async downloadReport({ state, dispatch }){
			
			let filter = state.report.filter;
			let key =  state.report.key;

			let data = await fotApi.downloadReport({ b_user_id:state.table[key].b_user_id, filter });	 
			if(data.result) { 
				 
			}		
		}
	}, 
} 