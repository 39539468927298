import * as warehouseProductCardApi from '@/api/warehouse_product_card.js';
import * as warehouseWriteOffsApi from '@/api/warehouse_write_offs.js';
import * as warehouseEntryCardApi from '@/api/warehouse_entry_card.js';

export default {
	namespaced: true,
	state: {
		show:false,
		type:null,
		product:null, 
		entry_id:null,  
		product_id:null, 
		position_id:null,
		deals_list:[], 
		positions_quantity:0,
		update_status:false,
		category_write_offs_list:[],
		write_offs:{
			show:false,
			quantity:0,
			deal_id:null,
			category_custom:null,
			date:null,
			category_id:null,
			message:null,
		}, 
		timeout:null, 
	},
	getters: {
		warehouse_product_card:state=>{
			return state
		},  
	},
	mutations: {  
		setShow(state, data){   
			state.product = data.product; 
			state.show = data.status; 
			state.entry_id = data.entry_id;
			state.position_id = data.position_id;
			state.type = data.type;
			state.product_id = data.product_id;   
			state.deals_list = data.deals_list;  
			state.positions_quantity = data.positions_quantity;   
			state.category_write_offs_list = data.category_write_offs_list;    
		},   
		showWriteOffs(state, data){  
			state.write_offs.show = data.status; 			
			if(!data.status){ 
				state.write_offs.quantity = null;
				state.write_offs.deal_id = null;
				state.write_offs.date = null; 
				state.write_offs.category_id = null;   
				state.write_offs.category_custom = null;   
				state.write_offs.message = null;   
			}
			else
				state.write_offs.date = new Date().toLocaleDateString("en-Ca")
		},
		setWriteOffsMessage(state, message){   
			state.write_offs.message = message;
		}, 
		quantityWriteOffs(state, quantity){   
			state.write_offs.quantity = quantity;
		},
		setUpdateStatus(state, status){
			state.update_status = status
		},    
	},
	actions: {  
		async showWarehouseProductCard ({ commit }, data){ 
			
			if(data.status){
				let response = await warehouseProductCardApi.getEntry({ entry_id:data.entry_id, product_id:data.product_id, position_id:data.position_id, type:data.type });	 
				if(response.result) {
					data.product = response.product;
					data.positions_quantity = response.positions_quantity;
					data.deals_list = response.deals_list;		
					data.category_write_offs_list = response.category_write_offs_list;	 		 
					commit('setShow', data); 
				}	
			}  
			else {
				data.product = null;
				data.deals_list = [];
				data.category_write_offs_list = [];
				data.positions_quantity = 0;
				commit('setShow', data);
				commit('setWriteOffsMessage', null);  
			}		
		},  
		showWriteOffs ({ commit }, data){
			commit('showWriteOffs', data);   
		},
		changeQuantityWriteOffs({ state, commit }){
			if(state.write_offs.quantity < 0) 
				commit('quantityWriteOffs', 0);
			  
			if(state.write_offs.quantity > state.product.products[0].positions.items[0].remains) 
				commit('quantityWriteOffs', state.product.products[0].positions.items[0].remains);
			 
		},
		async changeDataPosition({ state, commit }, data){ 
			let params_val = null;
  
			if(data.params_id == "place_zone") 
				params_val = state.product.products[data.key_product].positions.items[data.key_position].place.zone; 
			if(data.params_id == "place_shelf") 
				params_val = state.product.products[data.key_product].positions.items[data.key_position].place.shelf; 
			  
			await warehouseProductCardApi.setPosition({  
				entry_id:state.entry_id,
				position_id:state.product.products[data.key_product].positions.items[data.key_position].position_id, 
				params_id:data.params_id, 
				params_val,
				action:"save" 
			}).then((response)=>{

				if(response.result)
					commit('setUpdateStatus', true);
			})	   
		},
		changeDataProduct({ state, commit }, params_id){ 

			clearTimeout(state.timeout)
			
			let params_val = null;  
 
			if(params_id == "comment_product_card") params_val = state.product.comment;
			
			console.log(params_val, state.entry_id);
			
			state.timeout = setTimeout(async () => {
				await warehouseEntryCardApi.setEntry({ action:"save", params_id, params_val, id:state.entry_id }).then((response)=>{

					if(response.result)
						commit('setUpdateStatus', true);
				});	 	
			}, 1200)  
		},


		async saveChange({ state, commit }){
			let response = await warehouseProductCardApi.saveChange({ entry_id:state.entry_id });	 
			if(response.result) { 
				commit('setUpdateStatus', false);
			}
		}, 
		async cancelChange({ state, commit, dispatch }){
			let response = await warehouseProductCardApi.cancelChange({ entry_id:state.entry_id });	 
			if(response.result) { 
				commit('setUpdateStatus', false);
				dispatch("showWarehouseProductCard", { status:true, entry_id:state.entry_id, product_id:state.product_id, position_id:state.position_id, type:'one' });
			}
		},


		async createWriteOffs({ state, commit, dispatch, rootGetters }){ 

			let message = null;
			let valid = true;

			if(state.write_offs.category_id == null) {
				valid = false;
				message = "Выберите Категорию списания";
			}

			if(valid && state.write_offs.category_id == 3 && state.write_offs.deal_id == null) {
				valid = false;
				message = "Выберите Сделку/проект";
			}

			if(valid && state.write_offs.category_id == 4 && state.write_offs.category_custom == null) {
				valid = false;
				message = "Введите комментарий к списанию";
			}

			if(valid && state.write_offs.quantity <= 0) {
				valid = false;
				message = "Введите Кол-во";
			}		 

			if(valid && (state.write_offs.date == null || state.write_offs.date == "")) {
				valid = false;
				message = "Выберите дату списания";
			}
 
			commit('setWriteOffsMessage', message);  

			if(valid){  
				let res = await warehouseWriteOffsApi.createWriteOffs({
						category_write_offs_id:state.write_offs.category_id, 
						category_write_offs_custom:state.write_offs.category_custom, 
						deal_id:state.write_offs.deal_id,  
						date:state.write_offs.date,  
						quantity:state.write_offs.quantity,
						position_id:state.position_id,
					});	 
				if(res.result) {  
					commit('showWriteOffs', { status:false });  
					dispatch("showWarehouseProductCard", { 
						status:true, 
						entry_id:state.entry_id, 
						product_id:state.product_id, 
						position_id:state.position_id, 
						type:"one" 
					});  
					dispatch("warehouse_remains_card/loadDataWarehouseRemainsCard", 
						{ filter:rootGetters['warehouse_remains_card/warehouse_remains_card'].filter }, 
						{ root: true }
					);  
				} 

			}
		}, 
	}, 
} 