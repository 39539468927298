<template> 
  <v-dialog v-model="dialog.dialog_details.show" max-width="600" class="dialog-details">
    <v-card v-if="dialog.dialog_details.data !== null"> 
      
      <v-toolbar color="blue-analytics-1">
        <v-toolbar-title>Детализация</v-toolbar-title>
        <v-btn icon @click="setDialogDetails({show:false, data:null})">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-card-text>

        <v-card-subtitle>Параметр «<span v-html="dialog.dialog_details.data.title"></span>»</v-card-subtitle>
        
        <div v-if="dialog.dialog_details.data.details == 'main'">  

          <template v-for="(items, key) in dialog.dialog_details.data.data.managers" :key="key"> 
            <v-list lines="one" v-if="items.id !== 'dismissed'"> 
                <v-list-item 
                  :key="items.name"
                  :title="items.name"
                  class="details-table"   
                >
                  <template v-slot:prepend> 
                    <v-avatar v-if="items.photo !== null" :image="items.photo" class="justify-start"></v-avatar>
                    <v-avatar v-else icon="mdi-account" class="justify-start"></v-avatar> 
                  </template> 
                  <template v-slot:append> 
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'number'">{{ items.value.toLocaleString('ru-RU') }}{{ items.unit }}</span>
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'float'">{{ items.value.toLocaleString('ru-RU') }}{{ items.unit }}</span>
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'time_dhis'">{{ formattedTime(items.value, "dhis") }} {{ items.unit }}</span> 
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'time_is'">{{ formattedTime(items.value, "is") }} {{ items.unit }}</span>
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'time_his'">{{ formattedTime(items.value, "his") }} {{ items.unit }}</span>
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'time_i'">{{ formattedTime(items.value, "i") }} {{ items.unit }}</span>
                      
                    <span style="font-size: 14px;margin-top: 4px; width: 70px;" v-if="dialog.dialog_details.data.dynamics" :class="items.dynamics.mark == null ? '' : items.dynamics.mark ? 'plus' : 'minus' ">{{ items.dynamics.value }}{{ items.dynamics.unit }}</span>
                  </template> 
                </v-list-item>
            </v-list> 
          </template> 
          <v-divider color="blue-analytics-1"></v-divider>
          <template v-for="(items, key) in dialog.dialog_details.data.data.managers" :key="key"> 
            <v-list lines="one" v-if="items.id == 'dismissed'"> 
                <v-list-item 
                  :key="items.name"
                  :title="items.name"
                  class="details-table"   
                >
                  <template v-slot:prepend> 
                    <v-avatar v-if="items.photo !== null" :image="items.photo" class="justify-start"></v-avatar>
                    <v-avatar v-else icon="mdi-account" class="justify-start"></v-avatar> 
                  </template> 
                  <template v-slot:append> 
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'number'">{{ items.value.toLocaleString('ru-RU') }}{{ items.unit }}</span>
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'float'">{{ items.value.toLocaleString('ru-RU') }}{{ items.unit }}</span>
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'time_dhis'">{{ formattedTime(items.value, "dhis") }} {{ items.unit }}</span> 
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'time_is'">{{ formattedTime(items.value, "is") }} {{ items.unit }}</span>
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'time_his'">{{ formattedTime(items.value, "his") }} {{ items.unit }}</span>
                    <span style="font-weight: 600; font-size: 17px; width: 100px;" v-if="dialog.dialog_details.data.items[0].unit_type == 'time_i'">{{ formattedTime(items.value, "i") }} {{ items.unit }}</span>
                    <span style="font-size: 14px;margin-top: 4px; width: 70px;" v-if="dialog.dialog_details.data.dynamics" :class="items.dynamics.mark == null ? '' : items.dynamics.mark ? 'plus' : 'minus' ">{{ items.dynamics.value }}{{ items.dynamics.unit }}</span>
                  </template> 
                </v-list-item>
            </v-list> 
          </template>
        </div>  

        <div v-if="dialog.dialog_details.data.details == 'funnel'">
          <template v-for="(items_f, key_f) in dialog.dialog_details.data.data" :key="key_f">

            <v-list lines="one"> 
              <v-list-item 
                :key="items_f.name"
                :title="items_f.name" 
                style="background: #daecff;border-radius: 12px;padding-top: 11px;padding-bottom: 11px;"
              >
                <template v-slot:prepend>
                  <v-avatar color="blue-analytics-1">
                    <v-icon color="white">mdi-filter-outline</v-icon>
                  </v-avatar>
                </template> 
                <template v-slot:append>
                  <span style="font-weight: 600; font-size: 17px;"> 
                    {{ items_f.value.toLocaleString('ru-RU') }}
                  </span>
                </template> 
              </v-list-item>
            </v-list> 


            <template v-for="(items, key) in items_f.managers" :key="key">
              <v-list lines="one" v-if="items.id !== 'dismissed'"> 
                <v-list-item 
                  :key="items.name"
                  :title="items.name" 
                  class="details-table"
                >
                  <template v-slot:prepend> 
                    <v-avatar v-if="items.photo !== null" :image="items.photo" class="justify-start"></v-avatar>
                    <v-avatar v-else icon="mdi-account" class="justify-start"></v-avatar> 
                  </template> 
                  <template v-slot:append>
                    <span style="font-weight: 600; font-size: 17px;"> 
                      {{ items.value.toLocaleString('ru-RU') }}
                    </span> 
                  </template> 
                </v-list-item>
              </v-list> 
            </template> 
            <v-divider color="blue-analytics-1"></v-divider>
            <template v-for="(items, key) in items_f.managers" :key="key">
              <v-list lines="one" v-if="items.id == 'dismissed'"> 
                <v-list-item 
                  :key="items.name"
                  :title="items.name" 
                  class="details-table"
                >
                  <template v-slot:prepend> 
                    <v-avatar v-if="items.photo !== null" :image="items.photo" class="justify-start"></v-avatar>
                    <v-avatar v-else icon="mdi-account" class="justify-start"></v-avatar> 
                  </template> 
                  <template v-slot:append>
                    <span style="font-weight: 600; font-size: 17px;"> 
                      {{ items.value.toLocaleString('ru-RU') }}
                    </span> 
                  </template> 
                </v-list-item>
              </v-list> 
            </template> 
  
          </template>
        </div>

        <div v-if="dialog.dialog_details.data.details == 'group_params'">

          <v-list lines="one" style="padding: 0;"> 
              <v-list-item>
                <template v-slot:append>
                  <span style="font-weight: 400; font-size: 13px; width: 110px;color: #5e5e5e;">Контракты</span>
                  <span style="font-weight: 400; font-size: 13px; width: 110px;color: #5e5e5e;">Выручка</span>
                </template> 
              </v-list-item>
            </v-list> 
 
          <template v-for="(items, key) in dialog.dialog_details.data.data.managers" :key="key">
            <v-list lines="one" v-if="items.id !== 'dismissed'"> 
                <v-list-item 
                  :key="items.name"
                  :title="items.name"
                  class="details-table" 
                >
                  <template v-slot:prepend> 
                    <v-avatar v-if="items.photo !== null" :image="items.photo" class="justify-start"></v-avatar>
                    <v-avatar v-else icon="mdi-account" class="justify-start"></v-avatar> 
                  </template> 
                  <template v-slot:append>
                    
                    <span style="font-weight: 600; font-size: 17px; width: 110px;" v-for="(items_p, key_p) in items.params" :key="key_p"> 
                      {{ items_p.value.toLocaleString('ru-RU') }}
                      {{ items_p.unit }}
                    </span>
                    
                  </template> 
                </v-list-item>
              </v-list>
          </template>
          <v-divider color="blue-analytics-1"></v-divider>
          <template v-for="(items, key) in dialog.dialog_details.data.data.managers" :key="key">
            <v-list lines="one" v-if="items.id == 'dismissed'"> 
                <v-list-item 
                  :key="items.name"
                  :title="items.name"
                  class="details-table" 
                >
                  <template v-slot:prepend> 
                    <v-avatar v-if="items.photo !== null" :image="items.photo" class="justify-start"></v-avatar>
                    <v-avatar v-else icon="mdi-account" class="justify-start"></v-avatar> 
                  </template> 
                  <template v-slot:append>
                    
                    <span style="font-weight: 600; font-size: 17px; width: 110px;" v-for="(items_p, key_p) in items.params" :key="key_p"> 
                      {{ items_p.value.toLocaleString('ru-RU') }}
                      {{ items_p.unit }}
                    </span>
                    
                  </template> 
                </v-list-item>
              </v-list>
          </template> 
 
        </div>   
      </v-card-text>
 
    </v-card>
  </v-dialog>  
</template> 
<script>  
import { mapGetters, mapActions } from 'vuex'
export default { 
  computed:{  
    ...mapGetters("dialog", {dialog:"dialog"}), 
  },
  methods:{   
    ...mapActions("dialog", ["setDialogDetails"]),
    formattedTime(value, format){
       
       if(format == 'dhis'){ 
        let day = Math.floor(value / 86400);
        value %= 86400;
        let hours = Math.floor(value / 3600);
        value %= 3600;
        let minutes = Math.floor(value / 60);
        let seconds = value % 60;

        if(String(day).length == 1) day = "0" + day;
        if(String(hours).length == 1) hours = "0" + hours;
        if(String(minutes).length == 1) minutes = "0" + minutes;
        if(String(seconds).length == 1) seconds = "0" + seconds;
 
         return day+":"+hours+":"+minutes+":"+seconds;
       }
       if(format == 'his') {
        let hours = Math.floor(value / 3600);
        value %= 3600;
        let minutes = Math.floor(value / 60);
        let seconds = value % 60;
        if(String(hours).length == 1) hours = "0" + hours;
        if(String(minutes).length == 1) minutes = "0" + minutes;
        if(String(seconds).length == 1) seconds = "0" + seconds;
        return hours+":"+minutes+":"+seconds;
      }
       if(format == 'is') {
        let minutes = Math.floor(value / 60);
        let seconds = value % 60;
        if(String(minutes).length == 1) minutes = "0" + minutes;
        if(String(seconds).length == 1) seconds = "0" + seconds;
        return minutes+":"+seconds;
      }
       if(format == 'i') {
         let minutes = Math.floor(value / 60);
         if(String(minutes).length == 1) minutes = "0" + minutes;
         return minutes;
       }
     }  
  }, 
}
</script>