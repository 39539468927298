import server from '@/api/http';

export async function getFilter(params){  
	let { data } = await server.get('marketing/email/analytics/get_filter', { params });
	return data;
}
export async function setFilter(params){  
	let { data } = await server.get('marketing/email/analytics/set_filter', { params });
	return data;
}
export async function getData(params){  
	let { data } = await server.get('marketing/email/analytics/get_data', { params });
	return data;
}
export async function getUserResponse(params){  
	let { data } = await server.get('marketing/email/analytics/get_user_response', { params });
	return data;
}
export async function deleteResponse(params){  
	let { data } = await server.get('marketing/email/analytics/delete_response', { params });
	return data;
}
 