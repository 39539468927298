<template>
  <v-overlay :model-value="preloader.show" persistent class="align-center justify-center">
    <v-progress-circular color="blue-lighten-3" indeterminate :size="56"></v-progress-circular> 
  </v-overlay>
</template>
<script> 
import { mapGetters } from 'vuex'
 
export default {  
  computed: {
    ...mapGetters("preloader", { preloader:"preloader" }), 
  }, 
}
</script>