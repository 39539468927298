import * as warehouseLists from '@/api/warehouse_lists.js';
  
export default {
	namespaced: true,
	state: { 
		category_product_list:[],
		names_product_list:[],
		names_category_product_list:[],
		colour_product_list:[],
		supplier_product_list:[],
		payer_product_list:[],
		units_list:[],
		articles_pzn_list:[],
		articles_pzn_not_cat_list:[],
		articles_supplier_list:[],
		deals_list:[],
		category_write_offs_list:[],
		customers_list:[],		 
		shipment_from_list:[],
		users_list:[],
		status_order_list:[],
    	status_workshop_list:[],
		zones_product_list:[],
	},
	getters: {
		warehouse_lists:state=>{
			return state
		},  
	},
	mutations: {  
		setData(state, data){   
			state.category_product_list = data.category_product_list;
			state.names_product_list = data.names_product_list;
			state.names_category_product_list = data.names_category_product_list; 
			state.colour_product_list = data.colour_product_list;
			state.supplier_product_list = data.supplier_product_list;
			state.payer_product_list = data.payer_product_list;
			state.units_list = data.units_list;
			state.articles_pzn_list = data.articles_pzn_list;
			state.articles_pzn_not_cat_list = data.articles_pzn_not_cat_list;
			state.articles_supplier_list = data.articles_supplier_list;
			state.deals_list = data.deals_list;
			state.category_write_offs_list = data.category_write_offs_list;
			state.customers_list = data.customers_list;		 
			state.shipment_from_list = data.shipment_from_list;
			state.users_list = data.users_list;  
			state.status_order_list = data.status_order_list;  
			state.status_workshop_list = data.status_workshop_list;  
			state.zones_product_list = data.zones_product_list;   
		}, 
	},
	actions: { 
		async loadWarehouseLists ({ commit }){
			let data = await warehouseLists.getLists();	 
			if(data.result) 
				commit('setData', data);	  			 
		},    
	}, 
} 