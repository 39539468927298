export default {
	namespaced: true,
	state: {
		show:false, 
	},
	getters: {
		preloader:state=>{
			return state
		}, 
	},
	mutations: { 
		setPreloader(state, status){ 
			console.log(status);
			state.show = status   
		},    
	},
	actions: {
		setPreloader({ commit }, status){ 
			commit('setPreloader', status); 
		},  
	}
}