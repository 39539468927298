export default function rights_verification ({ next, store, to, from, nextMiddleware }){
   
  let access = store.getters['account/account'].access;
  let isToken = store.getters['account/isToken']; 
    
  let redirect_name = null;   
  
  if(to.name == "page-index"){ 
    if(isToken) 
      redirect_name = get_page_start(access); 
  } 
  else if(to.name == "access-closed"){ 
    
    redirect_name = get_page_start(access); 
    
    if(redirect_name == "access-closed")
      redirect_name = null
  }
  else{ 

    let response = check_permissions(access, to.name);

    if(!response) 
      redirect_name = get_page_start(access);  
  }
  
  if(redirect_name !== null) 
    return next({ name:redirect_name});
  
  return nextMiddleware()
}

function get_page_start(access){
    
  let redirect_name = "access-closed"; 
  let check_next = true;
    
  if(access !== null && access.length > 0){ 
 
    for(let key in access){   
      if(access[key].hasOwnProperty("admin")) {
        redirect_name = "analytics-callcenter"
        check_next = false;
        break;
      } 
      if(access[key].hasOwnProperty("departament_type")) { 
        if(access[key].departament_type == "callcenter"){
          redirect_name = "analytics-callcenter"
          check_next = false;
          break;
        }
        if(access[key].departament_type == "base"){
          redirect_name = "analytics-base"
          check_next = false;
          break;
        } 
      }  
    }
     
    if(check_next)
      for(let key in access){ 
        if(access[key].hasOwnProperty("permissions")){       

          for(let items of access[key].permissions){ 
            if(permissions_route_name[items] !== undefined){ 
              redirect_name = permissions_route_name[items];
              break
            }  
          }

          if(redirect_name !== null)
            break;
        } 
      } 
  }
   
  return redirect_name;
}

function check_permissions (access, name){
  
  let result = false; 
  
  if(
    name == "analytics-callcenter" || 
    name == "analytics-base" || 
    name == "account-optional" ||
    name == "current-status-callcenter" ||
    name == "current-status-base" || 
    name == "result-period-callcenter" ||
    name == "result-period-base"  
  ){  
    if(access !== null && access.length > 0){  
      for(let key in access){   
        if(access[key].hasOwnProperty("admin")) {
          result = true; 
          break;
        } 
        if(access[key].hasOwnProperty("departament_type")) { 
          if(
            access[key].departament_type == "callcenter" && 
            (name == "analytics-callcenter" || name == "account-optional" || name == "current-status-callcenter" || name == "result-period-callcenter")){
            result = true; 
            break;
          }
          if(access[key].departament_type == "base" && 
          (name == "analytics-base" || name == "account-optional" || name == "current-status-base" || name == "result-period-base")){ 
            result = true; 
            break;
          } 
        }  
      }
    }
  }
  else{
    if(access !== null && access.length > 0){
      
      for(let key in access)
        if(access[key].hasOwnProperty("admin")) {
          result = true; 
          break;
        } 

      if(!result)
        for(let key_p in permissions_route_name){  
          if(name == permissions_route_name[key_p]){ 
            for(let key in access){  
              if(access[key].hasOwnProperty("permissions")){
                for(let items of access[key].permissions){
                  if(key_p == items){
                    result = true;
                    break;
                  }
                }
              }
              if(result)
                break;
            } 
          }
        } 
    } 
  }
 
  console.log(name, result);

  return result;
}

const permissions_route_name = {
  "expenses" : "expenses-accounting", 
  "expenses_input_data"  :"expenses-input-data", 
  "expenses_budget" : "expenses-budget", 
  "expenses_result_period" : "expenses-result-period", 

  "marketing_email_surveys" : "marketing-email-surveys", 
  "marketing_email_analytics" : "marketing-email-analytics", 
  "marketing_track_analytic_report" : "marketing-track-analytic-report", 
  "marketing_track_analytic_utm" : "marketing-track-analytic-utm",
  "marketing_track_analytic_settings" : "marketing-track-analytic-settings", 

  "marketing_publications_networks_create" : "marketing-publications-networks-create", 
  "marketing_publications_networks_delay" : "marketing-publications-networks-delay", 
  "marketing_publications_networks_published" : "marketing-publications-networks-published", 
  "marketing_publications_networks_settings" : "marketing-publications-networks-settings", 

  "fot_result" : "fot-result", 
  "fot_statement" : "fot-statement",  
  "ebitda_project" : "ebitda-project", 
  "warehouse_entries" : "warehouse-entry", 
  "warehouse_remains" : "warehouse-remains", 
  "warehouse_orders" : "warehouse-orders", 
  "warehouse_workshop" : "warehouse-workshop",  
  "warehouse_write_offs" : "warehouse-write-offs", 
  "warehouse_reports" : "warehouse-reports", 
  "warehouse_input_data" : "warehouse-input-data", 
  "sales_pf_current" : "sales-pf-current",
  "sales_pf_plan" : "sales-pf-plan",
  "sales_pf_input" : "sales-pf-input"
}