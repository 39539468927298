import * as trackAnalyticUtmApi from '@/api/track_analytic_utm.js';

export default {
	namespaced: true,
	state: {
		utm:[],
		shows:{
			source:[],
			medium:[],
			campaign:[], 
		}   	 
	},
	getters: {
		track_analytic_utm:state=>{
			return state
		},  
	},
	mutations: { 
		setUtm(state, utm){ 

			if(state.utm.length > 0){
				for(let key_source in utm){
					if(state.shows.source.find((element) => element == utm[key_source].id) !== undefined) utm[key_source].show = true
					if(utm[key_source].utm_medium.length > 0){
						for(let key_medium in utm[key_source].utm_medium){
							if(state.shows.medium.find((element) => element == utm[key_source].utm_medium[key_medium].id) !== undefined) utm[key_source].utm_medium[key_medium].show = true
							if(utm[key_source].utm_medium[key_medium].utm_campaign.length > 0){
								for(let key_campaign in utm[key_source].utm_medium[key_medium].utm_campaign)
									if(state.shows.campaign.find((element) => element == utm[key_source].utm_medium[key_medium].utm_campaign[key_campaign].id) !== undefined) utm[key_source].utm_medium[key_medium].utm_campaign[key_campaign].show = true
							}
						}
					}
				}
			}

			state.utm = utm;
		},   	 
		setShows(state, data){ 
			if(data.show)
				state.shows[data.type].push(data.id)
			else { 
				let index = state.shows[data.type].indexOf(data.id);
				if(index !== -1)
					state.shows[data.type].splice(index, 1)  
			}

			console.log(state.shows);
		},  
	},
	actions: { 
		async loadUtm ({ commit }){ 
			await trackAnalyticUtmApi.get().then((response)=>{
				if(response.result) {
					commit('setUtm', response.utm);	 
				}				 
			})	 
		}, 
		async createUtm ({ dispatch }, data){ 
			await trackAnalyticUtmApi.create(data).then((response)=>{
				if(response.result) {
					dispatch('loadUtm');	 
				}				 
			})	 
		},
		async upUtm ({ dispatch }, data){  
			await trackAnalyticUtmApi.up(data).then((response)=>{
				if(response.result) {
					dispatch('loadUtm');	 
					dispatch("snackBar/showSnackBar", "Данные обновлены!", {root:true})  
				}				 
			})	 
		}, 
		async deleteUtm ({ dispatch }, data){ 
			await trackAnalyticUtmApi.deleteUtm(data).then((response)=>{
				if(response.result) {
					dispatch('loadUtm');	 
				}				 
			})	 
		}, 	 
	}, 
} 