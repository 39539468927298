import * as emailSurveysApi from '@/api/email_surveys.js';

export default {
	namespaced: true,
	state: {
		surveys:[],
		status_survey:true,
		survey:{
			show:false,
			id:null,
			name:null,
			rating:[],
			rating_fill:null,
			font_color:null,
			rating_figure:null,
			questions:[],
			link:null,
			code_auto:null,
			code_email:null,
			response_result:null
		},
		html:{
			show:false
		},
		errors:{
			show:false,
			message:null,
		},
		type_response:[
			{ name:"Множественный", value:"check_box"},
			{ name:"Единственный", value:"radio"},
			{ name:"Текстовый", value:"text"},
		],
		url_params:{
			company_id:null,
			email:null,
			deal_id:null,  
		}
	},
	getters: {
		email_surveys:state=>{
			return state
		},  
	},
	mutations: { 
		setSurveys(state, surveys){ 
			state.surveys = surveys; 
		},  
		setShowSurvey(state, data){ 
			state.survey.show = data.show; 
			state.survey.id = data.id;
			state.survey.name = data.name;
			state.survey.rating = data.rating; 
			state.survey.questions = data.questions; 
			state.survey.link = data.link; 
			state.survey.code_auto = data.code_auto;
			state.survey.code_email = data.code_email; 
			state.survey.response_result = data.response_result;
			state.survey.rating_fill = data.rating_fill;
			state.survey.font_color = data.font_color; 
			state.survey.rating_figure = data.rating_figure; 
		},
		addStar(state){
			if(state.survey.rating.length < 10)
				state.survey.rating.push({
					star:true
				})
		},
		delStar(state, key){
			state.survey.rating.splice(key, 1);
		},
		addQuestion(state){
			state.survey.questions.push({
				id:null,
				question:null,
				type_response:null,
				response:[]
			})
		},
		delQuestion(state, key){
			state.survey.questions.splice(key, 1);
		},
		changeTypeResponse(state, key){ 
			let type_response = state.survey.questions[key].type_response;
			if(type_response == "text")
				state.survey.questions[key].response = [];
		},
		addResponse(state, key){			 
			state.survey.questions[key].response.push({id:null, text:null});
		},
		delResponse(state, data){
			state.survey.questions[data.key].response.splice(data.k, 1);
		},
		setError(state, data){
			state.errors.show = data.show
			state.errors.message = data.message
		},
		setLink(state, link){
			state.survey.link = link
		},
		setStatusSurvey(state, status){
			state.status_survey = status
		},
		setUrlParams(state, data){
			state.url_params.company_id = data.company_id,
			state.url_params.email = data.email
			state.url_params.deal_id = data.deal_id 
		},	
		setShowHtlm(state, data){
			state.html.show = data.show
		},	
		setRatingFigure (state, figure){
			state.survey.rating_figure = figure
		} 	 
	},
	actions: { 
		async loadSurveys ({ commit }){ 
			await emailSurveysApi.getSurveys().then((response)=>{
				if(response.result) {
					commit('setSurveys', response.surveys);	 
				}				 
			})	 
		},
		async showSurvey({ commit }, data){
			
			let name = null;
			let rating = [];
			let questions = []; 
			let link = null;
			let code_auto = null;
			let code_email = null;
			let response_result = null;
			let rating_fill = '#e7d81a';
			let font_color = '#fff';
			let rating_figure = 'star';
			 
			if(data.show && data.id !== null){ 
				let response = await emailSurveysApi.getSurvey({id:data.id});
				if(response.result){
					name = response.name;
					rating = response.rating;
					questions = response.questions;
					link = response.link;
					code_auto = response.code_auto;
					code_email = response.code_email;
					response_result = response.response_result;
					rating_fill = response.rating_fill;
					font_color = response.font_color; 
					rating_figure = response.rating_figure;

					commit("setStatusSurvey", true);  
				}
				else
					commit("setStatusSurvey", false);   
			}		 
			  
			commit("setShowSurvey", { show:data.show, id:data.id, name, rating, rating_fill, font_color, rating_figure, questions, link, code_auto, code_email, response_result});
		},
		async saveSurvey({ state, commit, dispatch }){
			
			let show = false;
			let message = null;

			if(state.survey.name == null){
				show = true; 
				message = "Введите название опроса!";
			}
			  
			if(!show && state.survey.questions.length == 0){
				show = true; 
				message = "Добавьте вопрос!";
			}

			if(!show){
				for(let items of state.survey.questions){
					if(items.question == null){
						show = true; 
						message = "Введите текст вопроса!";
						break;
					}
					if(items.type_response == null){
						show = true; 
						message = "Выберите тип ответов!";
						break;
					}
					if(items.type_response !== "text" && items.response.length == 0){
						show = true; 
						message = "Вопрос без ответа. Добавьте ответ!";
						break;
					}
				}
			}

			if(!show) 
				for(let items of state.survey.questions)
					if(items.response.length > 0)
						for(let item of items.response)
							if(item.text == null){
								show = true; 
								message = "Введите текст ответа!"; 
							} 
						  
			if(!show){
				  
				let data = new FormData(); 
				data.append('id', state.survey.id);
				data.append('name', state.survey.name);   
				data.append('rating', JSON.stringify(state.survey.rating));
				data.append('rating_fill', state.survey.rating_fill);
				data.append('font_color', state.survey.font_color); 
				data.append('rating_figure', state.survey.rating_figure); 
				data.append('questions', JSON.stringify(state.survey.questions));

				await emailSurveysApi.upSurvey(data).then((response)=>{
					if(response.result) {
						commit("setLink", response.link);
						dispatch('loadSurveys');	
						dispatch('showSurvey', { id:response.id, show:true});	 
						dispatch("snackBar/showSnackBar", "Данные обновлены!", {root:true})  
					}				 
				})	 
			}

			commit("setError", { show, message });
		},
		async delSurvey({ dispatch }, id){

			await emailSurveysApi.deleteSurveys({id}).then((response)=>{
				if(response.result) {
					dispatch('loadSurveys');	 
				}				 
			})	 
		},
		async changeResponse({ state }, id){
 
			await emailSurveysApi.changeResponse({
				company_id:state.url_params.company_id,
				deal_id:state.url_params.deal_id,
				email:state.url_params.email,
				survey_id:state.survey.response_result[id].survey_id,
				question_id:state.survey.response_result[id].question_id,
				type_response:state.survey.response_result[id].type_response, 
				value:state.survey.response_result[id].value
			}) 
		},
		async changeResponseCustom({ state }, id){
 
			await emailSurveysApi.changeResponse({
				company_id:state.url_params.company_id,
				deal_id:state.url_params.deal_id,
				email:state.url_params.email,
				survey_id:state.survey.response_result[id].survey_id,
				question_id:state.survey.response_result[id].question_id,
				type_response:"check_box_custom", 
				value:state.survey.response_result[id].custom_text
			}) 
		},
		async deleteResponseCustom({ state }, id){ 

			await emailSurveysApi.deleteResponseCustom({
				company_id:state.url_params.company_id,
				deal_id:state.url_params.deal_id,
				email:state.url_params.email,
				survey_id:state.survey.response_result[id].survey_id,
				question_id:state.survey.response_result[id].question_id, 
			}) 
		}, 
		async saveRatingRate({ dispatch }, data){
			await emailSurveysApi.saveRatingRate({
				company_id:data.company_id,
				deal_id:data.deal_id,
				email:data.email,
				survey_id:data.id,
				rating_rate:data.rating_rate,
			})
		},
		setRatingFigure({ commit }, figure){

			commit("setRatingFigure", figure);
		}    
	}, 
} 