import * as expensesApi from '@/api/expenses_input_data.js';

export default {
	namespaced: true,
	state: {
		expenses:[],
		payers:[],
		open_expenses:[],
		open_payer:[],
		show_suppliers:false,
		delete_obj:{
			show:false,
			valid:true,
			name:null,
			type:null,
			key:null,
			k:null
		},
		suppliers:{
			item_expenditure_id:null,
			item_expenditure_name:null,
			items:null
		},   
	},
	getters: {
		expenses_input_data:state=>{
			return state
		},  
	},
	mutations: { 
		setData(state, data){ 
			state.expenses = data.expenses 
			state.payers = data.payers   
		},    
		setSuppliers(state, data){
			state.suppliers.item_expenditure_id = data.id;
			state.suppliers.item_expenditure_name = data.name; 
			state.suppliers.items = data.items;  
		},
		setShowSuppliers(state, status){
			state.show_suppliers = status;
		},
		pushSuppliers(state, data){
			state.suppliers.items.push({
				id:data.id,
				name:data.name
			});
		},
		spliceSuppliers(state, key){
			state.suppliers.items.splice(key, 1);
		},
		setDeleteObj(state, delete_obj){
			state.delete_obj = delete_obj;
		}
	},
	actions: {    
		async showDelete({ state, commit }, data){
			let name = null;
			let k = null;
			let id_obj = null;

			if(data.type == "expenses") {
				name = state.expenses[data.key].name;
				id_obj = state.expenses[data.key].id;
			}
			if(data.type == "item_expenditure") {
				name = state.expenses[data.key].item_expenditure[data.k].name;
				id_obj = state.expenses[data.key].item_expenditure[data.k].id;
				k = data.k;
			}  
			if(data.type == "suppliers") {
				name = state.suppliers.items[data.key].name; 
				id_obj = state.suppliers.items[data.key].id; 
			}
			if(data.type == "payer") {
				name = state.payers[data.key].name; 
				id_obj = state.payers[data.key].id; 
			}
			 
			let response = await expensesApi.validExpenses({ type:data.type, id:id_obj });	 
			if(response.result) { 
				commit('setDeleteObj', {
					show:true,
					name,
					valid:response.valid,
					type:data.type,
					key:data.key,
					k
				}); 
			}
		},
		confirmDelete({ state, commit, dispatch }){			
			if(state.delete_obj.type == "expenses") dispatch("delExpenses", state.delete_obj.key);
			if(state.delete_obj.type == "item_expenditure") dispatch("delItemExpenditure", { key:state.delete_obj.key, k:state.delete_obj.k });
			if(state.delete_obj.type == "suppliers") dispatch("delSuppliers", state.delete_obj.key);
			if(state.delete_obj.type == "payer") dispatch("delPayer", state.delete_obj.key);
			 
			commit('setDeleteObj', {
				show:false,
				valid:true,
				name:null,
				type:null,
				key:null,
				k:null
			}); 
		},
		cancelDelete({ commit }){
			commit('setDeleteObj', {
				show:false,
				valid:true,
				name:null,
				type:null,
				key:null,
				k:null
			}); 
		},
		async addPayer({ dispatch }){ 
			let data = await expensesApi.savePayer();	 
			if(data.result) { 
				dispatch("getData");
			}	
		},
		async savePayer({ state, dispatch }, key){
			let data = await expensesApi.savePayer({ id:state.payers[key].id, name:state.payers[key].name });	 
			if(data.result) { 
				dispatch("getData");
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true });   
			}	
		},
		async delPayer({ state, dispatch }, key){
			let data = await expensesApi.delPayer({ id:state.payers[key].id });	 
			if(data.result) { 
				dispatch("getData");
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true }); 
			}		
		},
		async getData({ commit }){
			let data = await expensesApi.getData();	 
			if(data.result) { 
				commit('setData', { expenses:data.expenses, payers:data.payers });	
			}				 
		},
		async addExpenses({ dispatch }){ 
			let data = await expensesApi.saveExpenses();	 
			if(data.result) { 
				dispatch("getData");
			}	
		},
		async saveExpenses({ state, dispatch }, key){ 
			let data = await expensesApi.saveExpenses({ id:state.expenses[key].id, name:state.expenses[key].name });	 
			if(data.result) { 
				dispatch("getData");
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true });   
			}				     
		},
		async delExpenses({ state, dispatch }, key){ 
			let data = await expensesApi.delExpenses({ id:state.expenses[key].id });	 
			if(data.result) { 
				dispatch("getData");
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true }); 
			}		 
		},
		async addItemExpenditure({ state, dispatch }, key){   
			let data = await expensesApi.saveItemExpenditure({ expenses_id:state.expenses[key].id });	 
			if(data.result) { 
				dispatch("getData");  
			}			
		},    
		async saveItemExpenditure({ state, dispatch }, items){
			let data = await expensesApi.saveItemExpenditure({ 
				item_expenditure_id:state.expenses[items.key].item_expenditure[items.k].id,
				name:state.expenses[items.key].item_expenditure[items.k].name, 
				hide_deal:state.expenses[items.key].item_expenditure[items.k].hide_deal 
			});	 
			if(data.result) { 
				dispatch("getData");  
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true });
			}		
		},
		async delItemExpenditure({ state, dispatch }, items){
			let data = await expensesApi.delItemExpenditure({ 
				id:state.expenses[items.key].item_expenditure[items.k].id,  
			});	 
			if(data.result) { 
				dispatch("getData");  
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true });
			}		
		},
		setShowSuppliers({ commit }, status){ 
			if(!status)
				commit('setSuppliers', {
					id:null,
					name:null, 
					items:[]
				});
			commit('setShowSuppliers', status);
		},
		openSuppliers({ state, commit, dispatch }, data){
  
			commit('setSuppliers', {
				id:state.expenses[data.key].item_expenditure[data.k].id,
				name:state.expenses[data.key].item_expenditure[data.k].name, 
				items:state.expenses[data.key].item_expenditure[data.k].suppliers
			});
			dispatch("setShowSuppliers", true);   
		},
		async addSuppliers({ state, commit }){ 
			let data = await expensesApi.saveSuppliers({ 
				item_expenditure_id:state.suppliers.item_expenditure_id,  
			});	 
			if(data.result) { 
				commit('pushSuppliers', { id:data.id, name:data.name });
			}		
		},
		async saveSuppliers({ state, dispatch }, key){  
			let data = await expensesApi.saveSuppliers({ 
				suppliers_id:state.suppliers.items[key].id,  
				name:state.suppliers.items[key].name,  
			});	 
			if(data.result) { 
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true });
			}	  
		},
		async delSuppliers({ state, commit }, key){ 
			let data = await expensesApi.delSuppliers({ 
				suppliers_id:state.suppliers.items[key].id
			});	 
			if(data.result) { 
				commit('spliceSuppliers', key);
			}	  
		}, 
		async endDrag({ state }, data){
						
			let items = [];

			if(data.type == "payer")  
				for(let item of state.payers)  
					items.push(item.id);

			if(data.type == "item_expenditure")  
				for(let item of state.expenses[data.key].item_expenditure)  
					items.push(item.id);		 
 
			if(data.type == "suppliers"){
				for(let item of state.suppliers.items)  
					items.push(item.id);	
			} 
		  
			await expensesApi.upSort({ 
				type:data.type,  
				items 
			});	    
		}
	}, 
} 