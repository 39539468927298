import * as expensesApi from '@/api/expenses_accounting.js';

export default {
	namespaced: true,
	state: {
		preloader:true,
		filter:null,   
		table:[],
		expenses_select:[],  
		deals_select:[], 
		payer_select:[], 
		expense_total:0,
		box_office_balance:0,
		box_office:{
			show:false,
			included:[],
			expenses:[],
			included_summ:0,
			expenses_summ:0,
			balance:0,
			page_included:1,
			quantity_page_included:0,
			page_expenses:1,
			quantity_page_expenses:0,
			get_row:5,			
		},
		order_by:{
			date:'desc',
			expenses:null,
			item_expenditures:null,
			summ:null,
			payer:null
		}, 
		delete:{
			show:false,
			key_row:null
		},
		num_page:10,
		page:1,
		quantity_pages:0,
		timeout:null,     
	},
	getters: {
		expenses_accounting:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){ 
			state.table = data.table; 
			state.quantity_pages = data.quantity_pages;  
			state.expense_total = data.expense_total;
			state.expenses_select = data.expenses_select;  
			state.deals_select = data.deals_select;  
			state.payer_select = data.payer_select;   
			state.box_office_balance = data.box_office_balance;   
		},  
		setPage(state, page){ 
			state.page = page; 
		},
		changeExpenses(state, data){ 

			let key_row = data.key_row;
			let expenses_id = Number(data.e.target.value);
			 
			for(let key in state.expenses_select){

				if(state.expenses_select[key].id == expenses_id){
					state.table[key_row].colls[2].select = state.expenses_select[key].item_expenditure;
				}
			}

			state.table[key_row].colls[2].value = null;
			state.table[key_row].colls[3].value = null;
		},
		changeItemExpenditures(state, data){ 

			let key_row = data.key_row;
			let item_expenditures_id = Number(data.e.target.value);
			  
			for(let key in state.table[key_row].colls[2].select){ 
				if(state.table[key_row].colls[2].select[key].id == item_expenditures_id){
					state.table[key_row].colls[3].select = state.table[key_row].colls[2].select[key].suppliers;
					state.table[key_row].hide_deal = state.table[key_row].colls[2].select[key].hide_deal
					
					if(state.table[key_row].colls[2].select[key].hide_deal)
						state.table[key_row].colls[4].value = null
				}   
			}

			state.table[key_row].colls[3].value = null;
		},
		showBoxOffice(state, status){ 
			state.box_office.show = status;  
		},
		setBoxOffice(state, data){ 
			state.box_office.included = data.included;   
			state.box_office.expenses = data.expenses; 
			state.box_office.included_summ = data.included_summ;   
			state.box_office.expenses_summ = data.expenses_summ;   
			state.box_office.balance = data.balance;   
			state.box_office.quantity_page_included = data.quantity_page_included; 
			state.box_office.quantity_page_expenses = data.quantity_page_expenses; 
		},
		setPageIncluded(state, page){ 
			state.box_office.page_included = page; 
		},
		setOrderBy(state, type){ 
			  
			if(type == "date"){  
				state.order_by.date = state.order_by.date == null ? 'desc' : state.order_by.date == 'desc' ? 'asc' : 'desc'; 
				state.order_by.expenses = null
				state.order_by.item_expenditures = null
				state.order_by.summ = null
				state.order_by.payer = null 
			}

			if(type == "expenses"){  
				state.order_by.expenses = state.order_by.expenses == null ? 'desc' : state.order_by.expenses == 'desc' ? 'asc' : 'desc'; 
				state.order_by.date = null 
				state.order_by.item_expenditures = null
				state.order_by.summ = null
				state.order_by.payer = null
			}
			if(type == "item_expenditures"){  
				state.order_by.item_expenditures = state.order_by.item_expenditures == null ? 'desc' : state.order_by.item_expenditures == 'desc' ? 'asc' : 'desc'; 
				state.order_by.date = null
				state.order_by.expenses = null 
				state.order_by.summ = null
				state.order_by.payer = null
			}
			  
			if(type == "summ"){ 
				state.order_by.summ = state.order_by.summ == null ? 'desc' : state.order_by.summ == 'desc' ? 'asc' : 'desc';
				state.order_by.date = null
				state.order_by.expenses = null
				state.order_by.item_expenditures = null 
				state.order_by.payer = null
			}
				
			if(type == "payer"){ 
				state.order_by.payer = state.order_by.payer == null ? 'desc' : state.order_by.payer == 'desc' ? 'asc' : 'desc';
				state.order_by.date = null
				state.order_by.expenses = null
				state.order_by.item_expenditures = null
				state.order_by.summ = null 
			}
		  
		}, 
		setPreloader(state, status){ 
			state.preloader = status; 
		},
		setDelete(state, data){ 
			state.delete.show = data.status;
			state.delete.key_row = data.key_row;   
		},
		setStatus(state, data){ 
			state.table[data.key_row].status = data.status;
		}			 
	},
	actions: {
		async delValueBoxOffice({ dispatch, state }, key){
			 
			let data = await expensesApi.boxOfficeDelValue({ id:state.box_office.included[key].id });
			if(data.result) {
				dispatch("showBoxOffice", true);
			}	 	 
		},
		async setValueBoxOffice({ dispatch, state }, key){ 
			let data = await expensesApi.boxOfficeSetValue({ data:state.box_office.included[key] });
			if(data.result) {
				dispatch("showBoxOffice", true);	
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true });
			}	
		},
		async addValueBoxOffice({ dispatch, commit }){ 
			let data = await expensesApi.boxOfficeSetValue();
			if(data.result) {
				dispatch("showBoxOffice", true);	
				commit('setPageIncluded', 1);  
			}	
		},
		async showBoxOffice({ commit, state }, status){ 
			
			if(status){ 
				let data = await expensesApi.getBoxOffice({ 
					filter:state.filter,
					page_included:state.box_office.page_included, 
					page_expenses:state.box_office.page_expenses, 
					get_row:state.box_office.get_row 
				});
				if(data.result) { 
					commit('setBoxOffice', data);  
				}
			}
			commit('showBoxOffice', status);
		},
		changeItemExpenditures({ dispatch, commit }, data){ 
			commit('changeItemExpenditures', data); 
			dispatch("setValue", { param_id:'item_expenditures_id', key_row:data.key_row, key_coll:data.key_coll });
		},
		changeExpenses({ dispatch, commit }, data){ 
			commit('changeExpenses', data); 
			dispatch("setValue", { param_id:'expenses_id', key_row:data.key_row, key_coll:data.key_coll });
		},
		async delValue({ dispatch, commit, state }){  			   
			let data = await expensesApi.delValue({ id:state.table[state.delete.key_row].id });
			if(data.result) {
				dispatch("loadDataExpensesAccounting", state.filter);
				commit('setDelete', { status:false, key_row:null }); 	
			}	 	 
		},
		setValue({ dispatch, state, commit }, data){  
			
			//clearTimeout(state.timeout)
			  
			let delay = 0; 
 
			let param_id = data.param_id;
			let key_row = data.key_row;
			let key_coll = data.key_coll;
 
			let id = state.table[key_row].id;
			  
			 

			commit('setStatus', { key_row, status:null });

			if(param_id == "summ" || param_id == "comment")
				delay = 300;
			   
			state.timeout = setTimeout(async () => {
				
				let value = state.table[key_row].colls[key_coll].value;
				console.log(param_id, id, value, data.e);
				await expensesApi.setValue({ id, param_id, value });
			}, delay) 
			 	 
		},
		async addRowTable({  dispatch, state, commit }){  
			let data = await expensesApi.setValue();
			if(data.result) {
				commit('setPage', 1);
				dispatch("loadDataExpensesAccounting", state.filter);	 
			}	 	 
		},
		async saveTable({  dispatch, state, commit }){  
			let data = await expensesApi.saveTable();
			if(data.result) { 
				dispatch("loadDataExpensesAccounting", state.filter);	
				dispatch('snackBar/showSnackBar', "Данные обновлены!", { root: true }); 
			}	 	 
		}, 
		orderBy({ commit, state, dispatch }, type){ 		 
			commit('setOrderBy', type);	
			dispatch("loadDataExpensesAccounting", state.filter);	
		},
		async loadFilterExpensesAccounting ({ commit, dispatch, rootGetters }){
			let data = await expensesApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"expenses_accounting"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				dispatch("loadDataExpensesAccounting", data.filter);	
			}				 
		},    
		async setFilterExpensesAccounting ({ dispatch, rootGetters }, filter){
			let data = await expensesApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"expenses_accounting", filter });	 
			if(data.result) {
				dispatch("loadDataExpensesAccounting", data.filter);	
			}				 
		},
		async loadDataExpensesAccounting({ commit, state }, filter){ 
			commit('setPreloader', true);
			let data = await expensesApi.getData({ filter, page:state.page, order_by:state.order_by, num_page:state.num_page });	 
			if(data.result) {
				commit('setData', data);	
				commit('setPreloader', false);		 
			}		 
		},  
		showDelete({ commit }, data){
			commit('setDelete', data); 
		}
		 
	}, 
} 