<template>
  <v-app-bar :elevation="1" color="blue-analytics-1">
    <v-app-bar-nav-icon @click="appInterface.account.show_drawer = !appInterface.account.show_drawer"></v-app-bar-nav-icon> 
    <template v-slot:append>
      <!-- <v-btn icon="mdi-bell-outline"></v-btn>   -->
      <v-list-item class="w-100">
        <template v-slot:prepend> 
             
          <v-avatar v-if="account.bitrix.photo !== null" :image="account.bitrix.photo" class="justify-start" style="margin-right: 5px;"></v-avatar> 
          <v-avatar v-else color="grey-darken-3"><v-icon icon="mdi-account-circle"></v-icon></v-avatar>

        </template>
        <v-list-item-title> 
            {{ account.bitrix.last_name+" "+account.bitrix.name }} 
        </v-list-item-title>
      </v-list-item>              
    </template> 
  </v-app-bar>
</template>
<script>
import { mapGetters } from 'vuex'
export default { 
  computed:{ 
    ...mapGetters("appInterface", {appInterface:"appInterface"}),
    ...mapGetters("account", {account:"account"})
  },
}
</script>