import * as warehouseWriteOffsApi from '@/api/warehouse_write_offs.js';

export default {
	namespaced: true,
	state: {
		filter:null,
		table:[],  
		page:1,
		get_row:20,
		quantity_page:null,
		group_by:{ 
			positoin_id:false,
			order_id:false,
			articles_pzn_id:false,
			names_id:false,
			category_id:false,
			type_id:false,
			class_id:false,
			colour_id:false,
			deal_id:false,  	 
		}, 
		delete_write_offs:[]
	}, 
	getters: {
		warehouse_write_offs:state=>{
			return state
		},  
	},
	mutations: { 
		setFilter(state, filter){ 
			state.filter = filter; 
		}, 
		setData(state, data){   
			state.table = data.table;   
			state.quantity_page = data.quantity_page;   
		},
		setOrderBy(state, param_id){  
			state.group_by[param_id] = state.group_by[param_id] ? false : true; 
			if(param_id == "category_id" && !state.group_by.category_id){ 
				state.group_by.type_id = false;  
				state.group_by.class_id = false;   
			} 
			if(
				(param_id == "type_id" || param_id == "class_id") && 
				(state.group_by.type_id || state.group_by.class_id)
			) 
				state.group_by.category_id = true;  			 	 
		},
		deleteWriteOffs(state, delete_write_offs){
			state.delete_write_offs = delete_write_offs
		},
		setPage(state, page){ 
			state.page = page 
		},
		resetFilter(state){ 
			state.filter.period = "current_month"; 
			state.filter.date = null;
			state.filter.articles_supplier_id = null;
			state.filter.articles_pzn_id = null;
			state.filter.category_product_id = null;
			state.filter.type_product_id = null;
			state.filter.class_product_id = null;
			state.filter.colour_product_id = null;
			state.filter.supplier_product_id = null;
			state.filter.payer_product_id = null;
			state.filter.position_id = null;
			state.filter.deal_id = null;
			state.filter.category_write_offs = null;
		}    
	},
	actions: { 
		async loadFilterWarehouseWriteOffs ({ state, commit, dispatch, rootGetters }){

			let data = await warehouseWriteOffsApi.getFilter({user_id:rootGetters['account/account'].user_id, page:"warehouse_write_offs"});	 
			if(data.result) {
				commit('setFilter', data.filter);	
				await dispatch("loadDataWarehouseWriteOffs", { filter:data.filter });
			}				 
		},    
		async setFilterWarehouseWriteOffs ({ dispatch, rootGetters, commit }, filter){
			let data = await warehouseWriteOffsApi.setFilter({ user_id:rootGetters['account/account'].user_id, page:"warehouse_write_offs", filter });	 
			if(data.result) {
				commit('setPage', 1);
				dispatch("loadDataWarehouseWriteOffs", { filter:data.filter });
			}				 
		},
		async loadDataWarehouseWriteOffs({ commit, state }, data){ 
			let response = await warehouseWriteOffsApi.getData({ filter:data.filter, order_by:state.group_by, page:state.page, get_row:state.get_row });	 
			if(response.result) {
				commit('setData', response);						 
			}
		}, 
		groupBy({ state, commit, dispatch }, param_id){  
			commit('setOrderBy', param_id);	  
			dispatch("loadDataWarehouseWriteOffs", { filter:state.filter });
		},  
		async deleteWriteOffs({ state, commit, dispatch }){

			let data = await warehouseWriteOffsApi.deleteWriteOffs({ delete_write_offs:state.delete_write_offs });	 
			if(data.result) {
				commit('deleteWriteOffs', []);
				dispatch("loadDataWarehouseWriteOffs", { filter:state.filter });
			}					 	
		},
		changeDataWriteOffs({ state }, data){	   
			let params_val = null;
			let delay = 1500;
			if(data.params_id == "comment") params_val = state.table[data.key].comment; 
			clearTimeout(state.timeout) 
			state.timeout = setTimeout(async () => {   
				let response = await warehouseWriteOffsApi.setWriteOffs({  
					action:"save", 
					params_id:data.params_id, 
					params_val, 
					position_id:state.table[data.key].position_id
				});	  

			}, delay); 
		},
		resetFilter({ state, commit, dispatch }){
			commit('resetFilter');	
			dispatch("loadDataWarehouseWriteOffs", { filter:state.filter });	
		}
	}, 
} 