export default {
	namespaced: true,
	state: {
		name:null,
		params:null,
		from:null
	},
	getters: {
		getRoute:state=>{
			return state
		}, 
	},
	mutations: { 
		setRoute(state, data){ 
			 
			state.name = data.to.name; 
			state.params = data.to.params; 
			//state.from = data.from; 
		},  
	},
	actions: {
		setRoute({ commit, dispatch }, data){ 
			commit('setRoute', data); 
			 
			if(data.from.name == "warehouse-remains"){
				dispatch("warehouse_remains_card/nullableData", {}, { root: true });  
			}
				 
		}, 
	}
}