import server from '@/api/http';
 
export async function getFilter(params){  
	let { data } = await server.get('fot/result/get_filter', { params });
	return data;
} 

export async function getData(params){  
	let { data } = await server.get('fot/result/get_data', { params });
	return data;
} 

export async function setFilter(params){  
	let { data } = await server.get('fot/result/set_filter', { params });
	return data;
}   
 
export async function upSuperannuationAllowance(params){  
	let { data } = await server.get('fot/result/up_superannuation_allowance', { params });
	return data;
}   

export async function saveManagers(params){  
	let { data } = await server.get('fot/result/save_managers', { params });
	return data;
}   

export async function delManagers(params){  
	let { data } = await server.get('fot/result/del_managers', { params });
	return data;
}    

export async function getReport(params){  
	let { data } = await server.get('fot/result/get_report', { params });
	return data;
}    

export async function downloadReport(params){  
	let { data } = await server.get('fot/result/download_report', { params });
	return data;
}    