import * as warehouseOrderApi from '@/api/warehouse_orders.js';
import * as warehouseWriteOffsApi from '@/api/warehouse_write_offs.js';
 
export default {
	namespaced: true,
	state: {
		show:false, 
		update_status:false,
		card_type:null,
		order:null,
		print_order:null, 
		timeout:null, 
		notify:{
			type:null,
			show:false,
			message:null
		}, 
		notify_modify:{
			show:false,
		},
		delete_position:[],
		file:null
	},
	getters: {
		warehouse_order_card:state=>{
			return state
		},  
	},
	mutations: { 
		setShow(state, data){    
			state.show = data.status;  
			state.card_type = data.card_type;   
		},
		setNotifyModify(state, data){    
			state.notify_modify.show = data.status;    
		},
		setUpdateStatus(state, status){
			state.update_status = status
		}, 
		setOrder(state, data){    
			state.order = data.order; 
		},
		setShipmentFrom(state, data){    
			state.order.deal = data.deal;
			state.order.category_write_offs_custom = data.category_write_offs_custom;
			state.order.shipment_from = data.shipment_from;
			state.order.customer = data.customer; 
			state.order.users = data.users;   			 
		},
		setFile(state, file){    
			state.file = file; 
		},   
		setOrderNotify(state, data){   
			state.notify.show = data.status; 
			state.notify.message = data.message;   
			state.notify.type = data.type;  
		},  
		setOrderPosition(state, data){ 
			let order_hold = state.order.positions[data.key].items[data.k].order_hold;
			let hold_workshop = state.order.positions[data.key].items[data.k].hold_workshop;
			let remains = state.order.positions[data.key].items[data.k].remains;
			let limit_remains = state.order.positions[data.key].items[data.k].limit_remains;	
			 
			if(hold_workshop < 0){
				state.order.positions[data.key].items[data.k].hold_workshop = 0;
				hold_workshop = 0;
			}

			if(order_hold < 0){
				state.order.positions[data.key].items[data.k].order_hold = 0;
				order_hold = 0;
			}
			
			if(hold_workshop > limit_remains){
				state.order.positions[data.key].items[data.k].hold_workshop = limit_remains;
				hold_workshop = limit_remains; 
			}

			if(order_hold > limit_remains){
				state.order.positions[data.key].items[data.k].order_hold = limit_remains;
				order_hold = limit_remains; 
			}

			/// 

			let group_summ = 0;
			let total_summ = 0;

			if(data.param_id == "order_hold"){  
				state.order.positions[data.key].items[data.k].remains = limit_remains - order_hold;
				state.order.positions[data.key].items[data.k].summ = Math.round((state.order.positions[data.key].items[data.k].price * order_hold) * 100) / 100;

				let group_order_hold = 0;
				  
				for(let k in state.order.positions[data.key].items){ 
					group_order_hold = group_order_hold + parseFloat(state.order.positions[data.key].items[k].order_hold);
					group_summ = group_summ + parseFloat(state.order.positions[data.key].items[k].summ);  
				}

				state.order.positions[data.key].total_group.order_hold = group_order_hold;
				state.order.positions[data.key].total_group.summ = group_summ;

				let total_order_hold = 0;

				for(let key in state.order.positions){ 
					if(key !== "total"){
						total_order_hold = total_order_hold + parseFloat(state.order.positions[key].total_group.order_hold);
						total_summ = total_summ + parseFloat(state.order.positions[key].total_group.summ);
					}   
				}	 
				state.order.positions.total.order_hold = total_order_hold;
			}

			if(data.param_id == "hold_workshop"){  
				state.order.positions[data.key].items[data.k].remains = limit_remains - hold_workshop;
				state.order.positions[data.key].items[data.k].summ = Math.round((state.order.positions[data.key].items[data.k].price * hold_workshop) * 100) / 100;

				let group_hold_workshop = 0;
			  
				for(let k in state.order.positions[data.key].items){ 
					group_hold_workshop = group_hold_workshop + parseFloat(state.order.positions[data.key].items[k].hold_workshop);
					group_summ = group_summ + parseFloat(state.order.positions[data.key].items[k].summ);  
				}
  
				state.order.positions[data.key].total_group.hold_workshop = group_hold_workshop;
				state.order.positions[data.key].total_group.summ = group_summ;

				let total_hold_workshop = 0;

				for(let key in state.order.positions){ 
					if(key !== "total"){
						total_hold_workshop = total_hold_workshop + parseFloat(state.order.positions[key].total_group.hold_workshop);
						total_summ = total_summ + parseFloat(state.order.positions[key].total_group.summ); 
					}   
				}
	 
				state.order.positions.total.hold_workshop = total_hold_workshop;
			}
 
			state.order.positions.total.summ = total_summ; 	   
		}, 
		deletePosition(state, delete_position){
			state.delete_position = delete_position
		},
		deleteOrderPosition(state){

			for(let key in state.order.positions){
				if(key !== "total") 
					for(let key_del in state.delete_position) {		
						
						if(state.order.positions[key].items.length > 0)
							for(let k in state.order.positions[key].items)
								if(
									Number(state.order.positions[key].items[k].position_id) == Number(state.delete_position[key_del])
								){ 
									state.order.positions[key].total_group.summ = parseFloat(state.order.positions[key].total_group.summ) - parseFloat(state.order.positions[key].items[k].summ);
									state.order.positions[key].total_group.price = parseFloat(state.order.positions[key].total_group.price) - parseFloat(state.order.positions[key].items[k].price);
									state.order.positions[key].total_group.order_hold = parseFloat(state.order.positions[key].total_group.order_hold) - parseFloat(state.order.positions[key].items[k].order_hold);
									state.order.positions[key].total_group.hold_workshop = parseFloat(state.order.positions[key].total_group.hold_workshop) - parseFloat(state.order.positions[key].items[k].hold_workshop);
									state.order.positions[key].total_group.write_offs = parseFloat(state.order.positions[key].total_group.write_offs) - parseFloat(state.order.positions[key].items[k].write_offs);
									state.order.positions[key].items.splice(k, 1);	  	 
								}

						if(state.order.positions[key].items.length == 0)
							delete state.order.positions[key];

					}
			}
			
			state.delete_position = [];

			let summ = 0;
			let price = 0;
			let order_hold = 0;
			let hold_workshop = 0;
			let write_offs = 0;

			for(let key in state.order.positions){
				if(key !== "total"){ 
					summ = summ + state.order.positions[key].total_group.summ; 
					price = price + state.order.positions[key].total_group.price;
					order_hold = order_hold + state.order.positions[key].total_group.order_hold;
					hold_workshop = hold_workshop + state.order.positions[key].total_group.hold_workshop;
					write_offs = write_offs + state.order.positions[key].total_group.write_offs;				 
				}
			}
			state.order.positions.total.summ = summ
			state.order.positions.total.price = price
			state.order.positions.total.order_hold = order_hold
			state.order.positions.total.hold_workshop = hold_workshop
			state.order.positions.total.write_offs = write_offs

		}
	},
	actions: { 
		async showWarehouseOrderCard({ state, commit, dispatch }, data){   			
			
			let status_order = null; 
			
			if(state.order !== null) status_order = state.order.status.id;	

			if(data.status){
				commit('setShow', data);
				await warehouseOrderApi.getOrder({ order_id:data.order_id }).then((response)=>{
					if(response.result) {  
						 
						commit('setOrder', response);   
						if(typeof data.modify == 'undefined'){ 
							dispatch("getModify")
							commit('setUpdateStatus', false);
						}					 	 
					}	   
				});	 
			}
			
			if(!data.status)				
				dispatch("getModify").then((response)=>{
					
					if(!response.result) {
						commit('setOrder', { order:null, deals_list:[], category_write_offs_list:[], print_order:null });	
						commit('setShow', data);
					}					
					commit('setUpdateStatus', false);
				})				
			
			commit('deletePosition', []);	
		},  
		async delOrder({ state, dispatch, commit, rootGetters }){
			let response = await warehouseOrderApi.delOrder({ order_id:state.order.order_id });	 
			if(response.result) { 
				await dispatch("warehouse_orders/loadDataWarehouseOrders", 
					{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
					{ root: true }
				);
				dispatch("showOrderNotify", { status:false, message:null, type:null });
				dispatch("showWarehouseOrderCard", { status:false, order_id:null, card_type:null });   
			}	
		},  	
		changeDataOrder({ state, commit, dispatch, rootGetters }, param_id){
			
			let param_val = null;
			let delay = 0;
			
			if(param_id == "date") param_val = state.order.date;
			if(param_id == "deal_id") param_val = state.order.deal.id;
			if(param_id == "category_write_offs_id") param_val = state.order.category_write_offs_id;
			if(param_id == "category_write_offs_custom") {
				param_val = state.order.category_write_offs_custom;
				delay = 1500;
			}
			if(param_id == "comment") {
				param_val = state.order.comment;
				delay = 1500;
			}
			if(param_id == "comment_long") {
				param_val = state.order.comment_long;
				delay = 1500;
			}

			clearTimeout(state.timeout);
			
			state.timeout = setTimeout( async () => {
				let response = await warehouseOrderApi.setOrder({ param_id, param_val, order_id:state.order.order_id });	 
				if(response.result) { 

					commit('setUpdateStatus', true);

					if(param_id == "deal_id" || param_id == "category_write_offs_id") 
						commit("setShipmentFrom", {
							deal:response.data.deal,
							category_write_offs_custom:response.data.category_write_offs_custom, 
							shipment_from:response.data.shipment_from, 
							customer:response.data.customer, 
							users:response.data.users,
						});  
				}	
			}, delay)
		},
		async changeDataPosition({ state, commit }, data){ 

			commit('setOrderPosition', data);	 
			
			//clearTimeout(state.timeout);
			
			let param_id = data.param_id;
			let position_id = state.order.positions[data.key].items[data.k].position_id;
			let param_val = null;

			if(param_id == "order_hold")
				param_val = state.order.positions[data.key].items[data.k].order_hold;

			if(param_id == "hold_workshop")
				param_val = state.order.positions[data.key].items[data.k].hold_workshop;
			
			//state.timeout = setTimeout(async () => {
				 
				let response = await warehouseOrderApi.setPositionModify({ order_id:state.order.order_id, position_id, param_id, param_val });	 
				if(response.result) { 
					commit('setUpdateStatus', true);
				}  
			//}, 1500)
		}, 
		async saveDataPosition({ state, dispatch, rootGetters }){ 			
			
			let positions = [];
			
			if(state.order !== null){
				for(let key in state.order.positions){
					if(key !== "total") 
						for(let k in state.order.positions[key].items){
							positions.push({ 
								position_id:state.order.positions[key].items[k].position_id,
								order_hold:state.order.positions[key].items[k].order_hold,
								hold_workshop:state.order.positions[key].items[k].hold_workshop,
							});
						}
				}

				let response = await warehouseOrderApi.setPosition({ order_id:state.order.order_id, positions });	 
				if(response.result) {

					await dispatch("warehouse_orders/loadDataWarehouseOrders", 
						{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
						{ root: true }
					); 
					return { result: true };
				}
			}
		}, 
		async saveChange({ state, commit, dispatch, rootGetters }){
			let response = await warehouseOrderApi.saveChange({ order_id:state.order.order_id });	 
			if(response.result) { 
				commit('setUpdateStatus', false);
				commit('setNotifyModify', { status:false });
				dispatch("showWarehouseOrderCard", { status:true, order_id:state.order.order_id, card_type:state.card_type });
				await dispatch("warehouse_orders/loadDataWarehouseOrders", 
					{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
					{ root: true }
				);
			}
		}, 
		async cancelChange({ state, commit, dispatch, rootGetters }){
			let response = await warehouseOrderApi.cancelChange({ order_id:state.order.order_id });	 
			if(response.result) { 
				commit('setUpdateStatus', false);
				commit('setNotifyModify', { status:false });
				dispatch("showWarehouseOrderCard", { status:true, order_id:state.order.order_id, card_type:state.card_type });
				await dispatch("warehouse_orders/loadDataWarehouseOrders", 
					{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
					{ root: true }
				);
			}
		},
		async runWorkshop({ state, dispatch, rootGetters }){

			let validation = true;
			let message = null;
			let order_id = state.order.order_id;

			if(state.order.date == null){
				validation = false;
				message = "Для того чтобы отправить заказ в Цех выберите «Дату заказа»";
			}

			if(validation && state.order.category_write_offs_id !== 3){
				validation = false;
				message = "Для того чтобы отправить заказ в Цех выберите «Категорию списания -> Под проект/сделку»";
			}

			if(validation && state.order.category_write_offs_id == 3 && state.order.deal.id == null){
				validation = false;
				message = "Для того чтобы отправить заказ в Цех выберите «Проект/сделку»";
			}

			if(validation)  
				for(let key in state.order.positions) 
					if(key !== "total")
						for(let k in state.order.positions[key].items) 
							if(state.order.positions[key].items[k].order_hold == 0){
								validation = false;
								message = "Для того чтобы отправить заказ в Цех, позиция не должна быть равна 0";
							}

			if(!validation)  dispatch("showOrderNotify", { status:true, message, type:"error" });  
			else{  

				let res = await warehouseOrderApi.runWorkshop({ order_id });	 
				if(res.result) {  
					dispatch("showWarehouseOrderCard", { status:true, order_id, card_type:state.card_type });
					await dispatch("warehouse_orders/loadDataWarehouseOrders", 
							{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
							{ root: true }
						); 
				}

				// dispatch("saveDataPosition").then( async (response)=>{
				// 	if(response.result) {  
 
				// 	} 
				// });
			}	  
		},
		async cancelWorkshop({ state, dispatch, rootGetters }){

			let response = await warehouseOrderApi.cancelWorkshop({ order_id:state.order.order_id});	 
			if(response.result) {  
				dispatch("showWarehouseOrderCard", { status:true, order_id:state.order.order_id, card_type:state.card_type });
				await dispatch("warehouse_orders/loadDataWarehouseOrders", 
						{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
						{ root: true }
					); 
			}
		},
		async runWriteOffs({ commit, state, dispatch, rootGetters }){
			
			let validation = true;
			let message = null;
			let order_id = state.order.order_id;

			if(state.order.date == null){
				validation = false;
				message = "Для того чтобы провести списание выберите «Дату заказа»";
			}

			if(validation && state.order.category_write_offs_id == null){
				validation = false;
				message = "Для того чтобы провести списание выберите «Категорию списания»";
			}

			if(validation && state.order.category_write_offs_id == 3 && state.order.deal.id == null){
				validation = false;
				message = "Для того чтобы провести списание выберите «Проект/сделку»";
			}

			if(validation)  
				for(let key in state.order.positions) 
					if(key !== "total")
						for(let k in state.order.positions[key].items) 
							if(state.order.positions[key].items[k].order_hold == 0){
								validation = false;
								message = "Для того чтобы провести списание, позиция не должна быть равна 0";
							}
 
			if(!validation)  dispatch("showOrderNotify", { status:true, message, type:"error" });  
			else{  
				let res = await warehouseWriteOffsApi.createWriteOffs({ order_id });	 
				if(res.result) { 
					await dispatch("warehouse_orders/loadDataWarehouseOrders", 
						{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
						{ root: true }
					); 					
					dispatch("showWarehouseOrderCard", { status:false, order_id:null, card_type:null });  
				} 
			}
		},
		async cancelWriteOffs({ commit, state, dispatch, rootGetters }){
			
			let response = await warehouseWriteOffsApi.cancelWriteOffs({ order_id:state.order.order_id });	 
			if(response.result) { 
				await dispatch("warehouse_orders/loadDataWarehouseOrders", 
					{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
					{ root: true }
				); 
				dispatch("showOrderNotify", { status:false, message:null, type:null });
				// commit('setOrder', { order:null, deals_list:[], category_write_offs_list:[] });	
				dispatch("showWarehouseOrderCard", { status:true, order_id:state.order.order_id, card_type:state.card_type });  
			}
		}, 
		async deletePosition({ state, commit, dispatch, rootGetters }){

			let positions = [];
 
			for(let key in state.order.positions){
				if(key !== "total") 
					for(let k in state.order.positions[key].items){
						let status = true;
						for(let key_del in state.delete_position){ 
							if(state.order.positions[key].items[k].position_id == state.delete_position[key_del])
								status = false;
						}						  
						if(status) positions.push(state.order.positions[key].items[k].position_id);
					}
			} 
		  
			let response = await warehouseOrderApi.upOrder({ order_id:state.order.order_id, positions });
			
			if(response.result) { 
				commit('setUpdateStatus', true);
				commit('deleteOrderPosition');	 
				//dispatch("showWarehouseOrderCard", { status:true, order_id:state.order.order_id, card_type:state.card_type });  	
			}
		},
		async getModify({ state, commit }){			
			
			let result = false;

			let response = await warehouseOrderApi.getModify({ order_id:state.order.order_id });
			if(response.result) {
				commit('setNotifyModify', { status:true });
				result = response.result
			}

			return { result }
		},
		showOrderNotify({ commit }, data){
			commit('setOrderNotify', data);
		},
		async confirmWorkshop({ state, dispatch, rootGetters }){

			// let validation = true;
			// let message = null;
			   
			// for(let key in state.order.positions) 
			// 	if(key !== "total")
			// 		for(let k in state.order.positions[key].items) 
			// 			if(state.order.positions[key].items[k].hold_workshop == 0){
			// 				validation = false;
			// 				message = "Для того чтобы подтвердить заказ, позиция не должна быть равна 0";
			// 			}

			// if(!validation)  dispatch("showOrderNotify", { status:true, message, type:"error" });  
			// else{ 
				
			let res = await warehouseOrderApi.confirmWorkshop({ order_id:state.order.order_id });	 
			if(res.result) { 
				await dispatch("warehouse_orders/loadDataWarehouseOrders", 
					{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
					{ root: true }
				); 
				//dispatch("showOrderNotify", { status:false, message:null, type:null });
				
				dispatch("showWarehouseOrderCard", { status:true, order_id:state.order.order_id, card_type:state.card_type });  
			}

				// dispatch("saveDataPosition").then(async (response)=>{ 

					 
				// });
			//}
		},
		async returnWorkshop({ state, dispatch, rootGetters }){

			let res = await warehouseOrderApi.returnWorkshop({ order_id:state.order.order_id });	 
			if(res.result) { 
				await dispatch("warehouse_orders/loadDataWarehouseOrders", 
					{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
					{ root: true }
				);  
				
				dispatch("showWarehouseOrderCard", { status:true, order_id:state.order.order_id, card_type:state.card_type });  
			}
		},		 
		async returnForConfirmation({ state, dispatch, rootGetters }){

			let res = await warehouseOrderApi.returnForConfirmation({ order_id:state.order.order_id });	 
			if(res.result) { 
				await dispatch("warehouse_orders/loadDataWarehouseOrders", 
					{ filter:rootGetters['warehouse_orders/warehouse_orders'].filter }, 
					{ root: true }
				); 				
				dispatch("showWarehouseOrderCard", { status:true, order_id:state.order.order_id, card_type:state.card_type });  
			}
		},
		async handleFile({ state, commit, dispatch }){		
			
			console.log(state.file);
 
			for(let key in state.file){

				let formData = new FormData(); 
				formData.append('files', state.file[key]);
				formData.append('order_id', state.order.order_id);  
				await warehouseOrderApi.downloadFiles(formData);
			}
			dispatch("showWarehouseOrderCard", { status:true, order_id:state.order.order_id, card_type:state.card_type }); 
			commit('setFile', null);			 
		},
		async delFile({ state, commit, dispatch }, key){
			let res = await warehouseOrderApi.delFile({ order_id:state.order.order_id, key });	
			if(res.result) { 
				dispatch("showWarehouseOrderCard", { status:true, order_id:state.order.order_id, card_type:state.card_type }); 
			} 
		},
		updateStatus({ commit }, status){
			commit('setUpdateStatus', status); 
		}
	}, 
} 