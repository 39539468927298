import * as warehouseEntryCardApi from '@/api/warehouse_entry_card.js';

export default {
	namespaced: true,
	state: {
		show:false,
		update_status:false,
		type:null,  
		entry:null,  
		products_load:false,   
		notify:{
			show:false,
			action:null,
			message:null,
			params:null
		},
		selected_position:[],
		position_relocate:{
			show:false,
			product_id:null,
		},
		custom_article_photo:{
			file:null
		},
		timeout:null, 
	},
	getters: {
		warehouse_entry_card:state=>{
			return state
		},  
	},
	mutations: {    
		setShow(state, data){      
			state.show = data.status;  
			state.type = data.type; 
		},   
		setUpdateStatus(state, status){
			state.update_status = status
		},
		setEntryNotify(state, data){
			state.notify.show = data.status;
			state.notify.action = data.action;
			state.notify.message = data.message; 
			state.notify.params = data.params;  
		},

		setEntryHead(state, entry){   
			state.entry = entry;   
		},
		editEntry(state, edit){
			state.entry.edit = edit;
		},
		setInvoiceSumm(state){ 
			let total_summ = 0; 
			for(let items of state.entry.products)  
				total_summ = total_summ + items.positions.total.summ; 
			if(total_summ > 0)
				state.entry.entry_invoice.summ = Math.round(total_summ* 100) / 100;   		 
		},
 

		setProductsLoad(state, status){      
			state.products_load = status;   
		}, 
		addProduct(state, data){  
			state.entry.products.push({
				product_id:data.product_id,
				product_num:data.product_num,  
				check_edit:true,
				product_article_supplier_status: false,
				product_article_supplier_id: null,
				product_article_supplier_custom: null,
				product_article_pzn_status: false,
				product_article_pzn_id: null,
				product_names: {
					id: null,
					name: null
				},
				product_category: {
					id: null,
					name: null
				},
				product_type: {
					id: null,
					name: null
				},
				product_class: {
					id: null,
					name: null
				},
				product_colour: {
					id: null,
					name: null
				},
				product_photo: null,
				product_comment: null,
				positions: {
					items: [],
					total: {
						quantity: 0,
						summ: 0
					}
				}
			});
		},
		setEntryProducts(state, products){ 
			if(state.entry !== null && products.length > 0)
				for(let items of products)
					state.entry.products.push(items); 
		}, 
		nullableEntryProducts(state){
			state.entry.products = [];
		},
		delProduct(state, key){
			state.entry.products.splice(key, 1);
		},
		upProduct(state, data){  
			if(state.entry !== null)
				for(let key in state.entry.products)
					if(state.entry.products[key].product_id == data.products.product_id)
						state.entry.products[key] = data.products;  
		},
		upArticlePzn(state, data){ 
			state.entry.products[data.key].product_article_pzn_id = data.data.product_article_pzn_id
			state.entry.products[data.key].product_names = data.data.product_names
			state.entry.products[data.key].product_category = data.data.product_category
			state.entry.products[data.key].product_type = data.data.product_type
			state.entry.products[data.key].product_class = data.data.product_class
			state.entry.products[data.key].product_colour = data.data.product_colour
			state.entry.products[data.key].product_photo = data.data.product_photo 
		},
		nullableSupplier(state, data){
			if(data.status)
				state.entry.products[data.key].product_article_supplier_id = null
			else
				state.entry.products[data.key].product_article_supplier_custom = null				 
		},
		upNumProducts(state){
			if(state.entry.products.length > 0)
				for(let key_product in state.entry.products)  
					state.entry.products[key_product].product_num = Number(key_product) + 1; 				
		},

		addPosition(state, data){
			state.entry.products[data.key].positions.items.push({
				id:data.id,
				position_id:data.position_id,
				modify:true,
				check_edit: true,
				position_valid: true,
				position_unit_id: 2,
				position_quantity: 0,
				position_price: 0,
				position_summ: 0,
				position_place: {
					zone: null,
					shelf: null
				},
				position_deals: [{
					hold: 0,
					deal_id: null,
					deal_name: null}],
				position_hold_total: 0,
				position_write_offs_total: 0
			});
		},
		deletePositions(state, data){
			state.entry.products[data.key].positions.items.splice(data.key_position, 1); 
		},
		setPositionSumm(state, data){
			let total_quantity = 0;
			let total_summ = 0;
			let position_price = Number(state.entry.products[data.key_product].positions.items[data.key_position].position_price);
			let position_quantity = Number(state.entry.products[data.key_product].positions.items[data.key_position].position_quantity);
			state.entry.products[data.key_product].positions.items[data.key_position].position_summ = Math.round(position_price*position_quantity* 100) / 100; 

			state.entry.products[data.key_product].positions.total.quantity
			state.entry.products[data.key_product].positions.total.summ

			for(let key in state.entry.products[data.key_product].positions.items){
				total_quantity = total_quantity + Number(state.entry.products[data.key_product].positions.items[key].position_quantity);
				total_summ = total_summ + state.entry.products[data.key_product].positions.items[key].position_summ;
			}

			state.entry.products[data.key_product].positions.total.quantity = total_quantity;
			state.entry.products[data.key_product].positions.total.summ = total_summ;			 
		}, 
		setPositionValid(state, data){
			state.entry.products[data.key].positions.items[data.k].position_valid = data.value;  
		},  
		setHoldDeal(state, data){ 
			state.entry.products[data.key_product].positions.items[data.key_position].position_deals[data.key_deal].hold = data.hold;
		},
		nullableHoldDeal(state, data){  
			let deals = state.entry.products[data.key_product].positions.items[data.key_position].position_deals; 
			for(let key in deals)  
				state.entry.products[data.key_product].positions.items[data.key_position].position_deals[key].hold = 0;			 
		}, 
		setPositionQuantity(state, data){
			state.entry.products[data.key_product].positions.items[data.key_position].position_quantity = data.quantity;
		},
		nullableSelectedPosition(state){
			state.selected_position = [];  
		},
		setShowPositionRelocate(state, data){      
			state.position_relocate.show = data.status;   
			state.position_relocate.product_id = data.product_id;  
		},
		addPositionDeal(state, data){
			state.entry.products[data.key].positions.items[data.key_position].position_deals.push({
				hold:0, 
				deal_id:null, 
				deal_name:null
			});
		},
		delPositionDeal(state, data){
			state.entry.products[data.key].positions.items[data.key_position].position_deals.splice(data.key_deal, 1);
		},   
		relocatePositions(state, data){
		  
			let key_replace_product = null;
 
			for(let key_product in state.entry.products)  
				if(state.entry.products[key_product].product_id == data.product_id) 
					key_replace_product = key_product;
			   
			for(let items of data.positions)  
				for(let key_product in state.entry.products)  
					if(state.entry.products[key_product].positions.items.length > 0)
						for(let key_position in state.entry.products[key_product].positions.items)  
							if(state.entry.products[key_product].positions.items[key_position].position_id == items){ 
								state.entry.products[key_replace_product].positions.items.push(state.entry.products[key_product].positions.items[key_position]); 
								state.entry.products[key_product].positions.items.splice(key_position, 1);
							}
						  
		},
		upPhotoCustomArticle(state, data){
			state.entry.products[data.key].product_photo = data.photo
			state.custom_article_photo.file = null;
		}
	},
	actions: { 
		async showWarehouseEntryCard ({ state, commit, dispatch }, data){ 
			  
			if(data.status){ 
  
				commit('setShow', data);

				let response = await warehouseEntryCardApi.getEntry({ entry_id:data.entry_id });	 
				if(response.result) {   
					commit('setEntryHead', response.entry);
					dispatch("loadEntryProducts", { entry_id:response.entry.entry_id, num:0, mode:"all" }); 

					let res = await warehouseEntryCardApi.getModify({ entry_id:data.entry_id });
					if(res.result) {  					
						dispatch("showEntryNotify", { status:true, action:'save_modify', message:'В приходе есть не сохраненные изменения', params:null });
					}
				}	
			} 
			else{   
				let response = await warehouseEntryCardApi.getModify({ entry_id:state.entry.entry_id });
				if(response.result) {  					
					dispatch("showEntryNotify", { status:true, action:'save_modify', message:'В приходе есть не сохраненные изменения', params:null });
				}
				else{
					commit('setShow', data);
					commit('setEntryHead', null);
					commit('nullableSelectedPosition');
				}		
			}	 		
		},    		
		async loadEntryProducts ({ commit, dispatch }, data){   
			commit('setProductsLoad', true);
			let response = await warehouseEntryCardApi.getEntryProducts({ entry_id:data.entry_id, num:data.num, mode:data.mode });	  
			if(response.result){ 
				commit('setEntryProducts', response.products); 
				commit('setProductsLoad', false);
				if(response.next) 
					dispatch("loadEntryProducts", { entry_id:data.entry_id, num:response.num, mode:data.mode})  
			}
		},		 
		async createEntry({ dispatch, rootGetters }, data){ 

			let response = await warehouseEntryCardApi.setEntry({ action:"add", data });
			if(response.result && data.type == "entry") { 
				await dispatch("warehouse_entry/loadDataWarehouseEntry", 
					{ filter:rootGetters['warehouse_entry/warehouse_entry'].filter }, 
					{ root: true }
				); 
				dispatch("showWarehouseEntryCard", { status:true, type:"edit", entry_id:response.entry_id });   
			}	

			// if(data.type == "expenses")
			// 	return { result:true, entry_id:response.entry_id };
		},  
		async delEntry({ state, dispatch, rootGetters }){ 
			let response = await warehouseEntryCardApi.delEntry({ id:state.entry.entry_id });	 
			if(response.result) { 					 
				dispatch("showWarehouseEntryCard", { status:false, type:null, entry_id:null });	
				await dispatch("warehouse_entry/loadDataWarehouseEntry", 
					{ filter:rootGetters['warehouse_entry/warehouse_entry'].filter }, 
					{ root: true }
				);	
			}	
		}, 
		async saveChange({ state, commit, dispatch }){

			let entry_id  = state.entry.entry_id;

			let response = await warehouseEntryCardApi.saveChange({ entry_id });	 
			if(response.result) { 	 
				dispatch("showEntryNotify", { status:false, action:null, message:null, params:null });
				commit('setUpdateStatus', false);
				commit('setEntryHead', null);
				commit('nullableSelectedPosition');
				dispatch("showWarehouseEntryCard", { status:true, type:"edit", entry_id });
			}
		}, 
		async cancelChange({ state, commit, dispatch }){

			let entry_id  = state.entry.entry_id;

			let response = await warehouseEntryCardApi.cancelChange({ entry_id });	 
			if(response.result) { 	
				dispatch("showEntryNotify", { status:false, action:null, message:null, params:null });
				commit('setUpdateStatus', false);
				commit('setEntryHead', null);
				commit('nullableSelectedPosition');
				dispatch("showWarehouseEntryCard", { status:true, type:"edit", entry_id });
			}

		}, 
		async changeDataEntry({ state, commit }, params_id){ 
			let params_val = null;  
			if(params_id == "date") params_val = state.entry.entry_date;
			if(params_id == "category_id") params_val = state.entry.entry_category_product.id;
			if(params_id == "supplier_id") params_val = state.entry.entry_supplier.id;
			if(params_id == "payer_id") params_val = state.entry.entry_payer.id;
			if(params_id == "invoice_number") params_val = state.entry.entry_invoice.number; 
			if(params_id == "invoice_date") params_val = state.entry.entry_invoice.date;
			if(params_id == "invoice_unit_id") params_val = state.entry.entry_invoice.unit_id;
			if(params_id == "invoice_summ") params_val = state.entry.entry_invoice.summ;
			 
			let response = await warehouseEntryCardApi.setEntry({ action:"save", params_id, params_val, id:state.entry.entry_id });	 
				if(response.result) { 
					commit('setUpdateStatus', true);
				}	  
		},
		async handleFile({ state, dispatch }){			 
			let formData = new FormData(); 
      		formData.append('file', state.entry.entry_invoice.file.file[0]);
			formData.append('id', state.entry.entry_id);  
			console.log(formData);
			let response = await warehouseEntryCardApi.downloadFile(formData);	  
			if(response.result) {   
				dispatch("showWarehouseEntryCard", { status:true, type:"edit", entry_id:state.entry.entry_id }); 
			}	
		},
		async delFile({ state, dispatch }){ 
			let response = await warehouseEntryCardApi.delFile({id:state.entry.entry_id});	  
			if(response.result) {   
				dispatch("showWarehouseEntryCard", { status:true, type:"edit", entry_id:state.entry.entry_id }); 
			}	
		}, 
		async addProduct({ state, commit }){ 

			let product_num = state.entry.products.length + 1;
			let product_id = "new_"+state.entry.entry_id+"_"+Date.now();

			let response = await warehouseEntryCardApi.setProduct({ entry_id:state.entry.entry_id, action:"add", product_id });	  
			if(response.result){   
				commit('addProduct', { product_id, product_num });
				commit('setUpdateStatus', true);	 
			}

			return { product_id };
		},
		async delProduct({ state, commit }, key){ 
			await warehouseEntryCardApi.delProduct({ id:state.entry.products[key].product_id, entry_id:state.entry.entry_id });	  
			commit('delProduct', key);
			commit('setUpdateStatus', true);
			commit('upNumProducts');
		},
		changeDataProduct({ state, commit }, data){	  
			let params_val = null;
			let delay = 0;
			if(data.params_id == "article_pzn_id") params_val = state.entry.products[data.key].product_article_pzn_id;
			if(data.params_id == "article_supplier_id") params_val = state.entry.products[data.key].product_article_supplier_id;
			if(data.params_id == "article_pzn_status") params_val = state.entry.products[data.key].product_article_pzn_status;  
			if(data.params_id == "article_supplier_status") params_val = state.entry.products[data.key].product_article_supplier_status;

			if(data.params_id == "names_id") params_val = state.entry.products[data.key].product_names.id;
			if(data.params_id == "type_id") params_val = state.entry.products[data.key].product_type.id;
			if(data.params_id == "class_id") params_val = state.entry.products[data.key].product_class.id;
			if(data.params_id == "colour_id") params_val = state.entry.products[data.key].product_colour.id;
			 
			if(data.params_id == "article_supplier_custom") {
				params_val = state.entry.products[data.key].product_article_supplier_custom;
				delay = 1500;
				clearTimeout(state.timeout)
			}
			 
			if(data.params_id == "comment") {
				params_val = state.entry.products[data.key].product_comment;
				delay = 1500;
				clearTimeout(state.timeout)
			}
			state.timeout = setTimeout(async () => {  
				let response = await warehouseEntryCardApi.setProduct({ 
					entry_id:state.entry.entry_id, 
					product_id:state.entry.products[data.key].product_id, 
					params_id:data.params_id, 
					params_val,
					action:"save" 
				});	  
				if(response.result) { 
					commit('setUpdateStatus', true);
					if(data.params_id == "article_pzn_id" || data.params_id == "article_pzn_status"){
						let article_pzn_id = null;
						if(data.params_id == "article_pzn_id")
							article_pzn_id = params_val

						let res = await warehouseEntryCardApi.getDataArticlePzn({ article_pzn_id });
						if(res.result){
							commit("upArticlePzn", { data:res.data, key:data.key }); 
						}
					}					
					if(data.params_id == "article_supplier_status") {  
						commit("nullableSupplier", { status:params_val, key:data.key }); 
					}					
				} 
			}, delay) 
		}, 
		async addPosition ({ state, commit }, key){
			
			let response = await warehouseEntryCardApi.setPosition({ 
				entry_id:state.entry.entry_id, 
				product_id:state.entry.products[key].product_id,  
				action:"add" 
			});	  
			if(response.result) {				 
				commit('setUpdateStatus', true);     
				commit('addPosition', { key, position_id:response.position_id, id:response.id });
			}	 
		}, 
		async delPosition({ state, commit }, data){
			 
			let response = await warehouseEntryCardApi.delPosition({  
				entry_id:state.entry.entry_id,
				product_id:state.entry.products[data.key].product_id,
				id:state.entry.products[data.key].positions.items[data.key_position].id,
				modify:state.entry.products[data.key].positions.items[data.key_position].modify,    
			});	  
			if(response.result) {   
				commit('setUpdateStatus', true);
				commit('nullableSelectedPosition');
				commit('deletePositions', { key:data.key, key_position:data.key_position });
			}	 
		},
		async addPositionDeal({ state, commit }, data){

			let response = await warehouseEntryCardApi.addPositionDeal({ 
				entry_id:state.entry.entry_id,
				product_id:state.entry.products[data.key].product_id, 
				id:state.entry.products[data.key].positions.items[data.key_position].id,  
				modify:state.entry.products[data.key].positions.items[data.key_position].modify,
			});	  
			if(response.result) {   
				commit('setUpdateStatus', true);
				commit('addPositionDeal', data);
			}	 

		},
		async delPositionDeal({ state, commit }, data){

			let response = await warehouseEntryCardApi.delPositionDeal({  
				entry_id:state.entry.entry_id,
				product_id:state.entry.products[data.key].product_id, 
				id:state.entry.products[data.key].positions.items[data.key_position].id,  
				modify:state.entry.products[data.key].positions.items[data.key_position].modify,
				key:data.key_deal  
			});	  
			if(response.result) { 
				commit('setUpdateStatus', true);  
				commit('delPositionDeal', data);
			}	 
		},		 
		async changeDataPosition({ state, commit, dispatch }, data){ 

			let params_val = null;			 

			if(data.params_id == "position_id"){  
				params_val = state.entry.products[data.key_product].positions.items[data.key_position].position_id;  

				if(!params_val.match(/^(([\d+]{1,}\-)([\d]{1,})|[\d]{1,})$/)){
					params_val = null;
				}
			}

			if(data.params_id == "unit_id")  
				params_val = state.entry.products[data.key_product].positions.items[data.key_position].position_unit_id;  

			if(data.params_id == "quantity"){ 
				
				params_val = state.entry.products[data.key_product].positions.items[data.key_position].position_quantity;
				let sum_hold_write_offs = parseFloat(state.entry.products[data.key_product].positions.items[data.key_position].position_hold_total) + parseFloat(state.entry.products[data.key_product].positions.items[data.key_position].position_write_offs_total);
				  
				let deals = state.entry.products[data.key_product].positions.items[data.key_position].position_deals;
				let hold_sum = 0;
				for(let key in deals){  
					hold_sum = hold_sum + parseFloat(deals[key].hold);
				} 

				if(state.entry.entry_status.id == 0){

					if(hold_sum > parseFloat(params_val)){
						commit('nullableHoldDeal', { key_product:data.key_product, key_position:data.key_position });
					}
				}

				if(state.entry.entry_status.id == 1){

					if(sum_hold_write_offs > parseFloat(params_val)){
						commit('setPositionQuantity', { key_product:data.key_product, key_position:data.key_position, quantity:sum_hold_write_offs }); 	
						params_val = sum_hold_write_offs;  
					}
				}				
			}

			if(data.params_id == "price") 
				params_val = state.entry.products[data.key_product].positions.items[data.key_position].position_price;  
			 
			if(data.params_id == "summ") 
				params_val = state.entry.products[data.key_product].positions.items[data.key_position].position_summ;  
			if(data.params_id == "place_zone") 
				params_val = state.entry.products[data.key_product].positions.items[data.key_position].position_place.zone; 
			if(data.params_id == "place_shelf") 
				params_val = state.entry.products[data.key_product].positions.items[data.key_position].position_place.shelf; 
 
			if(data.params_id == "deal_hold"){ 
				
				params_val = state.entry.products[data.key_product].positions.items[data.key_position].position_deals[data.key_deal].hold;

				let deals = state.entry.products[data.key_product].positions.items[data.key_position].position_deals;
				let quantity = parseFloat(state.entry.products[data.key_product].positions.items[data.key_position].position_quantity);
				let hold_sum = 0;
				let hold_max = quantity;

				for(let key in deals){ 
					if(Number(data.key_deal) !== Number(key))
						hold_max = hold_max - deals[key].hold

					hold_sum = hold_sum + parseFloat(deals[key].hold);
				} 
				if(hold_sum > quantity){ 
					commit('setHoldDeal', { hold:hold_max, key_product:data.key_product, key_position:data.key_position, key_deal:data.key_deal });
					params_val = hold_max
				}
			}
			 
			if(data.params_id == "deal_id") 
				params_val = state.entry.products[data.key_product].positions.items[data.key_position].position_deals[data.key_deal].deal_id;
			  

			if(data.params_id == "quantity" || data.params_id == "price"){  
				commit('setPositionSumm', { key_product:data.key_product, key_position:data.key_position });	
				commit('setInvoiceSumm');	
				dispatch("changeDataEntry", "invoice_summ");
			}		

			let response = await warehouseEntryCardApi.setPosition({
				entry_id:state.entry.entry_id, 
				product_id:state.entry.products[data.key_product].product_id,   
				id:state.entry.products[data.key_product].positions.items[data.key_position].id, 
				modify:state.entry.products[data.key_product].positions.items[data.key_position].modify, 
				params_id:data.params_id, 
				params_val,
				key_deal:data.key_deal,
				action:"save" 
			});	  
			if(response.result) {  
				
				commit('setUpdateStatus', true);

				if(data.params_id == "position_id")
					commit('setPositionValid', { key:data.key_product, k:data.key_position, value:true });	  
				
			}

			if(!response.result && data.params_id == "position_id") {    
				commit('setPositionValid', { key:data.key_product, k:data.key_position, value:false });	  
			}   
		},
		showEntryNotify({ commit }, data){  
			commit('setEntryNotify', data); 
		},
		actionEntryNotify({ state, dispatch }, action){

			if(state.notify.action == 'delete' && action) 
				dispatch("delEntry");	
				 
			if(state.notify.action == 'delete_product' && action)
				dispatch("delProduct", state.notify.params.key) 
			
			if(state.notify.action == 'delete_position' && action)  
				dispatch("delPosition", { key:state.notify.params.key, key_position:state.notify.params.key_position })
   			 
			 
			dispatch("showEntryNotify", { status:false, action:null, message:null, params:null });
		},
		async validateEntry({ state, dispatch, rootGetters }){

			let status = true;
			let status_notify = true;  
			let message = null;

			if(state.entry.entry_date == null){
				status = false;
				message = "Заполните поле «Дата прихода»";
			}
			if(status && state.entry.entry_category_product.id == null){
				status = false;
				message = "Заполните поле «Категория прихода»";
			}
			  
			if(status && state.entry.entry_invoice.summ == null){
				status = false;
				message = "Заполните поле «Сумма счета»";
			}

			if(status)
				for(let key in state.entry.products){
					
					if(state.entry.products[key].product_article_pzn_status){

						if(state.entry.products[key].product_names.id == null){
							status = false;
							message = "Заполните поле «Наименование товара»";
						}
						if(status && state.entry.products[key].product_type.id == null){
							status = false;
							message = "Заполните поле «Тип товара»";
						}
						if(status && state.entry.products[key].product_class.id == null){
							status = false;
							message = "Заполните поле «Вид товара»";
						}
						if(status && state.entry.products[key].product_colour.id == null){
							status = false;
							message = "Заполните поле «Цвет товара»";
						}  
					}
					else 
						if(state.entry.products[key].product_article_pzn_id == null){
							status = false;
							message = "Заполните поле «Наш артикул»";
						}  
					   

					if(status && state.entry.products[key].positions.items.length == 0){
						status = false;
						message = "Добавьте позиции в товар";
					}

					if(status && state.entry.products[key].positions.items.length > 0){

						for(let key_p in state.entry.products[key].positions.items){

							if(!state.entry.products[key].positions.items[key_p].position_valid){
								status = false;
								message = "Указанный вами номер позиции уже используется в другом приходе";
							}

							if(state.entry.products[key].positions.items[key_p].position_unit_id == null){
								status = false;
								message = "Заполните поле «Единица измерения» для позиции";
							}

							// if(status && state.entry.products[key].positions.items[key_p].position_quantity == 0){
							// 	status = false;
							// 	message = "Заполните поле «Кол-во» для позиции";
							// }
							// if(status && state.entry.products[key].positions.items[key_p].position_price == 0){
							// 	status = false;
							// 	message = "Заполните поле «Цена за ед.» для позиции";
							// }
						}
					}  
				} 
				
			if(!status)
				dispatch("showEntryNotify", { status:true, action:"validate", message:"<div style='font-size: 20px;'>Ошибка</div><div>"+message+"!</div>", params:null });
			else{
				message = "";
				if(state.entry.entry_invoice.number == null){ 
					status_notify = false;
					message += "Заполните поле «№ счета»<br>";
				}
				if(state.entry.entry_supplier.id == null){
					status_notify = false;
					message += "Заполните поле «Поставщик прихода»<br>";
				}
				if(state.entry.entry_payer.id == null){
					status_notify = false;
					message += "Заполните поле «Плательщик прихода»<br>";
				}
				if(state.entry.entry_invoice.date == null){
					status_notify = false;
					message += "Заполните поле «Дата счета»<br>";
				} 
				for(let key in state.entry.products){ 
					if(status && state.entry.products[key].product_article_supplier_status && state.entry.products[key].product_article_supplier_custom == null){
						status = false;
						message += "Заполните поле «Артикул поставщика»<br>";
					}   
				}
				if(!status_notify) 
					dispatch("showEntryNotify", { status:true, action:"notify", message:"<div style='font-size: 20px;'>Предупреждение</div><div>"+message+"Хотите продолжить?</div>", params:null }); 
				else 
					dispatch("performEntry");					 			 
			} 
		},
		async performEntry({ state, dispatch, rootGetters }){
			
			dispatch("showEntryNotify", { status:false, action:null, message:null, params:null });

			let response = await warehouseEntryCardApi.performEntry({  
				entry_id:state.entry.entry_id,  
			});	  
			if(response.result) {   
				await dispatch("warehouse_entry/loadDataWarehouseEntry", 
					{ filter:rootGetters['warehouse_entry/warehouse_entry'].filter }, 
					{ root: true }
				);	
				dispatch("showWarehouseEntryCard", { status:false, type:"edit", entry_id:null });
			}    
		},		
		async cancelPerformEntry({ state, dispatch, rootGetters }){

			let response = await warehouseEntryCardApi.cancelPerformEntry({  
				entry_id:state.entry.entry_id,  
			});	  
			if(response.result) {   
				await dispatch("warehouse_entry/loadDataWarehouseEntry", 
					{ filter:rootGetters['warehouse_entry/warehouse_entry'].filter }, 
					{ root: true }
				);	
				dispatch("showWarehouseEntryCard", { status:true, type:"edit", entry_id:state.entry.entry_id });
			}
			if(!response.result) {
				
				let message = null;

				if(response.order_id) message = "<div style='font-size: 20px;'>Отмена проводки запрещена</div><div>Так как позиции прихода используются в заказе №"+response.order_id+"!</div>";
				else message = "<div style='font-size: 20px;'>Отмена проводки запрещена</div><div>Так как у позиции есть списания!</div>";  

				dispatch("showEntryNotify", { status:true, action:"validate", message, params:null });
			}   
		},
		editEntry({ commit }, edit){
			commit('editEntry', edit);
		},
		checkRelocatePositions({ state, dispatch }, product){

			if(product == "select_product"){
				dispatch("setShowPositionRelocate", { status:true, product_id:null });  
			}

			if(product == "new_product")
				dispatch("relocatePositions");
		},
		async relocatePositions({ state, commit, dispatch }){
			
			let product_id = state.position_relocate.product_id;


			if(product_id == null)
				await dispatch("addProduct").then((res)=>{ 
					product_id = res.product_id;
				}); 
 
			let response = await warehouseEntryCardApi.relocatePositions({  
				entry_id:state.entry.entry_id,  
				product_id,
				positions:state.selected_position
			});	  

			if(response.result) {  
				commit('setUpdateStatus', true);				
				 
				commit('relocatePositions', { positions:state.selected_position, product_id }); 			 
				
				dispatch("setShowPositionRelocate", { status:false, product_id:null });
				commit('nullableSelectedPosition'); 	 
			}
		}, 
		setShowPositionRelocate({ commit }, data){
			commit('setShowPositionRelocate', data);
		},
		async handlePhotoCustomArticle({ state, commit }, data){
			
			let formData = new FormData(); 
      		formData.append('file', state.custom_article_photo.file[0]);
			formData.append('product_id', data.product_id);
			formData.append('entry_id', state.entry.entry_id);
       
			let response = await warehouseEntryCardApi.loadPhotoCustomArticle(formData);	  
			if(response.result) {  	
				commit('setUpdateStatus', true);
				commit('upPhotoCustomArticle', {photo:response.photo, key:data.key});
			}
		},		 
		async deletePhotoCustomArticle({ state, commit }, data){
			
			let response = await warehouseEntryCardApi.deletePhotoCustomArticle({ product_id:data.product_id, entry_id:state.entry.entry_id });	  
			if(response.result) {  	
				commit('setUpdateStatus', true);
				commit('upPhotoCustomArticle', {photo:null, key:data.key});
			}
		}		 
	}, 
} 