import server from '@/api/http';
 
export async function getEntry(params){  
	let { data } = await server.get('warehouse/entry/get/entry', { params });
	return data;
}  
export async function getEntryProducts(params){  
	let { data } = await server.get('warehouse/entry/get/entry/products', { params });
	return data;
}   
export async function setEntry(params){  
	let { data } = await server.get('warehouse/entry/set/entry', { params });
	return data;
}  
export async function delEntry(params){  
	let { data } = await server.get('warehouse/entry/del/entry', { params });
	return data;
}  
export async function setProduct(params){  
	let { data } = await server.get('warehouse/entry/set/product', { params });
	return data;
}  
export async function delProduct(params){  
	let { data } = await server.get('warehouse/entry/del/product', { params });
	return data;
}  
export async function setPosition(params){  
	let { data } = await server.get('warehouse/entry/set/position', { params });
	return data;
}  
export async function delPosition(params){  
	let { data } = await server.get('warehouse/entry/del/position', { params });
	return data;
} 
export async function addPositionDeal(params){  
	let { data } = await server.get('warehouse/entry/add/position/deal', { params });
	return data;
}  
export async function delPositionDeal(params){  
	let { data } = await server.get('warehouse/entry/del/position/deal', { params });
	return data;
}  
export async function downloadFile(params){  
	let { data } = await server.post('warehouse/entry/download/file', params, { headers: {'Content-Type': 'multipart/form-data' } });
	return data;
}  
export async function delFile(params){  
	let { data } = await server.get('warehouse/entry/del/file', { params });
	return data;
} 
export async function performEntry(params){  
	let { data } = await server.get('warehouse/entry/run/perform', { params });
	return data;
} 
export async function cancelPerformEntry(params){  
	let { data } = await server.get('warehouse/entry/cancel/perform', { params });
	return data;
} 
export async function markingEntry(params){  
	let { data } = await server.get('warehouse/entry/get/marking', { params });
	return data;
} 
export async function relocatePositions(params){  
	let { data } = await server.get('warehouse/entry/relocate/positions', { params });
	return data;
}
export async function saveChange(params){  
	let { data } = await server.get('warehouse/entry/save/change', { params });
	return data;
}
export async function cancelChange(params){  
	let { data } = await server.get('warehouse/entry/cancel/change', { params });
	return data;
} 
export async function getModify(params){  
	let { data } = await server.get('warehouse/entry/get/change', { params });
	return data;
} 
export async function getDataArticlePzn(params){  
	let { data } = await server.get('warehouse/entry/get/data/articlepzn', { params });
	return data;
} 
export async function loadPhotoCustomArticle(params){  
	let { data } = await server.post('warehouse/entry/download/photo/custom_article', params, { headers: {'Content-Type': 'multipart/form-data' } });
	return data;
} 
export async function deletePhotoCustomArticle(params){  
	let { data } = await server.get('warehouse/entry/delete/photo/custom_article', { params });
	return data;
} 
 
 


 