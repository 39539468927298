export default {
	namespaced: true,
	state: {
		status:false,
		text:null,
		timeout: 2000,
	},
	getters: {
		snackBar:state=>{
			return state
		}, 
	},
	mutations: { 
		setData(state, data){ 
			state.status = data.status  
			state.text = data.text  
		},    
	},
	actions: {
		showSnackBar({ commit }, text){ 
			commit('setData', { status:true, text }); 
		}, 
		closeSnackBar({ commit }){ 
			commit('setData', { status:false, text:null }); 
		}, 
	}
}