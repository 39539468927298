import server from '@/api/http';

export async function get(){  
	let { data } = await server.get('auth');
	return data;
}
export async function validToken(token){ 
	let { data } = await server.get('valid/token',{params:{token}});
	return data;
} 
export async function upSortPage(params){  
	let { data } = await server.post('up/sort_page', params);
	return data;
} 

 