import server from '@/api/http';
 
export async function getFilter(params){  
	let { data } = await server.get('warehouse/write_offs/get_filter', { params });
	return data;
}  
export async function getData(params){  
	let { data } = await server.get('warehouse/write_offs/get_data', { params });
	return data;
}  
export async function setFilter(params){  
	let { data } = await server.get('warehouse/write_offs/set_filter', { params });
	return data;
}   
export async function createWriteOffs(params){  
	let { data } = await server.get('warehouse/write_offs/create', { params });
	return data;
}
export async function cancelWriteOffs(params){  
	let { data } = await server.get('warehouse/write_offs/cancel', { params });
	return data;
}
export async function deleteWriteOffs(params){  
	let { data } = await server.get('warehouse/write_offs/del', { params });
	return data;
}
export async function setWriteOffs(params){  
	let { data } = await server.get('warehouse/write_offs/set', { params });
	return data;
} 